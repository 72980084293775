import { computed } from "vue";
import { useEshopStore } from "@/store/EshopStore";
import { useCreditStore } from "@/store/CreditStore";
import defaultConfig from "@/mixin/defaultConfig.json";

export function useEshopData() {
  const eshopStore = useEshopStore();
  const creditStore = useCreditStore();

  const eshopId = computed(() => {
    return eshopStore.detail.id;
  });

  const eshop = computed({
    get: () => eshopStore.detail,
    set: (val) => eshopStore.detail = val
  });

  const summary = computed({
    get: () => creditStore.summary,
    set: (val) => creditStore.summary = val
  });

  const edited = computed({
    get: () => creditStore.edited,
    set: (val) => creditStore.edited = val
  });

  const price = computed({
    get: () => creditStore.price,
    set: (val) => creditStore.price = val
  });

  const availableEshop = computed(() => {
    return eshop.value.available_eshop;
  });

  const isConfigureLang = computed(() => {
    return eshop.value.allow_lang === true;
  });

  const isEnableDark = computed(() => {
    return eshop.value.enable_dark;
  });

  const aiLang = computed(() => {
    return eshop.value.ai_lang;
  });

  const eshopConfig = computed(() => {
    return eshop.value.config ? JSON.parse(eshop.value.config) : {};
  });

  const editorLang = computed(() => {
    return eshop.value.editor_lang;
  });

  const editorConfig = computed(() => {
    const editor = eshopConfig.value.editor;
    if (editor && editor.active) {
      console.log('%c Allow custom editor config', 'background: #222; color: #bada55');
      return editor;
    } else {
      console.log('%c Use default editor config', 'background: #222; color: #bada55');
      return defaultConfig.editor;
    }
  });

  const eshopUrl = computed(() => {
    return eshop.value.url;
  });

  const eshopTariff = computed(() => {
    return eshop.value.tariff;
  });

  const isShopify = computed(() => {
    return eshop.value.platform === "shopify";
  });

  const isShoptet = computed(() => {
    return eshop.value.platform === "shoptet";
  });

  const isB2B = computed(() => {
    return eshop.value.platform === "b2b";
  });

  const showShoptetWebhookNotification = computed(() => {
    return false;
  });

  const isAdmin = computed(() => {
    return eshop.value.role.includes('admin');
  });

  const designId = computed(() => {
    return eshop.value.design_id;
  });

  const isPositiveCreditAccountStatus = computed(() => {
    const { reaming } = summary.value;
    return reaming < 0;
  });

  const allowGenerateContent = computed(() => {
    const { reaming } = summary.value;
    const { generate } = price.value;
    return reaming > generate;
  });

  const allowFormatContent = computed(() => {
    const { reaming } = summary.value;
    const { format } = price.value;
    return reaming > format;
  });

  const showExpireTariff = computed(() => {
    return eshop.value.expire_tariff;
  });

  return {
    eshopId,
    eshop,
    summary,
    edited,
    price,
    availableEshop,
    isConfigureLang,
    isEnableDark,
    aiLang,
    eshopConfig,
    editorLang,
    editorConfig,
    eshopUrl,
    eshopTariff,
    isShopify,
    isShoptet,
    isB2B,
    showShoptetWebhookNotification,
    isAdmin,
    designId,
    isPositiveCreditAccountStatus,
    allowGenerateContent,
    allowFormatContent,
    showExpireTariff
  };
}
