<template>
  <div>
    <div class="row">
      <div class="col-5">
        <div class="p-3 border-light bg-white widget-admin-row">
          <strong class="mb-2 d-block">Náhled widgetu</strong>

          <div
            v-for="(widget, index) in tree"
            :key="index"
            :class="widget.class"
          >
            <template v-if="widget.children">
              <widget-tree
                v-for="(tree, index) in widget.children"
                :id="widget.id"
                :key="widget.id + index"
                :lang="selectedLang"
                :item="tree"
                :class="tree.class"
              />
            </template>
          </div>
        </div>
      </div>

      <div class="col-2">
        <div class="p-3 border-light bg-white widget-admin-row">
          <strong class="mb-2 d-block">Přidávání elementů</strong>

          <div class="form-group">
            <label for="id">Typ elementu</label>
            <select
              id="id"
              v-model="formData.tag_id"
              class="custom-select"
            >
              <option
                v-for="element in tag"
                :key="element.id"
                :value="element.id"
              >
                {{ element.name }}
              </option>
            </select>
          </div>

          <div class="form-group">
            <label for="class">Třída</label>
            <input
              id="class"
              v-model="formData.class"
              class="form-control"
              required
            >
          </div>

          <div class="form-group">
            <label for="default_value">Výchozí text</label>
            <div
              v-for="flag in lang"
              :key="flag"
            >
              <img
                :src="`https://pobo-cdn.b-cdn.net/flag/${flag}.svg`"
                style="width: 20px"
              >
              <input
                v-if="formData.tag_id !== 7"
                id="default_value"
                v-model="formData.default_value[flag]"
                class="form-control"
              >
              <froala
                v-else
                v-model="formData.default_value[flag]"
                :config="config"
              />
            </div>
          </div>

          <div class="form-group">
            <label for="parent_id">Rodič</label>
            <select
              id="parent_id"
              v-model="formData.parent_id"
              class="custom-select"
            >
              <option :value="1">
                Nejvyšší v hierarchii
              </option>
              <option
                v-for="element in flat"
                :key="element.child_id"
                :value="element.child_id"
              >
                {{ element.name }} (ID: {{ element.child_id }})
              </option>
            </select>
          </div>

          <div class="form-check">
            <input
              id="is_editable"
              v-model="formData.is_editable"
              class="form-check-input"
              type="checkbox"
            >
            <label
              class="form-check-label"
              for="is_editable"
            >
              Editovatelný element
            </label>
          </div>

          <button
            class="btn btn-secondary btn-block mt-4"
            :disabled="!formData.parent_id || !formData.class || !formData.tag_id"
            @click.prevent="actionCreate"
          >
            Přidat element
          </button>
        </div>
      </div>

      <div class="col-5">
        <div class="p-3 border-light bg-white widget-admin-row">
          <strong class="d-block mb-2">Seznam elementů</strong>

          <table
            v-if="flat"
            class="table table-sm table-hover"
          >
            <thead class="bg-white font-weight-bolder">
              <tr>
                <td>ID</td>
                <td colspan="2">
                  Třída a styl elementu
                </td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in flat"
                :key="item.id"
              >
                <td>{{ item.child_id }}</td>

                <td>
                  <b-row>
                    <b-col cols="6">
                      <small class="d-block">{{ item.class }}</small>
                      <code class="d-block small">{{ item.tag }}</code>
                    </b-col>
                    <b-col cols="6 text-muted small">
                      <div>
                        <i :class="[item.is_editable ? 'ico-success' : 'ico-cancel', 'ico']" />
                        Editovatelný
                      </div>
                      <div>
                        <i :class="[item.is_customizable ? 'ico-success' : 'ico-cancel', 'ico']" />
                        Stylování v Pobo Designer
                      </div>
                    </b-col>
                  </b-row>
                </td>

                <td>
                  <div
                    class="btn-group"
                    role="group"
                  >
                    <button
                      class="btn btn-sm btn-danger"
                      @click="actionDelete(item.id)"
                    >
                      <i class="ico ico-match-delete m-0" />
                    </button>

                    <button
                      class="btn btn-sm btn-secondary"
                      @click="openElementModal(item)"
                    >
                      <i class="ico ico-edit m-0" />
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <b-modal
      id="element-modal"
      v-model="isElementModalOpen"
      size="ai"
      hide-footer
      no-fade
      title="Konfigurace elementu"
    >
      <b-form @submit.prevent="actionUpdate">
        <div class="form-group">
          <label for="tag_id">Typ elementu</label>
          <select
            id="tag_id"
            v-model="saveFormData.tag_id"
            class="custom-select"
          >
            <option
              v-for="(element, index) in tag"
              :key="index"
              :value="element.id"
            >
              {{ element.name }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label for="edit_class">Třída</label>
          <input
            id="edit_class"
            v-model="saveFormData.class"
            class="form-control"
            required
          >
        </div>

        <div
          v-if="saveFormData.default_value"
          class="form-group"
        >
          <label for="saveFormData_default_value">Výchozí text</label>
          <switch-lang @select-lang="selectLang" />

          <textarea
            id="saveFormData_default_value"
            v-model="saveFormData.default_value[selectedLang]"
            class="form-control"
            rows="10"
          />
        </div>

        <div class="form-check">
          <input
            id="saveFormData_is_editable"
            v-model="saveFormData.is_editable"
            class="form-check-input"
            type="checkbox"
          >
          <label
            class="form-check-label"
            for="saveFormData_is_editable"
          >
            Editovatelný element
          </label>
        </div>

        <div class="form-check">
          <input
            id="is_customizable"
            v-model="saveFormData.is_customizable"
            class="form-check-input"
            type="checkbox"
          >
          <label
            class="form-check-label"
            for="is_customizable"
          >
            Povolit stylování pomocí projektoru
          </label>
        </div>

        <b-btn
          variant="secondary"
          block
          type="submit"
          class="mt-4"
        >
          Uložit nastavení elementu
        </b-btn>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import WidgetTree from "@/components/component/part/widget-tree.vue";
import editorConfig from "@/js/config/froala.js";
import SwitchLang from "@/share/switch-lang.vue";
import lang from "@/js/config/lang";
import WidgetModel from "@/model/WidgetModel.js";
import TagModel from "@/model/TagModel.js";

let mappingLang = {};

Array.from(lang).forEach((flag) => {
  Object.assign(mappingLang, { [flag]: "" });
});

export default {
  name: "widget-element",

  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  mounted() {
    this.actionRead();
    this.actionReadTag();
  },

  data() {
    return {
      tree: {},
      tag: {},
      flat: {},
      css: {},
      isElementModalOpen: false,
      selectedLang: "cs",
      lang,
      config: editorConfig,

      formData: {
        tag_id: 1,
        parent_id: 1,
        widget_id: this.id,
        class: null,
        default_value: mappingLang,
        is_editable: false,
      },

      saveFormData: {
        id: null,
        class: null,
        tag_id: 1,
        default_value: null,
        default_css: [],
        is_customizable: false,
        is_editable: false,
        projector_name: null,
      },
    };
  },

  components: {
    "widget-tree": WidgetTree,
    "switch-lang": SwitchLang,
  },

  methods: {
    selectLang(flag) {
      this.selectedLang = flag;
    },

    openElementModal(widget) {
      this.isElementModalOpen = true;
      this.saveFormData = widget;
    },

    async actionRead() {
      await WidgetModel.findMe(
        this.id,
        () => {},
        (data) => {
          this.tree = data.tree;
          this.flat = data.flat;
          this.css = data.css;
        }
      );
    },

    async actionReadTag() {
      await TagModel.findMe(
        () => {},
        (data) => {
          this.tag = data;
        }
      );
    },

    async actionCreate() {
      await TagModel.create(
        this.formData,
        () => {},
        () => {
          this.actionRead();
        }
      );
    },

    async actionUpdate() {
      await TagModel.update(
        this.saveFormData.id,
        this.saveFormData,
        () => {},
        () => {
          this.actionRead();
          this.isElementModalOpen = false;
        }
      );
    },

    async actionDelete(id) {
      await TagModel.delete(
        id,
        () => {},
        () => {
          this.actionRead();
        }
      );
    },
  },
};
</script>

<style scoped></style>
