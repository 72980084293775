<template>
  <div
    class="generative-ai-button__dropdown"
    @click.prevent="toggleDropdown"
  >
    <b-icon
      v-if="!showDropdown"
      icon="caret-down-fill"
      class="fs-10 p-0 m-0"
    />
    <b-icon
      v-else
      icon="caret-up-fill"
      class="fs-10 p-0 m-0"
    />

    <div
      v-if="showDropdown"
      class="generative-ai-button__dropdown-wrap"
    >
      <div class="generative-ai-button__dropdown-inner">
        <template v-if="filteredContentList.length > 0">
          <div class="generative-ai-button__warning">
            <div>⚠️</div>
            <div>
              {{ $t('Always double check the generated text') }}!
            </div>
          </div>

          <div
            v-for="item in filteredContentList"
            :key="item.id"
            class="generative-ai-button__dropdown-item"
            href="#"
            @click.prevent="handleItemClick(item)"
          >
            {{ item.title }}
          </div>
        </template>
        <div
          v-else
          class="dropdown-empty"
        >
          <div
            v-if="isLoading"
            class="loading-message text-center p-3 fs-12"
          >
            {{ $t("product.search.load") }}
          </div>
          <div
            v-else
            class="empty-message text-center p-3 fs-12"
          >
            {{ $t('For now, you dont have any custom AI prompts. Are you interested? Contact us') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eshopData from "@/mixin/eshopData";
import GenerativeModel from "@/model/GenerativeModel";

export default {
  mixins: [eshopData],

  data() {
    return {
      contentList: [],
      isLoading: false,
      showDropdown: false,
    };
  },

  computed: {
    filteredContentList() {
      return this.contentList;
    }
  },

  watch:{
    showDropdown(newVal) {
      if (newVal) {
        this.fetchData();
        document.addEventListener("mousedown", this.handleClickOutside);
      } else {
        document.removeEventListener("mousedown", this.handleClickOutside);
      }
    }
  },

  beforeUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  },

  methods: {
    async fetchData() {
      if (this.contentList.length === 0) {
        this.isLoading = true;
        await GenerativeModel.getContent(
          () => {},
          (data) => {
            this.contentList = data;
            this.isLoading = false;
          }
        );
      }
    },

    toggleDropdown(){
      this.showDropdown = !this.showDropdown;
    },

    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.showDropdown = false;
      }
    },

    handleItemClick(item) {
      const {lang} = this;
      this.$emit("generate-ai-text", {
        html: "<span></span>",
        templateId: item.id,
        lang: lang,
      });
    },
  }
};
</script>