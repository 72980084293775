<template>
  <div class="product-autocomplete w-70">
    <div class="product-autocomplete__input">
      <template v-if="selected">
        <span
          class="form-control"
          @click="selected = null"
        >
          {{ formatUrl(selected.url) }}
        </span>
      </template>
      <template v-else>
        <form
          class="input-group position-relative"
          @submit.prevent="actionRead"
        >
          <input
            id="id"
            v-model="params.query"
            type="text"
            class="form-control"
            placeholder="Vyhledejte eshop..."
            autocomplete="off"
            @click="handleOpen(true)"
          >
          <div class="input-group-append">
            <button
              class="btn btn-secondary"
              type="submit"
            >
              <i class="ico ico-search" />
            </button>
          </div>
        </form>
      </template>
    </div>

    <div
      v-if="isOpen && !selected"
      class="product-autocomplete__container"
      style="z-index: 9"
    >
      <div
        v-for="user in users"
        :key="user.id"
        class="product-autocomplete__products"
      >
        <div class="product-autocomplete__title">
          <a
            :href="user.url"
            target="_blank"
          >
            {{ formatUrl(user.url) }}
            <i class="ico ico-external" />
          </a>
        </div>

        <div class="product-autocomplete__button">
          <a
            class="btn btn-secondary btn-sm btn-block text-white"
            @click="handleSelect(user)"
          >
            Vybrat
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from "vue";
import DashboardModel from "@/model/DashboardModel.js";

export default {
  name: "eshop-autocomplete",
  emits: ["select-eshop"],
  setup(_, {emit}) {
    const users = ref([]);
    const selected = ref(null);
    const isOpen = ref(false);
    const params = reactive({
      query: "",
      limit: 5,
      offset: 0,
      filter: 1,
    });

    const formatUrl = (url) => {
      if (url) {
        url = url.replace(/^(https?:\/\/)/, "");
        url = url.replace(/^www\./, "");
        url = url.replace(/\/$/, "");
      }
      return url;
    };

    const handleSelect = (eshop) => {
      selected.value = eshop;
      emit("select-eshop", eshop);
    };

    const handleOpen = (state) => {
      isOpen.value = state;
    };

    const actionRead = async () => {
      const {query, limit, offset, filter} = params;
      const result = await DashboardModel.findAll(
        {query, limit, offset, filter},
        () => {
        },
        (data) => {
          users.value = data.data.users;
        }
      );
    };

    onMounted(actionRead);

    return {
      users,
      selected,
      isOpen,
      params,
      formatUrl,
      handleSelect,
      handleOpen,
      actionRead,
    };
  },
};
</script>

<style scoped></style>
