<template>
  <div>
    <div class="container mt-2 mb-2">
      <div class="row">
        <div class="col-md-12 mb-4 pl-1">
          <div class="card">
            <div
              class="card-body pb-0 text-center"
              style="background: #e7e7e7"
            >
              <h5 class="card-title">
                {{ widget.name }} – ID: {{ widget.id }}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-form @submit.prevent="actionCreate">
      <b-form-group label="Vyberte e-shop ke kterému chcete widget přiřadit">
        <b-row>
          <b-col md="9">
            <eshop-autocomplete @select-eshop="selectEshop" />
          </b-col>
          <b-col
            md="3"
            class="m-0"
          >
            <b-btn
              variant="secondary"
              type="submit"
            >
              <i class="ico ico-save m-0" /> Uložit
            </b-btn>
          </b-col>
        </b-row>
        <small
          v-if="success"
          class="pt-2 pl-1"
          style="color: darkgreen"
        >
          Widget byl úspěšně přiřazen k e-shopu
        </small>
      </b-form-group>
    </b-form>

    <template v-if="users.length > 0">
      <p class="mt-5 mb-0">
        <strong>
          Tento widget je již propojený s těmito e-shopy
        </strong>
      </p>
      <div class="table-responsive">
        <table class="table table-striped table-bordered mt-3">
          <thead class="bg-white">
            <tr>
              <th>ID</th>
              <th>URL</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="eshop in users"
              :key="eshop.id"
            >
              <td>{{ eshop.id }}</td>
              <td>{{ eshop.url }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>

    <template v-else>
      <h6
        class="text-center pt-5 pb-5"
        style="text-transform: uppercase"
      >
        Tento widget nebyl prozatím přiřazen žádnému e-shopu
      </h6>
    </template>
  </div>
</template>

<script>
import EshopAutocomplete from "@/components/component/part/eshop-autocomplete.vue";
import WidgetModel from "@/model/WidgetModel.js";

export default {
  name: "WidgetConnect",

  props: {
    widget: {
      type: Object,
      required: true,
    },
  },

  async mounted() {
    await this.fetchConnectedEshops();
  },

  data() {
    return {
      users: [],
      selectedEshop: null,
      success: false,
    };
  },

  components: {
    EshopAutocomplete,
  },

  methods: {
    async fetchConnectedEshops() {
      try {
        const data = await WidgetModel.getConnectedEshops(this.widget.id);
        this.users = data || [];
      } catch (error) {
        console.error("Error fetching connected e-shops:", error);
      }
    },

    selectEshop(eshop) {
      this.selectedEshop = eshop;
    },

    async actionCreate() {
      if (!this.selectedEshop) return;

      try {
        const response = await WidgetModel.connectMe({
          eshop_id: this.selectedEshop.id,
          widget_id: this.widget.id,
        });

        if (response.result) {
          this.users.push(this.selectedEshop);
          this.success = true;
          this.$emit("update");
        } else {
          this.success = false;
        }
      } catch (error) {
        console.error("Error connecting e-shop:", error);
      }
    },
  },
};
</script>

<style scoped></style>
