<template>
  <div class="body-admin">
    <div class="body-admin__video">
      <video
        autoplay
        muted
        loop
        class="body-admin__video-mp4"
      >
        <source
          src="https://image.pobo.cz/video/intro.mp4"
          type="video/mp4"
        >
      </video>
    </div>
    <div class="body-admin__login p-5">
      <div class="pb-5 container">
        <div class="row mt-5">
          <aside class="col-sm-10 m-auto">
            <div class="text-center mb-4">
              <img
                alt="Pobo Page Builder logo"
                class="img-fluid user-logo-page"
                src="https://pobo-cdn.b-cdn.net/static/logo.png"
              >
            </div>

            <div class="col-12">
              <b-alert
                v-if="loginErrorMessage || resetErrorMessage || resetSuccessMessage"
                show
                variant="danger"
              >
                <ul class="list-unstyled m-0">
                  <li v-if="loginErrorMessage">
                    {{ loginErrorMessage }}
                  </li>
                  <li v-if="resetErrorMessage">
                    {{ resetErrorMessage }}
                  </li>
                  <li
                    v-if="resetSuccessMessage"
                    class="text-success"
                  >
                    {{ resetSuccessMessage }}
                  </li>
                </ul>
              </b-alert>
            </div>

            <h2 class="mt-4 pt-4 mb-4 text-center mb-4 pb-3 font-weight-normal text-white">
              {{ $t("Thank you for using Pobo Page Builder") }} 🙏
            </h2>

            <ul class="nav nav-tabs border-bottom-0">
              <li class="nav-item">
                <a
                  class="nav-link"
                  :class="{ active: isLoginFormVisible, 'text-white': !isLoginFormVisible }"
                  href="#"
                  @click.prevent="showLoginForm"
                >
                  {{ $t("Log in") }}
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  :class="{ active: !isLoginFormVisible, 'text-white': isLoginFormVisible }"
                  href="#"
                  @click.prevent="showResetForm"
                >
                  {{ $t("Forgot your password?") }}
                </a>
              </li>
            </ul>

            <div
              v-if="isLoginFormVisible"
              id="js-login"
            >
              <div class="p-4 shadow-sm h-100 rounded-lg bg-white">
                <h4 class="mb-4 mt-1">
                  {{ $t("Log in") }}
                </h4>
                <login-form
                  :disabled="isLoading"
                  @submit="loginUser"
                />
              </div>
            </div>

            <div
              v-else
              id="js-reset"
            >
              <div class="p-4 shadow-sm h-100 bg-white rounded">
                <h4 class="mb-4 mt-1">
                  {{ $t("Forgot your password?") }}
                </h4>
                <div class="pb-4 fs-15 lh-22">
                  {{ $t("If you forgot your password or did not receive the account creation link (we send it after the plugin is installed), please enter your email in the form.") }}
                </div>
                <reset-form-password
                  :disabled="isLoading"
                  @reset="resetPassword"
                />
              </div>
            </div>
          </aside>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from "@/components/forms/LoginForm.vue";
import ResetPasswordForm from "@/components/forms/ResetPasswordForm.vue";
import AuthModel from "@/model/AuthModel";
import Cookies from "js-cookie";

export default {
  name: "LayoutLogin",
  created() {
    this.loadTheme();
    const loadingElement = document.getElementById("hero-loading");
    if (loadingElement) {
      loadingElement.remove();
    }
  },
  data() {
    return {
      loginErrorMessage: "",
      resetErrorMessage: "",
      resetSuccessMessage: "",
      isLoading: false,
      isLoginFormVisible: true,
    };
  },
  components: {
    "login-form": LoginForm,
    "reset-form-password": ResetPasswordForm,
  },
  methods: {
    async loadTheme() {
      try {
        await import("@/scss/theme/light.scss");
      } catch (error) {
        console.error("Chyba při načítání motivu:", error);
      }
    },

    async loginUser(formData) {
      this.loginErrorMessage = "";
      await AuthModel.login(formData.email, formData.password, 352)
        .then((response) => {
          if (response.token) {
            Cookies.set("access_token", response.token);
            this.$router.push({ name: "product" });
          } else {
            this.loginErrorMessage = this.$t("The login failed. Please check your email and password.");
          }
        })
        .catch((error) => {
          this.loginErrorMessage = this.$t("An error occurred during login. Please try again.");
        });
    },

    async resetPassword(email) {
      this.resetErrorMessage = "";
      this.resetSuccessMessage = "";
      this.isLoading = true;

      try {
        const result = await AuthModel.resetPassword(
          email,
          () => {
          },
          () => (this.isLoading = false)
        );

        if (result) {
          this.resetSuccessMessage = this.$t("A reset link has been successfully sent to your email.");
        } else if (result.error) {
          this.resetErrorMessage =
              result.error === "USER_NOT_FOUND"
                ? this.$t("The user was not found.")
                : this.$t("Invalid email address.");
        }
      } catch (error) {
        this.resetErrorMessage = this.$t("An error occurred while sending the reset request. Please try again.");
      } finally {
        this.isLoading = false;
      }
    },

    showLoginForm() {
      this.isLoginFormVisible = true;
    },

    showResetForm() {
      this.isLoginFormVisible = false;
    },
  },
};
</script>

<style scoped>
.body-admin {
  display: flex;
  position: relative;
}

.body-admin__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: -1;
}

.body-admin__video-mp4 {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.body-admin__login {
  background: #00000080;
  width: 600px;
  height: 100vh;
  position: absolute;
  left: 0;
}
</style>