<template>
  <div class="text-center pt-4">
    <template v-if="activeExport">
      <b-spinner size="30" />
      <h4 class="mt-4 mb-0 pb-4">
        {{ $t("template.modalLoadExport.part1") }}
      </h4>
      <p class="text-center">
        {{ $t("template.modalLoadExport.part2") }}
      </p>
      <b-progress
        :max="limit"
        :value="reaming"
        animated
        class="mt-4"
        height="10px"
        variant="secondary"
      />
      <small class="d-block text-center text-muted mt-2">
        {{ $t("template.modalLoadExport.part3") }}
        {{ limit + 1 - Math.ceil(reaming) }}
        {{ $t("template.modalLoadExport.part4") }}
      </small>
    </template>
    <template v-else>
      <b-icon
        icon="arrow-up-circle"
        size="40"
      />
      <p class="text-center">
        {{ $t("Kliknutím nahrajete obsah do e-shopu.") }}
      </p>
    </template>
  </div>

  <b-btn
    block
    variant="secondary"
    @click="runExport"
  >
    {{ $t("Export content to the e-shop") }}
  </b-btn>
</template>

<script setup>
import { ref, computed } from 'vue';
import ExportModel from "@/model/ExportModel.js";

const LIMIT = 10;

const props = defineProps({
  content: {
    type: Object,
    required: true
  },
  type: {
    type: String,
    required: true,
    default: "product" // product, category, blog
  }
});

const emit = defineEmits(['close']);

const url = ref(null);
const reaming = ref(0);
const activeExport = ref(false);

const limit = computed(() => {
  return LIMIT;
});

const runExport = async () => {
  const { content, type } = props;
  const { id } = content;

  activeExport.value = true;

  const newWindow = window.open("about:blank", "exportWindow");
  if (!newWindow) {
    alert("Prohlížeč zablokoval vyskakovací okno. Povolte je a zkuste znovu.");
    activeExport.value = false;
    return;
  }

  const intervalId = setInterval(() => {
    if (reaming.value < limit.value) {
      reaming.value += 0.1;
    }
  }, 100);

  const request = {
    type: type,
    data: { id: id }
  };

  await ExportModel.uploadMe(
    request,
    () => {},
    (data) => {
      const { url } = data;

      clearInterval(intervalId);

      if (url) {
        newWindow.location.href = url + "#pobo-all-content";
      } else {
        newWindow.close();
      }
      emit("close");
    }
  );
};
</script>

<style scoped></style>