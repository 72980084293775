<template>
  <div class="editor-modal__header">
    <div class="editor-modal__header-tab">
      <ul class="content-editor__tab">
        <li>
          <button class="content-editor__tab-btn">
            {{ item.name }}
          </button>
        </li>
      </ul>
    </div>
    <div class="editor-modal__header-menu">
      <ul class="content-editor__menu">
        <li>
          <button
            class="content-editor__menu-btn"
            data-cy="modal-connect"
            @click="isOpenConnect = true"
          >
            Napojení na e-shopy
          </button>
        </li>
        <li>
          <button
            class="content-editor__menu-btn"
            data-cy="modal-detail"
            @click="isOpenDetail = true"
          >
            Edit design
          </button>
        </li>
        <li>
          <button
            class="content-editor__menu-btn content-editor__menu-btn--save"
            data-cy="design-detail-save"
            @click="handleSubmit"
          >
            {{ $t("product.panel.save") }}
          </button>
        </li>
        <li>
          <button
            class="content-editor__menu-btn content-editor__menu-btn--close"
            data-cy="design-detail-close"
            @click="$emit('handle-close')"
          >
            {{ $t("product.panel.close") }}
          </button>
        </li>
      </ul>
    </div>
  </div>
  <b-container fluid>
    <b-row class="row mt-2">
      <b-col cols="6">
        <div class="form-group">
          <div class="mb-2 text-left">
            Typografie
          </div>
          <monaco
            :value="formData.css_typo"
            language="scss"
            height="calc(100vh - 100px)"
            @change="formData.css_typo = $event"
          />
        </div>
      </b-col>
      <b-col cols="6">
        <div class="form-group">
          <div class="mb-2 text-left">
            Widgety
          </div>
          <monaco
            :value="formData.css_widget"
            language="scss"
            height="calc(100vh - 100px)"
            @change="formData.css_widget = $event"
          />
        </div>
      </b-col>
    </b-row>
  </b-container>

  <b-modal
    v-model="isOpenConnect"
    title="Napojení na e-shopy"
    hide-footer
    size="md"
  >
    <modal-connect :item="item" />
  </b-modal>

  <b-modal
    v-model="isOpenDetail"
    title="Edit design info"
    hide-footer
    size="lg"
  >
    <modal-detail
      :item="item"
      @handle-close="isOpenDetail = false"
    />
  </b-modal>
</template>

<script>
import { ref, reactive, onMounted } from "vue";
import Monaco from "@/components/share/monaco.vue";
import DesignModel from "@/model/admin/DesignModel";
import ModalConnect from "@/components/admin/template/modal-connect.vue";
import ModalDetail from "@/components/admin/template/modal-detail.vue";
import { showMessage } from "@/plugins/flashMessage";

export default {
  name: "ModalMain",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  components: {
    ModalConnect,
    ModalDetail,
    Monaco,
  },
  setup(props, { emit }) {
    const isOpenConnect = ref(false);
    const isOpenDetail = ref(false);
    const formData = reactive({ ...props.item });

    const handleSubmit = async () => {
      await DesignModel.update(
        formData,
        () => {},
        (data) => {
          emit("handle-update", data);
          showMessage("flash.saved");
        }
      );
    };

    return {
      formData,
      isOpenConnect,
      isOpenDetail,
      handleSubmit,
    };
  },
};
</script>
