<template>
  <div class="intro">
    <div class="intro__container">
      <div class="intro__container-inner">
        <transition name="welcome">
          <div v-if="reaming < limit - 1.0">
            <div
              class="intro__logo"
            >
              <img
                class="intro__logo-img"
                src="/static/logo-pobo.png"
              >
            </div>

            <b-progress
              :value="reaming"
              animated
              class="mt-4"
              height="6px"
              :max="limit - 1.0"
              variant="danger"
            />

            <div class="intro__header">
              {{ $t("state.loading") }}
            </div>

            <small class="intro__version">
              <span class="intro__version-name">Rest API:</span>&nbsp;&nbsp;{{ version.api }}
            </small>


            <small class="intro__version">
              <span class="intro__version-name">Client:</span> {{ version.client }}
            </small>


            <small class="intro__version">
              <span class="intro__version-name">Asset:</span>&nbsp;&nbsp;{{ version.asset }}
            </small>

            <small class="intro__version">
              <span class="intro__version-name">AI:</span>&nbsp;&nbsp;{{ version.ai }}
            </small>

            <small class="intro__version">
              <span class="intro__version-name">B2B API:</span>&nbsp;&nbsp;{{ version.b2b }}
            </small>
          </div>
        </transition>
      </div>
    </div>
    
    <div class="intro__video-container">
      <video
        class="intro__video"
        autoplay
        loop
        muted
        playsinline
      >
        <source
          :src="video"
          type="video/mp4"
        >
      </video>
    </div>
  </div>
</template>

<script>
import pcg from "@/../package.json";
export default  {
  name: "Intro",
  props: {
    reaming: {
      type: Number,
      default: 0,
      required: true,
    },

    limit: {
      type: Number,
      default: 0,
      required: true,
    },
  },

  computed: {
    version() {
      return pcg.releaseList;
    },

    environment() {
      return pcg.environment;
    },

    video() {
      return pcg.video;
    },
  }
}
</script>