<template>
  <div class="bg-light p-4">
    <h3 class="pb-2">
      Faktury
    </h3>
    <table class="table">
      <thead>
        <tr class="font-weight-bolder bg-light">
          <td class="border-0">
            Číslo obj.
          </td>
          <td class="border-0 small font-weight-bolder">
            Okno pro odnstalaci vypnuto
          </td>
          <td class="border-0">
            Stav
          </td>
          <td class="border-0">
            Metoda platby
          </td>
          <td class="border-0">
            Vytvořeno
          </td>
          <td class="border-0">
            Odběratel
          </td>
          <td
            class="border-0"
            colspan="2"
          >
            Zobrazit
          </td>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in data"
          :key="item.id"
        >
          <td
            class="align-middle"
            style="width: 140px"
          >
            {{ item.order_id }}
          </td>
          <td
            :class="[
              'align-middle text-white text-center',
              item.user.force_disable ? 'bg-danger' : 'bg-success',
            ]"
            style="width: 150px; font-size: 12px"
          >
            {{ item.user.force_disable ? "Ano, pozor!" : "Ne, v pořádku" }}
            <a
              class="d-block pt-2 text-white"
              href="#"
              @click.prevent="openUser(item.user.id)"
            >
              Změnit
            </a>
          </td>
          <td
            :class="[
              'align-middle text-white text-center',
              item.state === 'paid' ? 'bg-success' : (item.state === 'cancel' ? 'bg-danger' : 'bg-yellow-warning')
            ]"
            style="width: 80px; font-size: 12px"
          >
            {{ item.state === "paid" ? "ZAPLACENO" : (item.state === "cancel" ? "STORNO" : "NEZAPLACENO") }}
          </td>
          <td class="align-middle">
            {{
              item.type === "card"
                ? "Platba platební kartou"
                : "Platba převodem"
            }}
            <small
              v-if="item.type === 'card'"
              class="text-black-50"
            >
              {{ item.transaction_id }}
            </small>
          </td>
          <td
            class="align-middle"
            style="width: 120px"
          >
            {{ when(item.created_at) }}
          </td>
          <td class="align-middle">
            {{ item.person.name }}
          </td>
          <td
            class="align-middle"
            style="width: 100px"
          >
            <a
              :download="item.order_id"
              :href="item.pdf"
              class="btn btn-sm btn-secondary"
            >
              Stáhnout
            </a>
          </td>
          <td
            class="align-middle"
            style="width: 100px"
          >
            <a
              :href="item.pdf"
              class="btn btn-sm btn-secondary"
              target="_blank"
            >
              Zobrazit
            </a>
          </td>
        </tr>
      </tbody>
    </table>

    <b-modal
      id="modal"
      v-model="openUserModal"
      hide-footer
      size="ai"
      title="Náhled e-shopu"
      @close="actionRead"
    >
      <user :user-id="userId" />
    </b-modal>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import { when } from "@/filter/vue";
import User from "@/components/dashboard/user.vue";
import AdminModel from "@/model/AdminModel.js";

export default defineComponent({
  name: "Invoice",
  components: {
    user: User
  },
  setup() {
    const data = ref([]);
    const openUserModal = ref(false);
    const userId = ref(null);

    async function actionRead() {
      await AdminModel.findInvoice(
        () => {},
        (res) => {
          data.value = res;
        }
      );
    }

    function openUser(id) {
      userId.value = id;
      openUserModal.value = true;
    }

    onMounted(() => {
      actionRead();
    });

    return {
      data,
      openUserModal,
      userId,
      when,
      actionRead,
      openUser
    };
  }
});
</script>
