<template>
  <b-form @submit.prevent="handleSubmit">
    <b-form-group label="Název kategorie">
      <b-form-input
        v-model="form.name"
        type="text"
        class="form-control"
        :class="{ 'is-invalid': errors.name }"
        placeholder="Např. „Obrázky a galerie“"
      />
      <div
        v-if="errors.name"
        class="mt-1"
      >
        <small
          v-for="(error, index) in errors.name"
          :key="index"
          class="text-danger d-block"
          v-html="error"
        />
      </div>
    </b-form-group>

    <b-form-group label="Třída ikony">
      <b-form-input
        v-model="form.ico"
        type="text"
        class="form-control"
        :class="{ 'is-invalid': errors.ico }"
        placeholder="Např. „ico-wc-image“"
      />
      <div
        v-if="errors.ico"
        class="mt-1"
      >
        <small
          v-for="(error, index) in errors.ico"
          :key="index"
          class="text-danger d-block"
          v-html="error"
        />
      </div>
    </b-form-group>

    <b-form-group>
      <div class="form-check">
        <input
          id="public"
          v-model="form.public"
          class="form-check-input"
          type="checkbox"
        >
        <label
          class="form-check-label"
          for="public"
        >
          Viditelná kategorie
        </label>
      </div>
      <div
        v-if="errors.public"
        class="mt-1"
      >
        <small
          v-for="(error, index) in errors.public"
          :key="index"
          class="text-danger d-block"
          v-html="error"
        />
      </div>
    </b-form-group>

    <b-form-group>
      <div class="form-check">
        <input
          id="allow_reference"
          v-model="form.allow_reference"
          class="form-check-input"
          type="checkbox"
        >
        <label
          class="form-check-label"
          for="allow_reference"
        >
          Zobrazit jako referenci na webu
        </label>
      </div>
      <div
        v-if="errors.allow_reference"
        class="mt-1"
      >
        <small
          v-for="(error, index) in errors.allow_reference"
          :key="index"
          class="text-danger d-block"
          v-html="error"
        />
      </div>
    </b-form-group>

    <b-form-group>
      <b-btn
        variant="secondary"
        type="submit"
      >
        Odeslat
      </b-btn>
    </b-form-group>
  </b-form>
</template>

<script>
import WidgetCategoryModel from "@/model/WidgetCategoryModel.js";

export default {
  name: "widget-category",
  props: {
    id: {
      type: Number,
      required: false,
    },
  },
  async mounted() {
    if (this.id) {
      await this.loadCategory();
    }
  },
  data() {
    return {
      form: {
        id: null,
        name: "",
        ico: "",
        public: false,
        allow_reference: false,
      },
      errors: {},
      isLoaded: false,
    };
  },
  methods: {
    async loadCategory() {
      try {
        const data = await WidgetCategoryModel.findMe(this.id);
        this.form = data;
        this.isLoaded = true;
      } catch (error) {
        console.error("Error loading category:", error);
      }
    },
    async handleSubmit() {
      try {
        const data = this.id
          ? await WidgetCategoryModel.saveMe(this.form)
          : await WidgetCategoryModel.create(this.form);

        if (data.errors) {
          this.errors = data.errors;
        } else {
          this.$emit("update");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  },
};
</script>
