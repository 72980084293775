<template>
  <warning-low-credits
    :price="5"
    :show="!allowAi"
    image-type="michal"
  />

  <div v-if="countReamingLoadingProduct" class="m-5">
    <h3 class="mb-4">{{ $t("Loading products, please wait") }} ...</h3>
    <div class="bg-white rounded-lg shadow-sm p-3 mb-3">
      {{ $t("Remaining loading") }}
      <strong>{{ countReamingLoadingProduct }}</strong>
      {{ $t("products from") }}
      <strong>{{ countProduct }}</strong>
    </div>
    <b-progress
      :max="countProduct"
      :value="countProduct - countReamingLoadingProduct"
      class="mb-3"
      height="15px"
      animated
      variant="secondary" />
  </div>

  <template v-else>
    <b-overlay
      :show="showOverLay"
      rounded="lg"
      variant="white"
    >
      <template v-slot:overlay>
        <div class="text-center">
          <template v-if="isFinish">
            <b-icon
              icon="check-circle-fill"
              size="50"
              class="text-success"
            />
          </template>
          <template v-else>
            <b-spinner
              :label="$t('Loading ...')"
              variant="primary"
              size="50px"
            />
          </template>
          <h3 class="mt-4">
            <template v-if="isFinish">
              {{ $t("Products have been successfully sent to AI for generation") }}
            </template>
            <template v-else>
              {{ $t("Sending products") }}
            </template>
          </h3>

          <template v-if="isFinish">
            <b-btn @click="emit('close-modal')" variant="secondary" class="mt-4">
              {{ $t("Close modal") }}
            </b-btn>
          </template>
          <template v-else>
             <span class="d-block mt-2">
              {{ $t("Remains to be sent") }}
              <strong>{{ countReamingLoadingProduct }}</strong>
              {{ $t("products from") }}
              <strong>{{ countProduct }}</strong>.
            </span>
          </template>
        </div>
      </template>
      <div class="d-flex">
        <div class="multiple-copy-ai__select pr-4">
          <b-btn-group
            class="mb-4"
            v-model="filterProductType"
          >
            <template v-for="filter in filterMapping">
              <b-btn
                :variant="filterButtonVariant(filter.type)"
                size="sm"
                @click="changeAIFilter(filter.type)"
              >
                {{ $t(filter.text) }} ({{ countProductsStates(filter.type) }})
              </b-btn>
            </template>
          </b-btn-group>
          <div class="multiple-copy-ai__inner">
            <template v-if="isLoaded">
              <template v-if="filterProductList.length > 0">
                <div
                  v-for="(product, index) in filterProductList"
                  :key="index"
                  :class="{'multiple-copy-ai__product--active' : selectedProductIndex === product.original_index, 'loading-state': !product.loaded }"
                  class="multiple-copy-ai__product mb-3 p-2 rounded-lg"
                  @click.prevent="selectedProductIndex = product.original_index"
                >
                  <div class="text-center">
                    <b-icon :icon="iconValid(product)" size="20" :class="classValid(product)" />
                  </div>
                  <div>
              <span class="fs-13 mb-0 d-block font-weight-bold">
                {{ shortName(product.name, 60) }}
              </span>
                    <span
                      v-if="isProductValid(product)"
                      class="m-0 p-0 fs-12 text-success d-block"
                    >
                    {{ $t("Ready to generate") }}
                </span>
                    <span v-else class="m-0 p-0 fs-12 text-danger d-block">
                  {{ $t("Fill") }} {{ fillInfo(product) }}
                </span>
                  </div>
                </div>
              </template>
              <template v-else>
                {{ $t("No products found") }}
              </template>
            </template>
          </div>
        </div>

        <b-form
          class="multiple-copy-ai__setting pl-3"
          @submit.prevent="actionCreateAll"
          v-if="cacheProducts.length > 0"
        >
          <b-row align-v="center" class="mb-4">
            <b-col cols="1">
              <b-img
                :src="productDetail.image"
                fluid
                rounded
                class="border shadow-sm"
              />
            </b-col>
            <b-col cols="8">
              <h5 class="m-0 p-0">
                <a :href="productDetail.url" target="_blank">
                  {{ productDetail.name }}
                </a>
              </h5>
            </b-col>

            <b-col cols="3">
              <a :href="productDetail.url" target="_blank" class="btn btn-secondary btn-sm btn-block">
                {{ $t("Go to product") }}
                <b-icon icon="box-arrow-up-right" size="18px" class="ml-2" />
              </a>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="6">
              <h6 class="mb-3"> {{ $t("Product detail") }} </h6>

              <b-form-group
                id="name"
                :label="$t('product.name')"
                size="sm"
              >
                <b-form-input
                  id="name"
                  v-model="products[selectedProductIndex].name"
                  required
                  size="sm"
                  type="text"
                />

                <small
                  class="text-danger d-block"
                />
              </b-form-group>

              <b-form-group
                id="content"
                :label="$t('product.shortDescription')"
                size="sm"
              >
                <b-form-textarea
                  id="content"
                  v-model="cacheProducts[selectedProductIndex].short_description"
                  rows="3"
                  size="sm"
                />

                <small
                  class="text-danger d-block"
                />
              </b-form-group>

              <b-form-group
                id="parameters"
                :label="$t('product.parameters')"
                size="sm"
              >
                <b-form-textarea
                  id="parameters"
                  v-model="cacheProducts[selectedProductIndex].parameters"
                  rows="3"
                  size="sm"
                />

                <small
                  class="text-danger d-block"
                />
              </b-form-group>

              <b-form-group
                :description="$t('translation.optional.widget.help')"
              >
                <b-form-checkbox
                  v-model="removeExistWidget"
                  class="mb-0"
                >
                  {{ $t("translation.optional.widget.info") }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <h6 class="mb-3">
                {{ $t("Select widget layout") }}
              </h6>

              <widget-ai-preview :layout="layout" :widget="widget" @select-layout="selectLayout" />
            </b-col>
          </b-row>


          <div class="bg-white rounded-lg shadow-sm p-3 mb-3">
            <h5>
              {{ t("share.match.modal.title") }}
            </h5>

            <template v-if="shareList.length > 0">
              <b-form-group class="m-0">
                <b-form-checkbox
                  v-for="option in shareList"
                  v-model="selectedShareItems"
                  :key="option.id"
                  :value="option.id"
                  class="pt-2 border-bottom d-block align-items-center"
                >
                  {{ option.name }}
                  <small class="text-muted ml-2">
                    ({{ t("Position") }}: <strong>{{ option.position }}</strong>, {{ t("count widget") }}:
                    <strong>{{ option.count.standard }}x</strong>)
                  </small>
                </b-form-checkbox>
              </b-form-group>
            </template>
            <template v-else>
              {{ t('No share content found') }},

              <router-link to="share" class="text-secondary text-lowercase">
                {{ $t("share.page.create") }}
              </router-link>.
            </template>
          </div>


          <div class="bg-white rounded-lg shadow-sm p-3 mb-3">
            <b-row align-v="center">
              <b-col cols="6">
          <span>
            <b-icon
              class="mr-1"
              icon="stopwatch"
              size="16"
            />
            {{ $t("Estimated duration") }} <strong>{{ products.length * 19 }}s</strong>.
            {{ $t("You will be charged") }} {{ credit }}
            {{ $t("credits.five") }}.</span>
              </b-col>
              <b-col cols="6">
                <b-btn
                  block
                  type="submit"
                  :disabled="countInvalidProducts > 0"
                  variant="secondary"
                >
                  {{ $t("Create a bulk label via AI") }}
                </b-btn>
              </b-col>
            </b-row>
          </div>
        </b-form>
      </div>
    </b-overlay>
  </template>
</template>

<script lang="ts" setup>
import { computed, defineEmits, defineProps, onMounted, ref } from "vue";
import ProductModel from "@/model/ProductModel";
import GeneratorModel from "@/model/GeneratorModel";
import { showMessage } from "@/plugins/flashMessage";
import WarningLowCredits from "@/share/warning-low-credits.vue";
import { useCreditStore } from "@/store/CreditStore";
import { useI18n } from "vue-i18n";
import { useEshopData } from "@/composables/useEshopData.js";
import { useStatsInfoStore } from "@/store/StatsInfoStore";
import {
  CustomProductDescription,
  FilterAIProduct,
  MultipleCopyAIProps,
  Product, SubmitState,
  Widget
} from "@/interface/GenerativeInterface";
import WidgetAiPreview from "@/components/product/widget-ai-preview.vue";
import GenerativeModel from "@/model/GenerativeModel";
import ShareModel from "@/model/ShareModel";

const { t } = useI18n();
const props = defineProps<MultipleCopyAIProps>();
const emit = defineEmits(["close-modal"]);
const selectedProductIndex = ref<number>(0);
const widget = ref<Widget[]>([]);
const layout = ref<number>(0);
const loaded = ref<boolean>(false);
const submitState = ref<SubmitState>("pending");
const countProduct = ref<number>(props.products.length);
const countSubmitProduct = ref<number>(0);
const cacheProducts = ref<Product[]>([]);
const { eshopId } = useEshopData();

const countReamingLoadingProduct = computed<Number>(() => {
  return cacheProducts.value.filter(product => !product.loaded).length;
});
const filterProductType = ref<FilterAIProduct>("all");
const filterProductList = ref<Product[]>([]);

const countInvalidProducts = computed<Number>(() => {
  return cacheProducts.value.filter(product => !isProductValid(product)).length;
});

const removeExistWidget = ref<boolean>(false);

const selectedShareItems = ref<number[]>([]);
const selectLayout = (id: number): void => {
  layout.value = id;
};

const productDetail = computed<Product>((): Product => {
  return cacheProducts.value[selectedProductIndex.value];
});

const shareList = ref<ShareItem[]>([]);

const isLoaded = ref<boolean>(false);

const countProductsStates = (type: FilterAIProduct): CountProductsStates => {
  const states = {
    all: cacheProducts.value.length,
    "with-errors": cacheProducts.value.filter(product => !isProductValid(product)).length,
    "ready-to-generate": cacheProducts.value.filter(isProductValid).length
  };

  return states[type];
};

const isProductValid = (product: Product): Boolean => {
  return product.short_description && product.parameters;
};

const iconValid = (product: Product): string => {
  return isProductValid(product) ? "check-circle-fill" : "exclamation-triangle-fill";
};

const classValid = (product: Product): string => {
  return isProductValid(product) ? "text-success" : "text-danger";
};

const showOverLay = computed((): boolean => {
  return submitState.value !== "pending";
});

const isFinish = computed((): boolean => {
  return submitState.value === "finish";
});


const changeAIFilter = (type: FilterAIProduct): void => {
  filterProductType.value = type;

  if (type === "all") {
    filterProductList.value = cacheProducts.value;
  } else if (type === "with-errors") {
    filterProductList.value = cacheProducts.value.filter(product => !isProductValid(product));
  } else if (type === "ready-to-generate") {
    filterProductList.value = cacheProducts.value.filter(isProductValid);
  }
};

const filterButtonVariant = (type: FilterAIProduct) => {
  return filterProductType.value === type ? "secondary" : "light";
};

const filterMapping = [
  {
    text: "All",
    type: "all"
  },
  {
    text: "With errors",
    type: "with-errors"
  },
  {
    text: "Ready to generate",
    type: "ready-to-generate"
  }
];

const fillInfo = (product: Product) => {
  const array = [];
  if (!product.short_description) {
    array.push(t("short description"));
  }
  if (!product.parameters) {
    array.push(t("parameters"));
  }
  return array.join(", ");
};

const fetchProduct = async (index: number) => {
  const item = cacheProducts.value[index];

  await ProductModel.findCurrent(
    item.id,
    () => {
    },
    (data) => {
      if (data.product.short_description) {
        cacheProducts.value[index].short_description = data.product.short_description
          ?.replace(/<[^>]+>|&nbsp;/g, " ")
          ?.replace(/\s+/g, " ")
          ?.trim() || "";
      } else {
        cacheProducts.value[index].short_description = null;
      }

      cacheProducts.value[index].loaded = true;
      widget.value = data.widget;

      const remappingParameter = [];

      if (data.product.parameters) {
        data.product.parameters.forEach(({ name, value }) => {
          const formattedName = (
            name.charAt(0).toUpperCase() + name.slice(1)
          )
            .trim()
            .replace(/:$/, "");
          const formattedValue = value
            .replace(/\s+/g, " ")
            .trim();
          remappingParameter.push(
            `${formattedName}: ${formattedValue}`
          );
        });
      }

      cacheProducts.value[index].parameters = remappingParameter
        ? remappingParameter.join(", ")
        : "";

      cacheProducts.value[index].original_index = index;
    }
  );
};

const shortName = (text: string, length: number): string => {
  if (text.length <= length) {
    return text;
  } else {
    return text.substring(0, length) + "...";
  }
};

const actionCreateAll = async () => {
  submitState.value = "launched";
  for (let product of cacheProducts.value) {
    await actionCreate(product);
  }

  const productIds = cacheProducts.value.map(product => product.id);

  for (const shareId of Array.from(selectedShareItems.value)) {
    await ShareModel.matchProduct(
      {
        selected: productIds,
        share_id: shareId
      }
    );
  }

  showMessage("flash.generator.product");
  await useStatsInfoStore().fetch();
  submitState.value = "finish";
};

const actionCreate = (product: Product) => {
  return new Promise<void>((resolve, reject) => {
    const data = {
      name: product.name,
      product_id: product.id,
      layout: layout.value,
      short_description: product.short_description,
      parameters: product.parameters,
      remove_exist_widget: removeExistWidget.value
    };

    GeneratorModel.create(
      data,
      () => {
      },
      (data) => {
        if (data.response?.status === 422) {
          reject(new Error("multiple-ai error"));
        } else {
          resolve();
        }
      }
    );

    countSubmitProduct.value++;
  });
};


onMounted(async () => {
  await useCreditStore().findHistory();


  await ShareModel.findAll(
    "",
    () => {
    },
    (data) => {
      shareList.value = data.list;
    }
  );

  cacheProducts.value = props.products.map(product => ({
    ...product,
    loaded: false,
    additional_content: []
  }));

  for (let i = 0; i < cacheProducts.value.length; i++) {
    await fetchProduct(i);
  }

  // Todo nacist ID z API pres nejaky endpoint
  if (eshopId.value === 1503) {
    const productGuids = cacheProducts.value.map(product => product.guid);
    await GenerativeModel.getCustomProductList(
      productGuids,
      () => {
      }, (data) => {
        const content: CustomProductDescription = data;

        if (content.length > 0) {
          content.forEach((item, index) => {
            const matchingIndex = cacheProducts.value.findIndex(product => product.guid === item.guid);
            if (matchingIndex !== -1) {
              const descriptions = item.old_description_from_suppliers.map((content, idx) => {
                return `Původní popisek v HTML od dodavatele č.${idx + 1}: ${content}`;
              });

              cacheProducts.value[matchingIndex].short_description = `${cacheProducts.value[matchingIndex].short_description ? cacheProducts.value[matchingIndex].short_description : ""}\n\n${descriptions.join("\n\n")}`;
            }
          });
        }
      });
  }

  for (let i = 0; i < cacheProducts.value.length; i++) {
    if (cacheProducts.value[i].short_description === null || cacheProducts.value[i].short_description === "") {
      cacheProducts.value[i].short_description = "No description";
    }
  }

  selectedProductIndex.value = 0;

  changeAIFilter("all");

  isLoaded.value = true;
});
</script>