<template>
  <b-form
    class="pb-1"
    @submit.prevent="$emit('action-update')"
  >
    <h6>
      {{ $t('Product design') }}
    </h6>

    <p>{{ $t('In this section you can set a separate product appearance.') }}</p>
    <div
      class="bg-white p-4 shadow-sm rounded"
      style="overflow-y: auto; height: calc(100vh - 300px)"
    >
      <b-form-group>
        <b-form-radio
          v-for="(design, index) in data"
          :key="index"
          v-model="currentDesign"
          class="mb-2 border-bottom"
          :disabled="eshopTariff === 'start'"
          :value="design.id"
          data-cy="product-detail-change-design"
          @change="changeStatus"
        >
          {{ design.name }}

          <small class="d-block text-black-50">
            {{ design.info }}
          </small>
        </b-form-radio>
      </b-form-group>
    </div>
    <b-form-group>
      <tier-control
        :allow-tariff="['profi', 'premium', 'saas']"
        minimum-tariff="profi"
        position="bottom"
      >
        <b-btn
          variant="secondary"
          class="mt-4"
          type="submit"
          data-cy="product-detail-change-design-submit-btn"
          block
        >
          {{ $t("Save") }}
        </b-btn>
      </tier-control>
    </b-form-group>
  </b-form>
</template>

<script setup>
import { ref, onMounted, defineProps, defineEmits } from 'vue';
import DesignModel from "@/model/front/DesignModel";
import TierControl from "@/share/tier-control.vue";
import { useEshopData } from "@/composables/useEshopData";

const props = defineProps({
  product: {
    type: Object,
    default: () => ({})
  }
});

const emit = defineEmits(['action-update', 'change-design']);

const { eshopTariff } = useEshopData();

const currentDesign = ref(null);
const data = ref([]);

onMounted(async () => {
  await DesignModel.findAll(() => {
  }, (responseData) => {
    data.value = [{
      id: null,
      name: "The default look",
      info: "The default look to use if no other look is selected"
    }, ...responseData.custom];
  });

  currentDesign.value = props.product.design_id;
});

const changeStatus = () => {
  emit("change-design", currentDesign.value);
};
</script>