<template>
  <div>
    <b-form @submit.prevent="actionUpdate">
      <h5 class="mb-3">
        {{ $t("A/B tests") }}
      </h5>

      <div class="bg-white p-4 mb-5 shadow-sm rounded">
        <p class="text-sm mb-4 fs-16 lh-25">
          {{ $t("Enable support for A/B tests of labels using the tool") }}
          <a
            href="https://www.abgar.io"
            class="text-secondary"
            style="text-decoration: underline"
          >ABgar.io</a>.
          {{ $t("If you are interested in the test, write to us at") }}
          <a
            href="mailto:info@pobo.cz"
            class="text-secondary"
            style="text-decoration: underline"
          >info@pobo.cz</a>
        </p>

        <tier-control
          :allow-tariff="['premium', 'saas']"
          minimum-tariff="premium"
          position="left"
        >
          <b-form-group>
            <b-form-checkbox
              id="enable_ab_support"
              v-model="form.enable_ab_support"
              :unchecked-value="false"
              :value="true"
              name="enable_ab_support"
            >
              {{ $t("Enable A/B test support") }}
            </b-form-checkbox>

            <b-btn
              class="mt-3"
              type="submit"
              variant="secondary"
            >
              {{ $t("Save settings") }} <i class="ico ico-arrow-right" />
            </b-btn>
          </b-form-group>
        </tier-control>
      </div>
    </b-form>
  </div>
</template>

<script>
import lang from "@/js/config/lang.js";
import eshopData from "@/mixin/eshopData";
import cloneDeep from "lodash.clonedeep";
import { useEshopStore } from "@/store/EshopStore";
import TierControl from "@/share/tier-control.vue";

export default {
  name: "AbTestSetting",

  mixins: [eshopData],

  async mounted() {
    const { eshop } = this;
    this.form = cloneDeep(eshop);
  },

  data() {
    return {
      lang,
      form: {},
    };
  },

  components: {
    TierControl,
  },

  methods: {
    async actionUpdate() {
      const { form } = this;

      await useEshopStore().updateEshop({
        data: form
      });
    },
  },
};
</script>
