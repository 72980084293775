<template>
  <div class="content__nav">
    <div class="content__container">
      <div class="content__nav-inner">
        <div>
          <router-link to="/">
            <img
              alt="Pobo.cz"
              class="img-fluid"
              src="/static/logo-pobo.png"
            >
          </router-link>
        </div>

        <div class="content__nav-block pl-4">
          <ul class="menu">
            <li
              v-for="(item, index) in navigationMenu"
              :key="index"
              class="menu__li"
            >
              <router-link
                :to="{ name: item.route}"
                class="menu__a first"
              >
                {{ $t(item.lang) }}
              </router-link>

              <span
                v-if="item.isNew"
                class="menu__new"
              >
                <span class="menu__new-inner">
                  Novinka
                </span>
              </span>
            </li>
          </ul>
        </div>

        <switch-template />

        <div>
          <bell-template v-if="false" />
        </div>
        <div>
          <div
            ref="helpMenu"
            :class="['menu__dropdown d-block', isOpenHelpMenu ? 'active' : '']"
          >
            <div
              class="d-flex menu__dropdown-trigger"
              @click.prevent="openHelpMenu(true)"
            >
              <div>
                <b-icon
                  class="mt-1 d-flex"
                  icon="chat-dots"
                  size="20"
                />
              </div>
              <div class="pl-3">
                <div class="font-weight-bolder fs-15 lh-26 d-block">
                  {{ $t("menu.help.support") }}
                </div>
              </div>
            </div>
            <div
              v-if="isOpenHelpMenu"
              class="menu__dropdown-box menu__dropdown-box--help"
            >
              <div
                v-if="isOpenHelpMenu"
                class="menu__dropdown-menu"
              >
                <div
                  v-for="(item, index) in supportMenu"
                  :key="index"
                  class="menu__dropdown-menu__item menu__dropdown-menu__item--half"
                >
                  <a
                    :href="item.href"
                    class="menu__dropdown-menu__item-link"
                  >
                    <span class="menu__dropdown-menu__item-icon">
                      <b-icon
                        :icon="item.icon"
                        size="18"
                      />
                    </span>

                    <span
                      class="menu__dropdown-menu__item-info"
                    >
                      <span class="d-block font-weight-normal fs-15 lh-17">
                        {{ $t(item.lang) }}
                      </span>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            ref="userMenu"
            :class="['menu__dropdown d-block', isOpenUserMenu ? 'active' : '', ]"
          >
            <div
              class="d-flex menu__dropdown-trigger"
              @click.prevent="openUserMenu"
            >
              <div>
                <b-icon
                  class="d-flex"
                  icon="person"
                  size="26"
                />
              </div>
              <div class="pl-2">
                <div class="font-weight-bolder fs-15 lh-26 d-block">
                  <!-- cut max 20 characters -->
                  {{ eshop.humanUrl.length > 20 ? eshop.humanUrl.substring(0, 20) + '...' : eshop.humanUrl }}
                </div>
              </div>
            </div>

            <div
              v-if="isOpenUserMenu"
              class="menu__dropdown-box menu__dropdown-box--user text-dark"
            >
              <div
                class="d-flex align-items-center p-4 rounded mb-2 border-light border-bottom"
              >
                <div>
                  <a
                    :href="eshop.url"
                    class="fs-18 font-weight-bolder d-block mb-2"
                    target="_blank"
                  >

                    <b-icon
                      class="mr-1"
                      icon="shop"
                      variant="black"
                      size="18"
                    />
                    {{ eshop.humanUrl }}

                    (eshop ID:
                    <strong>
                      {{
                        eshop.id
                      }}
                    </strong>)
                  </a>

                  <div class="mt-1 fs-14">
                    <span class="d-block mb-1">
                      E-mail: <strong>{{ eshop.email }}</strong> |

                      <a
                        href="#"
                        @click.prevent="handleLogout"
                      >
                        {{ $t("menu.help.logout") }}
                        <b-icon
                          icon="box-arrow-in-up-right"
                          size="12"
                        />
                      </a>
                    </span>

                    <span class="mr-1">
                      {{ $t("Tariff") }}:
                      <strong>{{ eshop.tariff.toUpperCase() }}</strong>
                    </span>
                    |

                    <template v-if="eshop.platform === 'shoptet'">
                      <span>
                        {{ $t("menu.credit") }}:
                        <strong>{{
                          $t(
                            "inflection.enum.credit",
                            summary.reaming
                          )
                        }}</strong>
                      </span>
                      |
                    </template>

                    <template v-if="eshop.role.includes('admin')">
                      <router-link
                        class="mr-1"
                        :to="{name: 'dashboard'}"
                      >
                        {{ $t("menu.help.admin") }}
                      </router-link>
                    </template>
                  </div>
                </div>
              </div>

              <switch-eshop
                @switch-eshop="switchEshop"
              />

              <div class="menu__dropdown-menu">
                <div
                  v-for="(item, index) in filterNavigation"
                  :key="index"
                  class="menu__dropdown-menu__item"
                >
                  <router-link
                    :to="{ name: item.name }"
                    class="menu__dropdown-menu__item-link"
                  >
                    <span class="menu__dropdown-menu__item-icon">
                      <b-icon
                        :icon="item.icon"
                        size="15"
                      />
                    </span>

                    <span
                      class="menu__dropdown-menu__item-info"
                    >

                      <span
                        class="d-block fs-16"
                      >
                        {{ $t(item.lang.header) }}
                      </span>


                      <small
                        v-if="false"
                        class="d-block text-muted lh-17 fs-13"
                      >
                        {{ $t(item.lang.title) }}
                      </small>
                    </span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navigation from "@/components/setting/navigation.json";
import BellTemplate from "@/layout/part/bell-template.vue";
import SwitchTemplate from "@/layout/part/switch-template.vue";
import EshopModel from "@/model/EshopModel";
import { mapWritableState } from "pinia";
import { useCreditStore } from "@/store/CreditStore.js";
import { useGeneratorStore } from "@/store/GeneratorStore.js";
import eshopData from "@/mixin/eshopData";
import SwitchEshop from "@/layout/part/switch-eshop.vue";
import Cookies from "js-cookie";
import axios from "axios";

export default {
  name: "menu-template",

  mixins: [eshopData],

  data() {
    return {
      navigation,
      isOpenUserMenu: false,
      isOpenHelpMenu: false,
      supportEmail: import.meta.env.VITE_SUPPORT_EMAIL
    };
  },

  computed: {
    ...mapWritableState(useGeneratorStore, {
      reamingCronGenerator: "reaming",
    }),

    ...mapWritableState(useCreditStore, {
      summary: "summary",
    }),

    getSupportUrl(){
      return "https://pobo-support.com/" + this.editorLang + "/?eshop=" + this.eshopUrl + "&tariff=" + this.eshopTariff + "&multilang=" + this.isConfigureLang + "&is-shoptet=" + this.isShoptet;
    },

    supportMenu() {
      return [
        {
          href: this.getSupportUrl,
          icon: "life-preserver",
          lang: "menu.help.form",
        },
        {
          href: "/blog",
          icon: "newspaper",
          lang: "menu.help.blog",
        },
        {
          href: "/functions/todo",
          icon: "check-all",
          lang: "menu.help.todo",
        },

        {
          href: "/faq",
          icon: "question-circle",
          lang: "menu.help.faq",
        },
      ];
    },

    navigationMenu() {
      const { eshop } = this;
      const { platform } = eshop;

      return [
        {
          route: "product",
          lang: "menu.product",
          allowPlatform: ["shoptet", "shopify", "b2b"],
          isNew: false,
          show: true,
        },
        {
          route: "category",
          lang: "menu.category",
          allowPlatform: ["shoptet", "shopify", "b2b"],
          isNew: false,
          show: true,
        },
        {
          route: "blog",
          lang: "menu.blog",
          allowPlatform: ["shoptet", "shopify"],
          isNew: false,
          show: true,
        },
        {
          route: "share",
          lang: "menu.share",
          allowPlatform: ["shoptet", "shopify", "b2b"],
          isNew: false,
          show: true,
        },
        {
          route: "design-public",
          lang: "Design",
          allowPlatform: ["shoptet", "shopify", "b2b"],
          isNew: false,
          show: true,
        },
        {
          route: "analytics",
          lang: "menu.analytics",
          allowPlatform: ["shoptet", "shopify"],
          isNew: false,
          show: true,
        },
        {
          route: "static-ab-test",
          lang: "A/B testing",
          allowPlatform: ["shoptet"],
          isNew: false,
          show: true,
        },
      ].filter(
        ({ allowPlatform, show }) =>
          allowPlatform.includes(platform) && show
      );
    },

    filterNavigation() {
      const { navigation, eshop } = this;
      const { platform } = eshop;

      return navigation.filter(
        ({ visible, allowPlatform }) =>
          visible === true && allowPlatform.includes(platform)
      );
    },

    limit() {
      return 3;
    },
  },

  watch: {
    $route() {
      this.isOpenUserMenu = false;
      this.isOpenHelpMenu = false;
      document.body.classList.remove('editor-mode-editor', 'editor-mode-live');
    },

    isOpenHelpMenu() {
      this.manageClickOutsideListener();
    },

    isOpenUserMenu() {
      this.manageClickOutsideListener();
    }
  },

  components: {
    "bell-template": BellTemplate,
    "switch-template": SwitchTemplate,
    "switch-eshop": SwitchEshop
  },


  methods: {
    async handleLogout() {
      await EshopModel.logout().then(() => {
        window.location.href = "/login";
      })
    },

    async switchEshop(eshopId) {
      await EshopModel.loginAnotherEshop(eshopId, () => {
        },
        (data) => {
          const { token } = data;
          if (token) {
            Cookies.set("access_token", token);
          } else {
            alert("Warning: Token is empty");
          }
        }, () => {
        },
        () => {
          window.location.href = "/";
        });
    },
    openUserMenu() {
      this.isOpenHelpMenu = false;
      this.isOpenUserMenu = !this.isOpenUserMenu;
    },

    openHelpMenu() {
      this.isOpenUserMenu = false;
      this.isOpenHelpMenu = !this.isOpenHelpMenu;
    },

    closeMenus(event) {
      const helpMenuElement = this.$refs.helpMenu;
      const userMenuElement = this.$refs.userMenu;

      if ((this.isOpenHelpMenu && helpMenuElement && !helpMenuElement.contains(event.target)) ||
          (this.isOpenUserMenu && userMenuElement && !userMenuElement.contains(event.target))) {
        this.isOpenHelpMenu = false;
        this.isOpenUserMenu = false;
      }
    },

    manageClickOutsideListener() {
      if (this.isOpenHelpMenu || this.isOpenUserMenu) {
        document.addEventListener('click', this.closeMenus);
      } else {
        document.removeEventListener('click', this.closeMenus);
      }
    }
  },
};
</script>

<style scoped></style>
