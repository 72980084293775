<template>
  <div class="bg-white p-4">
    <h3 class="pb-2">
      Šablony - vzhledy popisků
    </h3>

    <b-alert
      variant="info"
      show
    >
      Po upravení designu je potřeba udělat purge na soubor, viz.
      <a
        href="https://dash.bunny.net/purge"
        class="text-info"
        target="_blank"
      >dash.bunny.net/purge</a>.
    </b-alert>

    <b-btn
      variant="secondary mb-4"
      @click="isOpenCreate = true"
    >
      Create design
    </b-btn>

    <b-modal
      v-model="isOpenCreate"
      title="Create design"
      hide-footer
      size="hd"
    >
      <modal-detail
        @handle-close="refresh"
      />
    </b-modal>

    <table class="table table-bordered table-striped">
      <thead>
        <tr
          class="font-weight-bolder bg-light"
          style="font-size: 14px"
        >
          <td class="border-0">
            ID
          </td>
          <td
            class="border-0"
            style="width: 220px"
          >
            Vytvořeno
          </td>
          <td class="border-0">
            Název šablony
          </td>
          <td class="border-0">
            Vzdálený název šablony
          </td>
          <td class="border-0">
            Design type
          </td>
          <td class="border-0">
            Viditelnost
          </td>
          <td class="border-0">
            Náhled šablony
          </td>
          <td
            class="border-0"
            style="width: 220px"
          >
            Akce
          </td>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in data"
          :key="item.id"
        >
          <td class="align-middle">
            {{ item.id }}
          </td>
          <td class="align-middle">
            {{ datetime(item.created_at) }}
            <small class="text-muted mt-1 d-block fs-13">
              Upraveno: {{ datetime(item.updated_at) }}
            </small>
          </td>
          <td class="align-middle">
            {{ item.name }}
            <div
              v-if="item.connected.length > 0"
              class="p-1 bg-light mt-1"
            >
              <small class="d-block text-muted fs-11">Přidané pro e-shopy</small>
              <ul
                v-for="(eshop, index) in item.connected"
                :key="index"
                class="text-muted fs-13 pl-3 mt-0"
              >
                <li>{{ eshop.url.replace(/^https?:\/\//, "") }}</li>
              </ul>
            </div>
          </td>
          <td class="align-middle">
            {{ item.remote_name ? item.remote_name : 'Design has no remote name' }}
          </td>
          <td class="align-middle">
            {{ item.type === 'public' ? 'Design for all (free)' : 'Custom design ($$$)' }}
            <code class="d-block fs-12 mt-2">
              https://image.pobo.cz/design/{{ item.id }}.css
            </code>
          </td>
          <td
            :class="[item.visible ? 'text-success' : 'text-danger', 'align-middle']"
          >
            {{ item.visible ? "Visible" : "Hidden" }}
          </td>
          <td class="align-middle text-center">
            <a
              :href="item.image_preview"
              target="_blank"
            >
              <b-img-lazy
                height="50"
                :src="item.image_preview"
              />
            </a>
          </td>
          <td class="align-middle">
            <b-btn-group class="shadow-sm">
              <b-btn
                variant="secondary"
                size="sm"
                @click="openEditModal(item)"
              >
                Upravit
              </b-btn>
              <confirm-delete
                :id="item.id"
                @delete="actionDelete"
              >
                <template #trigger>
                  <i class="ico ico-component-delete" />
                  {{ $t("Delete design") }}
                </template>
              </confirm-delete>
            </b-btn-group>
          </td>
        </tr>
      </tbody>
    </table>

    <div
      v-if="isOpenEdit"
      class="modal-editor"
    >
      <modal-main
        :item="selectedItem"
        @handle-update="fetchData"
        @handle-close="isOpenEdit = false"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import DesignModel from "@/model/admin/DesignModel";
import ModalMain from "@/components/admin/template/modal-main.vue";
import ModalDetail from "@/components/admin/template/modal-detail.vue";
import ConfirmDelete from "@/share/confirm-delete.vue";
import { datetime } from "@/filter/vue";

export default defineComponent({
  name: "TemplateDesign",
  components: {
    ModalDetail,
    "modal-main": ModalMain,
    "confirm-delete": ConfirmDelete,
  },
  setup() {
    const data = ref([]);
    const isOpenCreate = ref(false);
    const isOpenEdit = ref(false);
    const selectedItem = ref({});

    const fetchData = async () => {
      data.value = await DesignModel.findAll();
    };

    const refresh = async (newData) => {
      data.value = newData;
      isOpenCreate.value = false;
    };

    const actionDelete = async (id) => {
      const item = data.value.find((item) => item.id === id);
      if (item) {
        item.delete = true;
        await DesignModel.update(
          item,
          () => {},
          (updatedData) => {
            data.value = updatedData;
          }
        );
      }
    };

    const openEditModal = (item) => {
      selectedItem.value = item;
      isOpenEdit.value = true;
    };

    onMounted(fetchData);

    return {
      data,
      isOpenCreate,
      isOpenEdit,
      selectedItem,
      fetchData,
      refresh,
      actionDelete,
      openEditModal,
      datetime,
    };
  },
});
</script>
