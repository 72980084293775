<template>
  <div
    v-if="!isClosed"
    ref="helpBox"
    class="bg-white shadow-sm rounded border-light mb-4"
    :class="classes"
  >
    <div class="help-box">
      <div
        class="help-box__trigger shadow-sm bg-light"
      >
        <a
          href="#"
          data-cy="help-box-close"
          class="text-danger font-weight-bold"
          @click.prevent="closeBox"
        >
          <b-icon
            icon="bi bi-x-circle"
            size="24"
          />
        </a>
      </div>
      <div class="help-box__info">
        <slot name="default" />
      </div>
      <div class="help-box__video">
        <div class="help-box__video-preview rounded shadow-sm border-light">
          <a
            href="#"
            @click.prevent="openVideo"
          >
            <b-img-lazy
              :src="`https://i3.ytimg.com/vi/${video.id}/hqdefault.jpg`"
              fluid
            />
          </a>
        </div>
        <a
          href="#"
          class="d-flex justify-content-center align-items-center fs-14 font-weight-bolder mt-1"
          @click.prevent="openVideo"
        >
          <b-icon
            icon="youtube"
            size="20"
            class="text-danger mr-2"
          /> {{ video.time }}
        </a>
      </div>
    </div>
  </div>

  <b-modal
    v-model="isOpenModal"
    hide-header
    hide-footer
    size="lg"
    content-class="editor-modal"
  >
    <b-embed
      type="iframe"
      aspect="16by9"
      class="routed"
      :src="`https://www.youtube.com/embed/${video.id}`"
      allowfullscreen
    />
  </b-modal>
</template>

<script>
import eshopData from "@/mixin/eshopData";
export default {
  name: 'help-box',

  mixins: [eshopData],

  props: {
    author: {
      type: String,
      required: true
    },

    type: {
      type: String,
      required: true
    },

    classes: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      isOpenModal: false,
    }
  },

  computed: {
    photo() {
      const { author } = this

      switch (author) {
      case 'sara':
        return 'https://pobo-cdn.b-cdn.net/static/sara-pietakova.png'
      case 'vojta':
        return 'https://pobo-cdn.b-cdn.net/static/vojtech-vanek-team.png'
      case 'michal':
      default:
        return 'https://pobo-cdn.b-cdn.net/static/michal-stikar-team-2.png'
      }
    },

    video: function () {
      const {type} = this

      let output = {}

      switch (type) {
      case 'image':
        output = {
          en: {
            id: 'iHQ-ZqjTYCc',
            time: '2m 6s',
          },
          cs: {
            id: 'Gh6w8plOnaM',
            time: '1m 46s',
          },
          sk: {
            id: 'Gh6w8plOnaM',
            time: '1m 46s',
          },
          hu: {
            id: 'iHQ-ZqjTYCc',
            time: '2m 6s',
          },
        }
        break;

      case 'blog':
        output = {
          en: {
            id: 'xMY9xJFrWoM',
            time: '3m 45s',
          },
          cs: {
            id: 'vz_-uTVyKo4',
            time: '2m 3s'
          },
          sk: {
            id: 'vz_-uTVyKo4',
            time: '2m 3s'
          },
          hu: {
            id: 'xMY9xJFrWoM',
            time: '3m 45s',
          },
        }
        break;

      case 'share':
        output = {
          en: {
            id: 'wnavQHYbgZg',
            time: '3m 05s',
          },
          cs: {
            id: 'mp-OQfQd82w',
            time: '1m 40s',
          },
          sk: {
            id: 'mp-OQfQd82w',
            time: '1m 40s',
          },
          hu: {
            id: 'wnavQHYbgZg',
            time: '3m 05s',
          },
        }
        break;

      case 'ai':
        output = {
          en: {
            id: 'wTl4zH7-plo',
            time: '2m 14s',
          },
          cs: {
            id: 'FZjSSsjv_wI',
            time: '1m 37s',
          },
          sk: {
            id: 'FZjSSsjv_wI',
            time: '1m 37s',
          },
          hu: {
            id: 'wTl4zH7-plo',
            time: '2m 14s',
          },
        }
        break;

      case 'designer':
        output = {
          en: {
            id: 'f0J59z5XR8s',
            time: '6m 48s',
          },
          cs: {
            id: 'DoV0X9rmGlw',
            time: '1m 26s'
          },
          sk: {
            id: 'DoV0X9rmGlw',
            time: '1m 26s'
          },
          hu: {
            id: 'f0J59z5XR8s',
            time: '6m 48s',
          },
        }
        break;

      case 'tariff':
        output = {
          en: {
            id: '1TAVf6lCuyM',
            time: '3m 52s',
          },
          cs: {
            id: 'nyDJ7b9fOAo',
            time: '4m 31s',
          },
          sk: {
            id: 'nyDJ7b9fOAo',
            time: '4m 31s',
          },
          hu: {
            id: '1TAVf6lCuyM',
            time: '3m 52s',
          },
        }
        break;

      case 'collaborators':
        output = {
          en: {
            id: 'IYenbh93b2E',
            time: '1m 12s',
          },
          cs: {
            id: 'txTZcIcUkpE',
            time: '1m 12s',
          },
          sk: {
            id: 'txTZcIcUkpE',
            time: '1m 12s',
          },
          hu: {
            id: 'IYenbh93b2E',
            time: '1m 12s',
          },
        }
        break;

      case 'theme':
        output = {
          en: {
            id: 'BTn_DgJ9244',
            time: '1m 12s',
          },
          cs: {
            id: 'XXR332rhD3k',
            time: '1m 12s',
          },
          sk: {
            id: 'XXR332rhD3k',
            time: '1m 12s',
          },
          hu: {
            id: 'BTn_DgJ9244',
            time: '1m 12s',
          },
        }
        break;
      case 'multilang':
        output = {
          en: {
            id: '1SJpz5x8Tqg',
            time: '1m 20s',
          },
          cs: {
            id: '1SJpz5x8Tqg',
            time: '1m 20s',
          },
          sk: {
            id: '1SJpz5x8Tqg',
            time: '1m 20s',
          },
          hu: {
            id: '1SJpz5x8Tqg',
            time: '1m 20s',
          },
        }
        break;
      case 'how':
        output = {
          en: {
            id: 'qUMD8GP9iKE',
            time: '6m 48s',
          },
          cs: {
            id: '1Fy6afQ31iA',
            time: '1m 36s',
          },
          sk: {
            id: '1Fy6afQ31iA',
            time: '1m 36s',
          },
          hu: {
            id: 'qUMD8GP9iKE',
            time: '6m 48s',
          },
        }
        break;

      case 'copy':
        output = {
          en: {
            id: 's8fxilJX5BE',
            time: '1m 24s',
          },
          cs: {
            id: 'zwP2JtHm3b0',
            time: '0m 40s'
          },
          sk: {
            id: 'zwP2JtHm3b0',
            time: '0m 40s'
          },
          hu: {
            id: 's8fxilJX5BE',
            time: '1m 24s',
          },
        }
        break;

      case 'use':
        output = {
          en: {
            id: 'qUMD8GP9iKE',
            time: '6m 50s',
          },
          cs: {
            id: '1Fy6afQ31iA',
            time: '1m 36s',
          },
          sk: {
            id: '1Fy6afQ31iA',
            time: '1m 36s',
          },
          hu: {
            id: 'qUMD8GP9iKE',
            time: '6m 50s',
          },
        }
        break;
      }

      return output[this.eshop.lang]
    },
 
    closeList() {
      return JSON.parse(localStorage.getItem('closeList')) || []
    },

    isClosed() {
      const { type, closeList } = this
      return closeList.includes(type)
    }
  },

  methods: {
    openVideo() {
      this.isOpenModal = true
    },

    closeBox() {
      const { type } = this

      const closeList = this.closeList

      if (!closeList.includes(type)) {
        closeList.push(type)
        localStorage.setItem('closeList', JSON.stringify(closeList))
        this.$refs.helpBox.remove()
      }
    },
  }
}
</script>
