<template>
  <div class="grid-layout__filter">
    <a
      v-for="(filter, index) in list"
      :key="index"
      :class="[
        filter.type === current ? 'active' : '',
        'grid-layout__filter-item',
      ]"
      href="#"
      @click.prevent="emit('change', filter.type)"
    >
      <i :class="[filter.ico, 'ico']" />
      {{ $t(filter.translate) }}
      <span
        v-if="stats[filter.key] !== undefined && !hideCount"
        class="badge badge-secondary ml-1 px-1 py-1 text-white small"
      >
        {{ stats[filter.key] }}
      </span>
    </a>
    <slot name="additional" />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStatsInfoStore } from "@/store/StatsInfoStore";
import { storeToRefs } from "pinia";

const props = defineProps({
  type: {
    type: String,
    required: true,
    default: "product"
  },
  hideCount:{
    type: Boolean,
    required: false,
    default: false
  },
  list: {
    type: Array,
    required: true,
    default: () => []
  },
  current: {
    type: Number,
    required: true,
    default: 1
  }
});

const emit = defineEmits(['change']);

const statsStore = useStatsInfoStore();
const { product, category, blog } = storeToRefs(statsStore);

const stats = computed(() => {
  const { type } = props;
  if (type === 'product') return product.value;
  if (type === 'category') return category.value;
  if (type === 'blog') return blog.value;
  return {};
});
</script>