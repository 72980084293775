<template>
  <div
    v-if="widget.is_original"
    class="content-editor__panel--original p-2 mb-5"
  >
    <div class="row align-items-center">
      <div class="col-12">
        <strong class="d-block">{{ $t("panel.original.title") }}</strong>
        <small class="text-muted d-block">
          {{ $t("panel.original.info") }}
        </small>
      </div>
    </div>
  </div>

  <div
    :class="['content-editor__panel', { 'content-editor__panel--expanded': toolbarExpanded }]"
    @mouseenter="expandToolbar"
    @mouseleave="collapseToolbar"
  >
    <button class="btn btn-secondary btn-sm btn-drag btn-rounded">
      <i class="ico ico-component-drag" />
    </button>

    <button
      v-if="!toolbarExpanded"
      class="btn btn-secondary btn-sm btn-dots btn-rounded"
    >
      <i class="ico ico-dots" />
    </button>

    <div v-else>
      <button
        class="btn btn-secondary btn-sm btn-drag btn-rounded"
        @click.prevent="moveWidget('move-up', widget.id)"
      >
        <i class="ico ico-arrow-up" />
      </button>

      <button
        class="btn btn-secondary btn-sm btn-drag btn-rounded"
        @click.prevent="moveWidget('move-down', widget.id)"
      >
        <i class="ico ico-arrow-down" />
      </button>

      <div class="confirm-delete">
        <button
          class="btn btn-danger btn-sm text-white mt-1 btn-rounded"
          data-cy="widget-delete-button"
          @click.prevent="openConfirmModal(true)"
        >
          <i class="ico ico-component-delete" />
        </button>

        <transition>
          <div
            v-if="isConfirmOpen"
            class="confirm-delete__message"
          >
            <div class="confirm-delete__inner">
              <span class="confirm-delete__header">{{
                $t("panel.delete.modal.title")
              }}</span>
              <div class="container">
                <div class="d-flex">
                  <div class="w-50 pr-1">
                    <button
                      class="btn btn-sm btn-danger btn-block"
                      data-cy="widget-delete-button-confirm"
                      @click="
                        $emit('handle-delete', widget.id);
                        openConfirmModal(false);
                      "
                    >
                      {{ $t("confirmDelete.delete") }}
                    </button>
                  </div>
                  <div class="w-50 pl-1">
                    <button
                      class="btn btn-secondary btn-sm btn-block"
                      @click="openConfirmModal(false)"
                    >
                      {{ $t("confirmDelete.abort") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>

      <button
        class="btn btn-info btn-sm text-white mt-1 btn-rounded"
        data-cy="widget-open-favourite-modal"
        @click.prevent="openFavouriteModal(true)"
      >
        <i class="ico ico-component-favourite" />
      </button>
    </div>

    <b-modal
      v-model="isTarifStart"
      hide-footer
      hide-header
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      size="md"
      centered
      class="text-dark"
    >
      <div class="text-center mb-4">
        <h2 class="fs-4 fw-bold pt-4">
          {{ $t('This feature is available from the PROFI plan') }}
        </h2>
      </div>
      <p class="text-muted">
        {{ $t('In addition to a more efficient editor, an unlimited number of products, categories, and articles, and priority customer support, you will also get:') }}
      </p>
      <ul class="list-unstyled mb-4">
        <li class="mb-2">
          <i class="bi bi-check-circle-fill text-success me-2" />
          {{ $t("Setup and evaluation of the first A/B test") }}
        </li>
        <li class="mb-2">
          <i class="bi bi-check-circle-fill text-success me-2" />
          {{ $t("Adding collaborators") }}
        </li>
        <li class="mb-2">
          <i class="bi bi-check-circle-fill text-success me-2" />
          {{ $t("Switching between e-shops") }}
        </li>
        <li class="mb-2">
          <i class="bi bi-check-circle-fill text-success me-2" />
          {{ $t("Bulk editing for shared descriptions") }}
        </li>
        <li class="mb-2">
          <i class="bi bi-check-circle-fill text-success me-2" />
          {{ $t("Multi-language support (without DeepL)") }}
        </li>
        <li class="mb-2">
          <i class="bi bi-check-circle-fill text-success me-2" />
          {{ $t("Photobank with 5 000 000 pictures") }}
        </li>
        <li>
          <i class="bi bi-check-circle-fill text-success me-2" />
          {{ $t("Compatible labels for marketplace") }}
        </li>
      </ul>
      <div class="text-center">
        <button
          class="btn btn-secondary btn-lg"
          @click.prevent="toPriceTable"
        >
          {{ $t('Yes, I want to increase my sales!') }}
        </button>
        <p class="mt-1 text-muted fs-13">
          {{ $t('For the price of 2 coffees per month') }}
        </p>
      </div>

      <p
        class="text-center"
        style="text-decoration: underline; cursor: pointer"
        @click.prevent="isTarifStart = false"
      >
        {{ $t('No, thank you, I am satisfied with average results') }}
      </p>
    </b-modal>


    <b-modal
      v-model="isFavouriteOpen"
      :title="$t('panel.original.modal.title')"
      hide-footer
      content-class="editor-modal"
      size="md"
    >
      <b-form
        novalidate
        @submit.prevent="addToFavourite"
      >
        <p>
          {{ $t("panel.original.modal.top") }}
        </p>

        <p>{{ $t("panel.original.modal.bottom") }}</p>

        <hr>

        <b-form-group
          id="name"
          :description="$t('panel.original.form.help')"
          :label="$t('panel.original.form.name')"
        >
          <b-form-input
            id="name"
            v-model="favouriteModel.name"
            data-cy="widget-favourite-input"
            :class="errors.name ? 'is-invalid' : ''"
            :placeholder="$t('panel.original.form.placeholder.name')"
            required
            type="text"
          />

          <small class="form-text text-muted">
            {{ $t("panel.original.form.help") }}
          </small>
        </b-form-group>

        <div
          v-if="errors"
          class="mb-2"
        >
          <small
            v-for="error in errors.name"
            :key="error"
            class="text-danger d-block"
            v-html="error"
          />
        </div>

        <b-btn-group class="w-100">
          <b-btn
            variant="danger"
            @click="openFavouriteModal(false)"
          >
            <i class="ico ico-cancel" />{{ $t("panel.original.form.close") }}
          </b-btn>
          <b-btn
            data-cy="widget-favourite-submit"
            type="submit"
            variant="secondary"
          >
            <i class="ico ico-component-favourite" />
            {{ $t("panel.original.form.save") }}
          </b-btn>
        </b-btn-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import WidgetFavouriteModel from "@/model/WidgetFavouriteModel.js";
import eshopData from "@/mixin/eshopData";

export default {
  name: "WidgetPanel",

  mixins: [eshopData],
  props: {
    widget: {
      type: Object,
      require: true,
      default: null,
    },
  },

  emits: ["handle-delete"],

  data() {
    return {
      isConfirmOpen: false,
      isFavouriteOpen: false,
      favouriteModel: {
        name: null,
      },
      toolbarExpanded: false,
      errors: [],
      isTarifStart: false,
    };
  },

  methods: {
    openConfirmModal(state) {
      this.isConfirmOpen = state;
    },

    toPriceTable(){
      this.$router.push('/merchant/setting/change-tariff');
    },

    moveWidget(type, id){
      const { eshopTariff } = this;
      if (eshopTariff === "start") {
        this.isTarifStart = true;
      }else{
        this.$emit(type, id);
      }
    },

    openFavouriteModal(state) {
      this.isFavouriteOpen = state;
    },

    expandToolbar() {
      this.toolbarExpanded = true;
    },

    collapseToolbar() {
      if(!this.isConfirmOpen){
        this.toolbarExpanded = false;
      }
    },

    async addToFavourite() {
      const { widget, favouriteModel } = this;
      const { widget_id, children } = widget;
      const { name } = favouriteModel;

      await WidgetFavouriteModel.create(
        { name, widget_id, children },
        () => {
        },
        (data) => {
          if (data.response?.status === 422) {
            this.errors = data.response.data.errors
          } else {
            this.isFavouriteOpen = false;
            this.$emit("create-favourite");
          }
        },
      );
    },
  },
};
</script>
