<template v-if="data">
  <div class="p-2">
    <template v-if="isOpen('copy')">
      <h5>{{ $t("blog.upload.copy.title") }}</h5>
      <p class="mt-2">
        {{ $t("blog.upload.copy.content") }}
      </p>

      <template v-if="isConfigureLang">
        <div class="content-editor__languages-flags pb-3">
          <a
            :class="[
              'content-editor__languages-flag',
              'default' === selectedLang ? 'border-danger' : 'border',
            ]"
            href="#"
            @click.prevent="selectLang('default')"
          >
            <img
              :src="currentFlag"
              class="w-100"
            >
          </a>

          <a
            v-for="(flag, index) in sortLang"
            :key="index"
            :class="[
              'content-editor__languages-flag',
              flag === selectedLang ? 'border-danger' : 'border',
            ]"
            href="#"
            @click.prevent="selectLang(flag)"
          >
            <img
              :src="`https://pobo-cdn.b-cdn.net/flag/${flag}.svg`"
              class="w-100"
            >
          </a>
        </div>
      </template>

      <textarea
        id="html"
        ref="html"
        class="form-control form-control-sm bg-white"
        readonly
        rows="4"
      >{{ data.html }}</textarea>

      <a
        class="btn btn-secondary btn-block mt-4"
        href="#"
        @click.prevent="copyToClipboard"
      >
        {{ $t("blog.upload.copy.submit") }}
      </a>
    </template>

    <template v-if="isOpen('login')">
      <h5>{{ $t("blog.upload.login.title") }}</h5>
      <p>{{ $t("blog.upload.login.content") }}</p>

      <a
        class="btn btn-secondary btn-block my-4"
        href="#"
        @click.prevent="handleLogin"
      >
        {{ $t("blog.upload.login.goEshop") }}
      </a>

      <a
        href="https://cdn.pobo.cz/asset/upload-article.gif"
        target="_blank"
      >
        <span class="d-block text-center">{{
          $t("blog.upload.login.guide")
        }}</span>
        <img
          class="img-fluid img-thumbnail"
          src="https://cdn.pobo.cz/asset/upload-article.gif"
        >
      </a>
    </template>
  </div>
</template>
<script>
import EshopModel from "@/model/EshopModel.js";
import BlogModel from "@/model/BlogModel.js";
import lang from "@/js/config/lang.js";

export default {
  name: "BlogHtml",
  props: {
    id: {
      type: Number,
      require: true,
    },
  },

  async mounted() {
    await EshopModel.findMe(
      () => {},
      (data) => {
        this.user = data;
        this.isLoaded = true;
      }
    );

    this.findHTML();
  },

  data() {
    return {
      data: Object,
      user: Object,
      active: ["copy"],
      isLoaded: false,
      lang,
      selectedLang: "default",
    };
  },

  computed: {
    sortLang() {
      const { user } = this;
      const { editor_lang, lang_list } = user;
      return lang.filter(
        (flag) => flag !== editor_lang && lang_list.includes(flag)
      );
    },

    currentFlag() {
      const { user } = this;
      const { editor_lang } = user;
      return `https://pobo-cdn.b-cdn.net/flag/${editor_lang}.svg`;
    },

    allowMultiLang() {
      const { user } = this;
      const { platform } = user;
      return platform === "shoptet";
    },

    isConfigureLang() {
      const { user } = this;
      const { allow_lang } = user;
      return allow_lang === true;
    },

    multiLangClass() {
      const { allowMultiLang } = this;
      return [
        allowMultiLang
          ? "content-editor__sticky--enable"
          : "content-editor__sticky--disable",
        "content-editor__sticky",
      ];
    },
  },

  watch: {
    selectedLang: function () {
      this.findHTML();
    },
  },

  methods: {
    open(block) {
      this.active = block;
    },

    copyToClipboard() {
      this.$refs.html.select();
      document.execCommand("copy");
      this.open(["login"]);
    },

    findHTML() {
      const { id } = this;
      const { selectedLang } = this;
      const object = { id, selectedLang };
      BlogModel.findHtml(
        object,
        () => {},
        (data) => {
          this.data = data;
        }
      );
    },

    handleLogin() {
      const { url } = this.user;
      window.open(
        `${url}admin/clanky/stranky/`,
        "_blank",
        "menubar=no,top=10,left=10,width=980,height=980"
      );
    },

    isOpen(block) {
      return this.active.includes(block);
    },

    selectLang(flag) {
      this.selectedLang = flag;
      this.$emit("update-lang", flag);
    },
  },
};
</script>

<style scoped></style>