<template>
  <b-form @submit.prevent="actionUpdate">
    <layout-setting anchor="export">
      <template #header>
        {{ $t("Appearance mode settings") }}
      </template>
      <template #content>
        <b-form-group>
          <b-form-radio
            v-model="form.template_mode"
            value="standalone"
            class="mb-3 pb-3 border-bottom"
          >
            {{ $t("Old version of the label styling system") }}

            <b-badge
              variant="danger"
              class="d-inline-block p-1 ml-1"
            >
              {{ $t("Legacy mode") }}
            </b-badge>
            <small class="d-block text-black-50 pt-2">
              {{ $t("Choose this option if you have problems with displaying product descriptions, categories or articles on the e-shop") }}.
            </small>
          </b-form-radio>

          <b-form-radio
            v-model="form.template_mode"
            value="generic"
          >
            {{ $t("Generic label styling system") }}
            <b-badge
              variant="secondary"
              class="d-inline-block p-1 ml-1"
            >
              {{ $t("Recommended") }}
            </b-badge>
            <small class="d-block text-black-50 pt-2">
              {{ $t("The appearance of labels is set using CSS variables and allows you to customize the design of specific widgets individually") }}.
              {{ $t("The generic system has, among other things, a function to display widgets on 100% monitor width (in case of compatible templates)") }}.


              {{ $t("This mode is compatible with") }}
              <a
                href="../../../static/live-designer"
                target="_blank"
                class="text-secondary"
              >Live Designer</a>,
              {{ $t('which allows you to set the appearance of the e-shop with a simple click') }}.
            </small>
          </b-form-radio>
        </b-form-group>

        <b-form-group class="mt-4">
          <b-button
            type="submit"
          >
            {{ $t("Save") }} <i class="ico ico-arrow-right" />
          </b-button>
        </b-form-group>
      </template>
    </layout-setting>

    <layout-setting
      anchor="fullscreen"
    >
      <template #header>
        {{ $t("Enable full-width display of labels") }}
      </template>

      <template #content>
        <tier-control
          :allow-tariff="['profi', 'premium', 'saas']"
          minimum-tariff="profi"
          position="bottom"
        >
          <b-form-group>
            <b-form-checkbox
              v-model="form.enable_fullscreen"
              :unchecked-value="false"
              :value="true"
              :disabled="form.template_mode === 'standalone'"
              class="mb-2"
            >
              {{ $t("Enable full-width browser label extension") }}

              <small class="d-block text-black-50 pt-1">
                <strong> {{ $t("To use this feature, you must use the generic label styling system") }}. </strong><br>
                {{ $t("Please note: The settings may not work correctly for all templates, if you have problems with the display") }}
                <a
                  href="/contact"
                  target="_blank"
                  class="text-secondary"
                > {{ $t("please write to us") }}</a>.
              </small>
            </b-form-checkbox>
          </b-form-group>

          <b-form-group class="mt-2">
            <b-button type="submit">
              {{ $t("Save") }} <i class="ico ico-arrow-right" />
            </b-button>
          </b-form-group>
        </tier-control>
      </template>
    </layout-setting>
  </b-form>
</template>

<script>
import eshopData from "@/mixin/eshopData";
import cloneDeep from "lodash.clonedeep";
import { useEshopStore } from "@/store/EshopStore";
import TierControl from "@/share/tier-control.vue";
export default {
  name: "SettingTemplate",

  mixins: [eshopData],

  async mounted() {
    const store = useEshopStore();
    store.fetchEshop().then(() => {
      this.form = cloneDeep(store.getEshop());
    });
  },

  data() {
    return {
      form: {},
    };
  },
  components: {'tier-control' : TierControl},

  methods: {
    async actionUpdate() {
      const { form } = this;
      await useEshopStore().updateEshop({
        data: form
      });
    },
  },
};
</script>