<template>
  <div v-if="isShoptet && eshop.region === 'cz'">
    <b-btn
      class="pr-3"
      size="sm"
      variant="secondary"
      :disabled="isLocked"
      @click="openModal()"
    >
      <b-spinner
        v-if="enableRequest"
        small
      />
      <i
        v-else
        class="ico ico-translate"
      />

      {{
        enableRequest
          ? $t("translation.status.running.button")
          : $t("translation.status.complete.button")
      }}
    </b-btn>

    <modal-panel
      ref="modalPanel"
      :title="$t('translation.modal.title')"
      :header="$t('translation.modal.subtitle')"
      help="credit"
      :no-close-on-esc="enableRequest"
      :no-close-on-backdrop="enableRequest"
      :hide-header-close="enableRequest"
      data-cy="modal-translate-container"
      @close-modal="$emit('close-modal')"
    >
      <template #action>
        <b-btn
          :disabled="isLockedTranslate"
          data-cy-="modal-translate-create"
          variant="secondary"
          @click="actionCreate"
        >
          <i class="ico ico-translate" />
          {{ $t("translation.modal.request") }}
        </b-btn>
      </template>
      <template #content>
        <help-box
          author="sara"
          type="multilang"
          classes="mt-4"
        >
          <h2 class="mb-4">
            {{ $t("How to use multilang?") }}
          </h2>
          <p class="fs-16 lh-26">
            {{ $t("The default language settings can be found in the Shoptet administration in the settings tab (basic settings-languages). In case you use multilang just activate it below.") }}
          </p>
        </help-box>
        <tier-control
          :allow-tariff="['premium', 'saas']"
          minimum-tariff="premium"
        >
          <div
            v-if="!enableRequest"
            class="m-auto"
            data-cy="modal-translate-indicator-enable w-100"
          >
            <credit-charge
              :count-lang="eshop.lang_list.length"
              :count-product="edited.product"
              :is-locked="isLockedTranslate"
              type="product"
            />
          </div>
          <div
            v-else
            class="m-auto px-5 text-center"
          >
            <div class="mb-4">
              <b-spinner size="large" />
            </div>
            <h4>
              {{ $t("translation.modal.waitingRequest.waitMinute") }}
            </h4>
          </div>

          <div
            v-if="cron.list.length > 0"
            class="shadow-sm bg-white rounded p-2 mt-4"
          >
            <div class="translate-table font-weight-bold">
              <div>{{ $t("translation.modal.table.start") }}</div>
              <div>{{ $t("translation.modal.table.finish") }}</div>
              <div>{{ $t("translation.modal.table.info") }}</div>
              <div class="text-center">
                {{ $t("translation.modal.table.status") }}
              </div>
            </div>

            <div
              v-for="item in cron.list"
              :key="item.id"
              class="translate-table"
            >
              <div>{{ datetime(item.created_at) }}</div>
              <div>
                <span v-if="item.updated_at">
                  {{ datetime(item.updated_at) }}
                </span>
                <span v-else>
                  {{ $t("export.modal.table.waitForEnd") }}
                  <b-spinner
                    label="Small Spinner"
                    small
                  />
                </span>
              </div>

              <div>
                <small
                  v-if="item.updated_at"
                  class="text-muted d-block"
                >
                  {{ $t("translation.modal.table.translated") }}:
                  {{ $t("sort.product", item.count_product) }}<br>
                  {{ $t("translation.modal.table.charge") }}:
                  {{ $t("sort.credit", item.translate_total) }}
                </small>
              </div>

              <div>
                <b-progress
                  :max="100"
                  :value="item.translate_progress"
                  height="15px"
                  striped="striped"
                  variant="secondary"
                />
                <small class="d-block text-center w-100 text-muted mt-1">
                  {{ $t("sync.modal.table.finish") }}:
                  <strong>{{ item.translate_progress }}% / 100%</strong>
                </small>
              </div>
            </div>
          </div>
        </tier-control>
      </template>
    </modal-panel>
  </div>
</template>

<script>
import TranslateModel from "@/model/TranslateModel.js";
import eshopData from "@/mixin/eshopData";
import CreditCharge from "@/share/credit-charge.vue";
import { useCreditStore } from "@/store/CreditStore";
import TierControl from "@/share/tier-control.vue";
import { datetime } from "@/filter/vue";
import ModalPanel from "@/components/share/modal-panel.vue";
import lockedData from "@/mixin/lockedData";
import HelpBox from "@/share/help-box.vue";
export default {
  name: "ModalTranslate",

  mixins: [eshopData, lockedData],

  async mounted() {
    await this.actionRead();

    await this.checkModal();

    setInterval(async () => {
      await this.checkModal();
    }, 5000);
  },

  data() {
    return {
      running: false,
      modalOpen: false,
      is_silent: true,
      cron: {
        stats: [],
        enable_request: true,
        list: [],
        reaming: {
          wait: 0,
          all: 0,
        },
      },
      count: 0,
    };
  },

  computed: {
    enableRequest: function () {
      const { cron } = this;
      return !cron.enable_request;
    },

    isLockedTranslate() {
      const { summary, edited, eshop, price } = this;
      const { lang_list } = eshop;
      const { translate } = price;
      return summary.reaming < edited.product * lang_list.length * translate;
    },
  },

  components: {
    "help-box": HelpBox,
    "modal-panel": ModalPanel,
    "credit-charge": CreditCharge,
    "tier-control": TierControl,
  },

  methods: {
    datetime,
    async checkModal() {
      if (this.enableRequest) {
        this.openModal();
        await this.actionRead();
      }
    },

    async actionCreate() {
      const { is_silent } = this;

      await TranslateModel.create(
        { is_silent },
        () => {},
        (data) => {
          this.cron = data;
        }
      );
    },

    async actionRead() {
      this.cron = await TranslateModel.findAll();
    },

    async openModalEvent() {
      await useCreditStore().findHistory();
    },

    openModal() {
      this.$refs.modalPanel.isModalOpen = true;
    },
  },
};
</script>
