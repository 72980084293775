<template>
  <b-container
    fluid
    class="mb-2"
  >
    <b-row align-h="bottom">
      <b-col>
        <b-form-group
          :label="$t('Select an e-shop and its products')"
        >
          <tier-control
            :allow-tariff="['profi', 'premium', 'saas']"
            minimum-tariff="profi"
            position="left"
          >
            <b-form-select
              v-model="selectedEshop"
              size="sm"
              :options="eshopOptions"
            />
          </tier-control>
        </b-form-group>
      </b-col>

      <b-col cols="2">
        <b-form-group
          :label="$t('Translate content')"
        >
          <b-form-checkbox
            v-model="translateContent"
            :value="true"
            :unchecked-value="false"
            disabled
          >
            <small class="text-secondary">
              ({{ $t("tariff.head.plan") }})
            </small>
          </b-form-checkbox>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group
          :label="$t('Options for copying')"
        >
          <b-form-select
            v-model="selectedCopyType"
            size="sm"
          >
            <b-form-select-option
              v-for="item in modeListType"
              :key="item.value"
              :value="item.value"
            >
              {{ $t(item.text) }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group>
          <b-btn
            variant="secondary"
            size="md"
            class="w-100 mt-4"
            data-cy="product-confirm-copy"
            @click.prevent="confirmModal(true)"
          >
            {{ $t("Copy labels") }}
            <b-icon
              icon="check-circle"
              class="ml-1"
              size="14"
            />
          </b-btn>
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
  <div class="copy-modal-label">
    <div class="copy-modal-label__preview">
      <h2 class="fs-16 mb-3">
        {{ $t("The label you are copying") }}
      </h2>
      <div
        class="bg-white content-editor__preview-copy shadow-sm rounded-lg mt-4 p-4"
      >
        <div
          v-for="item in widget"
          :key="item.id"
          :class="[item.enable_typography_css ? 'widget-typography' : '']"
        >
          <div v-html="item.html" />
        </div>

        <slot name="placeholder" />
      </div>
    </div>
    <div class="copy-modal-label__product">
      <h2 class="fs-16">
        {{ $t("Which products the label is copied to") }}
      </h2>
      <match-single-product
        type="copy"
        :eshop-id="selectedEshop"
        :hide-submit-button="true"
        :hide-count-column="true"
        :selected-product="product ? product : null"
        @selected="getSelected"
      />
    </div>
  </div>

  <b-modal
    v-model="openConfirmModal"
    size="md"
    hide-footer
    :title="$t('Copy confirmation')"
  >
    <div class="d-flex flex-column text-center">
      <b-icon
        class="ml-1"
        size="59"
      />
      <h2>{{ $t("Copy confirmation") }} </h2>
      <p
        class="mb-4 text-sm p-3 fs-21 lh-30"
      >
        {{ $t("This move is irreversible! If you copy the labels, it is not possible to restore them to their original state.")
        }}
      </p>
    </div>

    <b-btn-group class="shadow-sm d-flex justify-content-center">
      <b-btn
        variant="danger"
        size="md"
        class="w-50"
        @click.prevent="confirmModal(false)"
      >
        {{ $t("Cancel") }}
        <b-icon
          icon="x-circle"
          class="ml-1"
          size="14"
        />
      </b-btn>
      <b-btn
        variant="secondary"
        size="md"
        class="w-50"
        data-cy="product-confirm-copy"
        @click.prevent="submitCopy"
      >
        {{ $t("Copy labels") }}
        <b-icon
          icon="check-circle"
          class="ml-1"
          size="14"
        />
      </b-btn>
    </b-btn-group>
  </b-modal>
</template>

<script setup>
import { ref, reactive, computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import matchSingleProduct from "@/components/share/match-single-product.vue";
import axios from "axios";
import options from "@/config/share.json";
import ProductModel from "@/model/ProductModel";
import { showMessage } from "@/plugins/flashMessage";
import { useEshopData } from "@/composables/useEshopData";
import langList from "@/js/config/lang";
import TierControl from "@/share/tier-control.vue";

const { t } = useI18n();
const { eshop } = useEshopData();

const props = defineProps({
  product: {
    type: Object,
    required: true
  }
});

const emit = defineEmits(['action-update', 'close-modal']);

const widget = ref(null);
const isLoaded = ref(false);
const showFakeDoor = ref(false);
const selected = ref([]);
const eshopOptions = ref([]);
const selectedLanguage = ref("default");
const translateWithDeepL = ref(false);
const openConfirmModal = ref(false);
const selectedEshop = ref(null);
const previewModal = ref(false);
const selectedCopyType = ref(false);
const translateContent = ref(false);

const modeListType = [
  {
    value: false,
    text: "Insert widgets behind the current ones on the e-shop",
    icon: "ico-copy-down"
  },
  {
    value: true,
    text: "Replace the widgets with the current ones found on the e-shop",
    icon: "ico-copy-delete"
  }
];

const langListComputed = computed(() => {
  const langListArray = eshop.value.lang_list;
  const langCurrent = eshop.value.lang;

  const push = langList.filter(flag => langListArray.includes(flag)).map(flag => {
    return {
      value: flag,
      event: flag
    };
  });

  return [{
    value: langCurrent,
    event: "default"
  }, ...push];
});

async function actionRead() {
  const { api } = options.product;
  const { widget: widgetApi } = api;

  await axios.get(widgetApi.replace("[id]", props.product.id)).then(({ data }) => {
    widget.value = data;
    isLoaded.value = true;
    emit("action-update", data);
  });
}

function prepareEshopOptions() {
  eshopOptions.value = eshop.value.available_eshop.map(eshopItem => {
    return {
      text: eshopItem.humanUrl,
      value: eshopItem.id
    };
  });

  selectedEshop.value = eshop.value.id;
}

function changeCopyLanguage(lang = "default") {
  selectedLanguage.value = lang;
}

function getSelected(item) {
  selected.value = item;
}

function confirmModal(value) {
  openConfirmModal.value = value;
}

function openPreviewModal(value) {
  previewModal.value = value;
}

async function submitCopy() {
  await ProductModel.createCopy(
    {
      productId: props.product.id,
      checked: selected.value,
      deleteHistoryWidget: selectedCopyType.value,
      translateContent: translateContent.value,
      eshopId: selectedEshop.value
    },
    () => {},
    (data) => {
      selected.value = [];
      emit("close-modal", props.product);
      showMessage("copy.successCopyFinish");
    }
  );
}

onMounted(async () => {
  await actionRead();
  prepareEshopOptions();
});
</script>