<template>
  <div>
    <layout-grid>
      <template #title>
        {{ $t("categories.header") }}
      </template>

      <template #welcome>
        <help-box
          author="sara"
          type="use"
        >
          <h2 class="mb-4">
            {{ $t("How to use the Pobo Page Builder editor?") }}
          </h2>
          <p class="fs-16 lh-30 font-weight-light">
            {{ $t("Introducing Pobo Page Builder: Drag & drop editor for e-shops.") }}
            {{ $t("Learn how to create attractive product descriptions with advanced formatting, no HTML/CSS needed.") }}
            {{ $t("Discover how to enhance the appeal and conversion rate of your e-shop.") }}
          </p>
        </help-box>
      </template>


      <template #pagination>
        <b-pagination
          v-model="params.offset"
          :per-page="params.limit"
          :total-rows="params.total"
          pills
          size="sm"
          @change="handleUsePaginator"
        />
      </template>

      <template #filter>
        <grid-filter
          type="category"
          :current="params.filter"
          :list="listFilter"
          @change="filterProduct"
        />
      </template>

      <template #content>
        <div class="category-table--head">
          <div />
          <div>
            {{ $t("categories.widget") }}
          </div>

          <div>{{ $t("categories.listCategory") }}</div>
          <div>
            <grid-search
              @input="updateQuery"
              @submit="getData"
            />
          </div>
        </div>

        <template v-if="isLoaded">
          <template v-if="categories.length > 0">
            <div
              v-for="item in categories"
              :key="item.id"
            >
              <grid-row
                :id="item.id"
                :content="item.content"
                :image="item.image"
                :is-deleted="item.is_delete"
                :is-favourite="item.is_favourite"
                :is-visible="item.is_visible"
                :item="item"
                :name="item.name"
                :url="item.url"
                grid-class="category-table"
                type="category"
                @open-editor="openEditor"
              />
            </div>
          </template>
          <template v-else>
            <div class="text-center">
              <h3 class="py-4 font-weight-light">
                {{ $t("categories.notFound") }}
              </h3>

              <b-btn @click="resetFilter">
                {{ $t("categories.resetFilter") }}
                <i class="ico ico-arrow-right" />
              </b-btn>
            </div>
          </template>
        </template>
        <template v-else>
          <div
            v-for="n in 10"
            :key="n"
            class="category-table"
          >
            <div
              v-for="m in 5"
              :key="m"
            >
              <b-skeleton-img
                height="45px"
                no-aspect
              />
            </div>
          </div>
        </template>
      </template>
    </layout-grid>

    <browser-panel
      :primary-modal-open="primaryModalOpen"
      type="category"
      @set-active-panel="setActivePanel"
    />

    <Transition name="slide-up">
      <editor-browser
        v-show="primaryModalOpen"
        :tabs="tabs.getGlobalTabs('category')"
        :active-id="tabs.getGlobalActiveIndex('category')"
        :options="options.category"
        :is-open="primaryModalOpen"
        type="category"
        :window-browser-tab="windowBrowserTab"
        @open-tool-modal="openToolModal"
        @action-close="closeEditor"
        @change-tab="changeTab"
        @action-minimize="minimizeEditor"
      />
    </Transition>

    <b-modal
      v-model="secondaryModalOpen"
      :size="getSize"
      :title="getTitle"
      hide-footer
    >
      <div
        v-if="tabOpen.includes('legacy')"
        class="bg-white p-4 pt-1 editor-bg"
      >
        <div
          v-if="category.old_description"
          v-html="category.old_description"
        />
        <div v-else>
          <p class="text-center pt-3">
            {{ $t('This category does not have an original label') }}
          </p>
        </div>
      </div>

      <div v-if="tabOpen.includes('export')">
        <modal-extend-export
          type="category"
          :content="category"
          @close="closeSecondaryModal"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import { category } from "@/config/filter.json";
import GridSearch from "@/share/grid-search.vue";
import GridFilter from "@/share/grid-filter.vue";
import options from "@/config/share.json";
import CategoryModel from "@/model/CategoryModel.js";
import EshopModel from "@/model/EshopModel.js";
import GridRow from "@/share/grid-row.vue";
import { useStatsInfoStore } from "@/store/StatsInfoStore";
import HelpBox from "@/share/help-box.vue";
import EditorBrowser from "@/share/editor-browser.vue";
import ModalExtendExport from "@/share/modal-extend-export.vue";
import {useTabsStore} from "@/store/TabsStore";
import BrowserPanel from "@/share/browser-panel.vue";
export default {
  name: "CategoryList",

  provide() {
    return {
      pageType: "category"
    }
  },

  async mounted() {
    await this.getData();
    this.eshop = await EshopModel.findMe();
  },

  data() {
    return {
      isLoaded: false,
      primaryModalOpen: false,
      tabOpen: [],
      categories: [],
      listFilter: category,
      options: options,
      secondaryModalOpen: false,
      windowBrowserTab: [
        {
          event: "legacy",
          type: "main", // main or tool,
          translate: "View original content"
        },
        {
          event: "export",
          type: "main", // main or tool,
          translate: "Export content"
        }
      ],
      eshop: {},
      params: {
        offset: 1,
        limit: 20,
        total: 0,
        query: "",
        filter: 1,
      },
    };
  },

  computed:{
    tabs(){
      return useTabsStore();
    },

    getSize() {
      const { tabOpen } = this;

      if (tabOpen.includes("legacy")) {
        return "lg";
      } else if (tabOpen.includes("export")) {
        return "md";
      }
      return "editor-lg";
    },

    getTitle() {
      const { tabOpen } = this;

      if (tabOpen.includes("legacy")) {
        return this.$t("product.panel.oldDescription");
      } else if (tabOpen.includes("export")) {
        return this.$t("Export to e-eshop");
      }
      return `Warning: getTitle() not set`;
    },

    category(){
      return this.tabs.getTabData('category');
    }
  },

  components: {
    "modal-extend-export": ModalExtendExport,
    "editor-browser": EditorBrowser,
    HelpBox,
    "grid-search": GridSearch,
    "grid-filter": GridFilter,
    "grid-row": GridRow,
    "browser-panel": BrowserPanel,
  },

  metaInfo() {
    return { title: this.$t("meta.category") };
  },

  methods: {
    async getData() {
      const { params } = this;

      const result = await CategoryModel.findAllCategory(
        params,
        () => {
          this.isLoaded = false;
        },
        () => {
          this.isLoaded = true;
        }
      );

      const { category, total } = result;
      this.categories = category;
      this.params.total = total;
    },

    setActivePanel(entityId) {
      this.primaryModalOpen = (this.tabs.globalActiveIndex !== entityId) || !this.primaryModalOpen;

      if(!this.primaryModalOpen) {
        document.body.classList.remove("modal-open");
      }
      this.tabs.setActiveIndex('category', entityId);
    },

    changeTab(index){
      this.tabs.setActiveIndex('category', index);
    },

    async minimizeEditor() {
      this.primaryModalOpen = false;
      document.body.classList.remove("modal-open");
      await useStatsInfoStore().fetch()
    },

    async closeEditor(entity) {
      const { id } = entity;

      await this.tabs.closeEditor('category', id);

      this.secondaryModalOpen = false;
      this.primaryModalOpen = false;

      document.body.classList.remove("modal-open");

      await this.getData();
      await useStatsInfoStore().fetch();
    },

    async getCategory(category) {
      await this.tabs.fetchTab('category',category);
      this.primaryModalOpen = true;
    },

    async resetFilter() {
      this.params.filter = 1;
      this.params.query = "";
      await this.getData();
    },

    openToolModal(name) {
      this.tabOpen = [name];
      this.secondaryModalOpen = true
    },

    closeSecondaryModal(){
      this.secondaryModalOpen = false;
    },

    async openEditor(category) {
      await this.getCategory(category);
      document.body.classList.add("modal-open");
    },

    updateQuery(query) {
      this.params.query = query;
    },

    async handleUsePaginator(offset) {
      this.params.offset = offset;
      await this.getData();
    },

    async filterProduct(filter) {
      this.params.filter = filter;
      this.params.offset = 1;
      await this.getData();
    },

  },
};
</script>
