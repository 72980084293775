<template>
  <div class="approve-panel__container rounded-lg shadow-sm py-3 px-4">
    <div class="d-flex align-items-center">
      <div class="approve-panel__img">
        <b-img
          :src="product.image_preview"
          :alt="product.name"
          width="100"
          rounded
          class="approve-panel__image"
          height="100" />
      </div>
      <div class="approve-panel__content">
        <h5 class="m-0 p-0">{{ product.name }}</h5>
        <span class="d-block mt-2">
          {{ t('The product description has been generated by AI and needs to be reviewed and approved') }}.
        </span>

        <b-btn @click="emit('approve-product', product)" size="sm" class="mt-2" variant="secondary">
          {{ t('Save content and approve') }}
          <b-icon icon="chevron-double-right" class="ml-1" size="12" />
        </b-btn>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, defineEmits } from "vue";
import { Product } from "@/interface/GenerativeInterface";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

const emit = defineEmits<{
  (e: 'approve-product', product: Product): void;
}>();

const props = defineProps<{
  product: Product;
  products: Product[];
}>();

</script>