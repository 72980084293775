<template>
  <div
    :class="gridClass"
  >
    <div
      v-if="type !== 'product'"
      class="category-table__status"
    >
      <div
        :class="[
          isVisible ? 'visible' : 'hidden',
          'category-table__status-inner',
        ]"
      >
        {{ isVisible ? $t("grid.visible") : $t("grid.hidden") }}
      </div>
    </div>
    <div>
      <grid-item-quality :content="content" />
    </div>

    <div
      v-if="read"
      class="text-right"
    >
      <span class="d-block fs-15">
        {{ count(read.end) }}
        <i class="ico ico-filter-basket" />
      </span>

      <span class="d-block fs-15">
        {{ count(read.start) }}
        <i class="ico ico-filter-eye" />
      </span>
    </div>

    <div>
      <div class="product-list-image">
        <img
          v-if="image"
          :alt="name"
          :src="image"
        >
        <img
          v-else
          src="https://pobo-cdn.b-cdn.net/static/no-image-icon.png"
        >
      </div>
    </div>

    <div class="border-left-0">
      <div
        v-if="type === 'product'"
        :class="[
          isVisible ? 'visible' : 'hidden',
          'product-table__status-inner',
        ]"
      >
        <i :class="{'ico': true, 'ico-eye': isVisible, 'ico-x-eye': !isVisible}" />
      </div>
      <template v-if="isVisible">
        <link-cutter
          :url="url"
          :name="name"
          :max-width="500"
        />
      </template>
      <template v-else>
        <span class="font-weight-bolder">{{ name }}</span>
      </template>

      <div class="d-flex align-items-center">
        <template v-if="category.length > 0">
          <small class="text-muted d-block mt-1 mr-1">
            <i class="ico ico-folder mr-1" />
            <template v-for="(cat, index) in category.slice(0, 3)">
              {{ cat.name }}<template v-if="index + 1 < category.length && index < 2">, </template>
            </template>
            <template v-if="category.length > 3">
              + {{ $t('others') }} {{ $t("inflection.enum.category", category.length - 3) }}
            </template>
          </small>
        </template>
        <template v-if="status === 'review'">
          <small class="d-block mt-1 mr-1 text-info">
            <i class="ico ico-review ml-1" />
            {{ $t('Awaiting approval') }}
          </small>
        </template>

        <small
          v-if="design && design.id"
          class="text-muted mt-1"
        >
          <i class="ico ico-design ml-2 mr-1" />
          {{ design.name }}
        </small>
      </div>
      <span
        v-if="isDeleted"
        class="text-danger"
        style="font-size: 12px"
      >
        ({{ $t("grid.isDeleted") }})
      </span>
    </div>

    <div
      :class="[
        (type === 'product' ? 'product' : 'category') + (type === 'product' && !isShopify ? '-table__action-shoptet' : '-table__action-shopify'),
        'text-right',
      ]"
    >
      <layout-single-export
        :item="item"
        :type="type"
        trigger="grid"
      />


      <grid-item-favourite
        :id="id"
        :content="type"
        :state="isFavourite"
      />

      <a
        v-if="type === 'product'"
        v-tooltip.top-center="$t('tooltip.generateContent')"
        :class="['ico', 'ico-grid-ai', { 'link-disabled': isLocked || runningGenerator }]"
        @click.prevent="$emit('open-generator', item)"
      />

      <div>
        <layout-edit-button
          data-cy="product-list-edit"
          :item="item"
          block
          :ignore-generator-state="false"
          @open-editor="$emit('open-editor', item)"
          @open-generator="$emit('open-generator', item)"
        />
      </div>
    </div>
    <div
      v-if="type === 'product'"
      :class="['select-grid-item', { 'link-disabled': isLocked || runningGenerator}]"
    >
      <slot name="checkbox" />
    </div>
  </div>
</template>

<script>
import GridItemFavourite from "@/share/grid-item-favourite.vue";
import GridItemQuality from "@/share/grid-item-quality.vue";
import { count } from "@/filter/vue";
import generatorData from "@/mixin/generatorData";
import LayoutEditButton from "@/share/layout-edit-button.vue";
import eshopData from "@/mixin/eshopData";
import LinkCutter from "@/share/link-cutter.vue";
import lockedData from "@/mixin/lockedData";

export default {
  name: "GridRow",

  mixins: [generatorData, eshopData, lockedData],

  props: {
    item: {
      type: Object,
      required: true,
    },

    isVisible: {
      type: Boolean,
      required: true,
    },

    content: {
      type: Object,
      required: true,
    },

    read: {
      type: Object,
      required: false,
      default: () => {},
    },

    image: {
      type: String,
      required: false,
      default: () => null,
    },

    url: {
      type: String,
      required: true,
    },

    status: {
      type: String,
      required: false,
    },

    name: {
      type: String,
      required: true,
    },

    category: {
      type: Array,
      required: false,
      default: () => [],
    },

    isDeleted: {
      type: Boolean,
      required: true,
    },
    isFavourite: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    gridClass: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },

    design: {
      type: Object,
      default: null,
      validator: (p) => {
        return ['null', 'object'].indexOf(typeof p) !== -1 || p === null;
      },
    }
  },

  computed:{
    runningGenerator() {
      const { isLockedItemId, isDeleted } = this;
      return isLockedItemId || isDeleted;
    },
  },

  components: {
    "link-cutter": LinkCutter,
    "layout-edit-button": LayoutEditButton,
    "grid-item-quality": GridItemQuality,
    "grid-item-favourite": GridItemFavourite,
  },
  methods: {
    count,
  }
};
</script>

<style scoped>
  .link-disabled{
    position: relative;
    pointer-events: none;
    color: gray;
  }

  .link-disabled::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    z-index: 1;
  }
</style>
