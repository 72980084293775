<template>
  <b-form
    class="p-0"
    novalidate
    @submit.prevent="actionCreate"
  >
    <help-box
      author="sara"
      type="ai"
      classes="mt-4"
    >
      <h2 class="mb-4">
        {{ $t("How to use AI to generate widgets") }}
      </h2>
      <p class="fs-16 lh-26">
        {{ $t("Pobo Page Builder uses ChatGPT's capabilities to create complete widgets with headings, paragraphs and images.")
        }}
        {{ $t("This feature facilitates fast and efficient content creation, increases productivity and allows users to easily generate attractive and engaging web pages.")
        }}
      </p>
    </help-box>

    <div
      v-if="isLocked"
      class="bg-white rounded-lg shadow-sm p-5 mb-4"
      style="
                background: url('https://pobo-cdn.b-cdn.net/static/michal-stikar-team-2.png?width=200&height=200&auto_optimize=high')
                    right bottom / contain no-repeat;
                border-image: initial;
            "
    >
      <h4 class="mt-0 mb-3">
        Bohužel nemáte dostatek kreditů na automatické vytvoření popisku
      </h4>

      <p class="mb-0 fs-16 lh-28 d-block">
        Přejděte prosím do sekce „
        <router-link
          :to="{ name: 'credit' }"
          style="text-decoration: underline"
          class="text-secondary"
        >
          {{ $t("translation.modal.empty.button") }}
        </router-link>
        ", {{ $t("translation.modal.empty.postfix") }}<br>
        Pokud máte dotaz, ozvěte se nám na
        <a
          href="mailto:podpora@pobo.cz"
          class="text-secondary"
          style="text-decoration: underline"
        >podpora@pobo.cz</a>, rádi vám pomůžeme.
      </p>

      <small class="mb-0 mt-2 text-muted d-block">
        (Cena za vytvoření popisku pomocí ChatGPT je 5 kreditů).
      </small>
    </div>

    <b-row>
      <b-col cols="5">
        <template v-if="isLoaded">
          <span class="d-block fs-14 lh-20 mb-3">
          {{
              $t(
                "Select the widget layout, review the information and submit the form.  Pobo Page Builder will download the current product information into the form and if it is not available, you need to fill it in manually."
              )
            }}
          </span>

          <template v-if="widget.length > 1">
            <widget-ai-preview :layout="layout" :widget="widget" @select-layout="selectLayout" />
          </template>
        </template>
        <template v-else>
          <b-skeleton-img
            height="400px"
            no-aspect
          />
        </template>
      </b-col>
      <b-col cols="7">
        <template v-if="isLoaded">
          <b-form-group
            id="name"
            :label="$t('product.name')"
            size="sm"
            :disabled="isLocked"
          >
            <b-form-input
              id="name"
              v-model="formData.name"
              :class="errors.name ? 'is-invalid' : ''"
              required
              size="sm"
              type="text"
            />

            <small
              v-for="(error, index) in errors.name"
              :key="index"
              class="text-danger d-block"
              v-html="$t(error)"
            />
          </b-form-group>

          <b-row>
            <b-col cols="6">
              <b-form-group
                id="content"
                :label="$t('product.shortDescription')"
                size="sm"
                :disabled="isLocked"
              >
                <b-form-textarea
                  id="content"
                  v-model="formData.short_description"
                  :class="
                errors.short_description ? 'is-invalid' : ''
              "
                  required
                  rows="3"
                  size="sm"
                />

                <small
                  v-for="(error, index) in errors.short_description"
                  :key="index"
                  class="text-danger d-block"
                  v-html="$t(error)"
                />
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group
                id="parameters"
                :label="$t('product.parameters')"
                size="sm"
                :disabled="isLocked"
              >
                <b-form-textarea
                  id="parameters"
                  v-model="formData.parameters"
                  :class="errors.parameters ? 'is-invalid' : ''"
                  required
                  rows="3"
                  size="sm"
                />

                <small
                  v-for="(error, index) in errors.parameters"
                  :key="index"
                  class="text-danger d-block"
                  v-html="$t(error)"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <div v-if="additionalContent.length > 0" class="mb-3 py-2 px-3 bg-white border-light rounded-lg shadow-sm">
            <b-row align-v="center">
              <b-col cols="9" class="fs-14">
                <i class="ico ico-warning mr-2"></i>
                {{ $t("Z DataDepo bylo staženo několik zdrojů") }}:
                <strong>{{ additionalContent.length }}x</strong>
              </b-col>
              <b-col cols="3">
                <b-btn variant="secondary" block size="sm" @click="toggleAdditionalContentModal">
                  {{ $t("Zobrazit zdroje") }}
                </b-btn>
              </b-col>
            </b-row>
          </div>

          <b-form-group
            :description="$t('translation.optional.widget.help')"
          >
            <b-form-checkbox
              v-model="formData.remove_exist_widget"
              :disabled="isLocked"
              class="mb-3"
            >
              {{ $t("translation.optional.widget.info") }}
            </b-form-checkbox>
          </b-form-group>

          <b-form-group class="mt-2">
            <b-btn
              block
              variant="secondary"
              :disabled="isLocked"
              type="submit"
            >
              {{ $t("copyai.idle") }}
            </b-btn>
          </b-form-group>
        </template>
        <template v-else>
          <b-skeleton-img
            height="400px"
            no-aspect
          />
        </template>
      </b-col>
    </b-row>
  </b-form>

  <b-modal
    v-model="isOpenAdditionalContentModal"
    title="Zdroje z DataDepo"
    size="md"
    hide-footer
  >
    <template v-for="item in additionalContent">
      <h6>
        {{ item.name }}
      </h6>

      <div v-for="(content, index) in item.old_description_from_suppliers" :key="index" class="mb-2">
        <b-form-group label="HTML">
          <b-textarea
            title="HTML"
            :value="content"
            rows="3"
            readonly
          />
        </b-form-group>
      </div>
    </template>
  </b-modal>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, defineEmits } from "vue";
import ProductModel from "@/model/ProductModel.js";
import GeneratorModel from "@/model/GeneratorModel.js";
import { useGeneratorStore } from "@/store/GeneratorStore.js";
import { useCreditStore } from "@/store/CreditStore.js";
import { useEshopData } from "@/composables/useEshopData.js";
import { showMessage } from "@/plugins/flashMessage";
import HelpBox from "@/share/help-box.vue";
import GenerativeModel from "@/model/GenerativeModel";
import {
  Widget,
  FormData,
  Product,
  ValidationErrors,
  ModalCopyAIProps,
  ModalCopyAIEmits, CustomProductDescription
} from "@/interface/GenerativeInterface";
import WidgetAiPreview from "@/components/product/widget-ai-preview.vue";


const props = defineProps<ModalCopyAIProps>();
const emit = defineEmits<ModalCopyAIEmits>();

const isLoaded = ref<Boolean>(false);
const layout = ref<Number>(0);
const errors = ref<ValidationErrors>({});
const widget = ref<Widget[]>([]);

const formData = ref<FormData>({
  name: null,
  short_description: null,
  remove_exist_widget: true,
  parameters: [],
  additionalContent: []
});

const product = ref<Product>({
  name: "",
  short_description: null,
  parameters: [],
  guid: ""
});

const selectLayout = (id: number): void => {
  layout.value = id;
};

const additionalContent = ref<CustomProductDescription>([]);
const productGuid = ref<string>([]);
const isOpenAdditionalContentModal = ref<boolean>(false);
const creditStore = useCreditStore();
const generatorStore = useGeneratorStore();
const { allowGenerateContent, isShoptet, eshop, eshopId } = useEshopData();

const isLocked = computed(() => {
  return !allowGenerateContent.value && isShoptet.value;
});

const toggleAdditionalContentModal = () => {
  isOpenAdditionalContentModal.value = !isOpenAdditionalContentModal.value;
};

const actionCreate = async () => {
  const { productId } = props;
  const payload = {
    ...formData.value,
    layout: layout.value,
    product_id: productId
  };


  // Todo nacist ID z API pres nejaky endpoint
  if (eshopId.value === 1503) {
    let additionalContentString = "";

    if (additionalContent.value.length > 0) {
      additionalContent.value.forEach(item => {
        const descriptions = item.old_description_from_suppliers.map((content, index) => {
          return `Původní popisek v HTML od dodavatele č.${index + 1}: ${content}`;
        });
        additionalContentString += descriptions.join("\n\n");
      });
    }

    if (additionalContentString.length > 0) {
      payload.short_description = `${payload.short_description ? payload.short_description : ''}\n\n${additionalContentString}`;
    }
  }

  delete payload.additionalContent;

  await GeneratorModel.create(
    payload,
    () => {
    },
    (data) => {
      if (data.response?.status === 422) {
        errors.value = data.response.data.errors;
      } else {
        showMessage("flash.generator.product");
        generatorStore.check();

        emit("create", {
          id: productId
        });
      }
    }
  );
};

onMounted(async () => {
  const { productId } = props;

  await creditStore.findHistory();

  await ProductModel.findCurrent(
    productId,
    () => {
    }, (data) => {
      const { product: productData, widget: widgetData } = data;
      const { name, short_description, parameters } = productData;

      product.value = productData;
      productGuid.value.push(data.product.guid);

      formData.value.name = name.trim();
      formData.value.short_description = short_description
        ? short_description
          .replace(/<[^>]+>|&nbsp;/g, " ")
          .replace(/\s+/g, " ")
          .trim()
        : null;

      widget.value = widgetData;
      layout.value = widgetData[0].id;

      const remappingParameter = [];

      if (parameters) {
        parameters.forEach(({ name, value }) => {
          const formattedName = (
            name.charAt(0).toUpperCase() + name.slice(1)
          )
            .trim()
            .replace(/:$/, "");
          const formattedValue = value
            .replace(/\s+/g, " ")
            .trim();
          remappingParameter.push(
            `${formattedName}: ${formattedValue}`
          );
        });
      }

      formData.value.parameters = remappingParameter
        ? remappingParameter.join(", ")
        : "";
    }
  );

  // Todo nacist ID z API pres nejaky endpoint
  if (eshopId.value === 1503) {
    await GenerativeModel.getCustomProductList(
      productGuid.value,
      () => {
      }, (data) => {
        additionalContent.value = data;
      });
  }

  isLoaded.value = true;
});
</script>