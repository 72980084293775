<template>
  <div
    v-if="categories.length > 0"
    class="filter-product-list w-100 p-3 rounded-lg"
  >
    <div
      v-for="category in categories"
      :key="category.id"
      class="filter-product-list__item mr-1"
    >
      <div class="filter-product-list__item-name">
        {{ category.name }}
      </div>
      <div
        class="filter-product-list__item-delete pointer-event"
        @click="$emit('remove', category.id)"
      >
        <b-icon
          icon="x"
          class="fs-16 font-weight-bold"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    categories: {
      type: Array,
      default: () => []
    }
  }
};
</script>