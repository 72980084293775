<template>
  <div class="bg-light p-4">
    <h3 class="pb-2">
      Demonstrativní popisky
    </h3>

    <button
      class="btn btn-secondary"
      @click="isCreateModalVisible = true"
    >
      Připravit a odeslat demonstrativní popisek
    </button>
    <hr>

    <table
      v-if="data"
      class="table"
    >
      <thead>
        <tr class="font-weight-bolder">
          <td
            class="border-0"
            style="width: 50px"
          >
            ID
          </td>
          <td
            class="border-0"
            style="width: 150px"
          >
            Vytvořeno
          </td>
          <td
            class="border-0"
            style="width: 150px"
          >
            Přečtěno
          </td>
          <td
            class="border-0"
            style="width: 150px"
          >
            Uživatel
          </td>
          <td class="border-0">
            Produkt
          </td>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in data"
          :key="item.id"
        >
          <td class="align-middle">
            {{ item.id }}
          </td>
          <td class="align-middle">
            {{ when(item.created_at) }}
          </td>
          <td class="align-middle">
            <span
              v-if="item.visible"
              class="badge badge-success"
            >
              Přečteno
            </span>
            <span
              v-else
              class="badge badge-danger"
            >
              Nepřečteno
            </span>
          </td>
          <td class="align-middle">
            {{ item.product.user }}
          </td>
          <td class="align-middle">
            <a
              :href="item.product.url"
              class="border-bottom"
              target="_blank"
            >
              {{ item.product.name }}
            </a>
          </td>
        </tr>
      </tbody>
    </table>

    <div
      v-if="!data"
      class="text-center h2 font-weight-light"
    >
      Bohužel jsme nenašli žádné demonstrativní popisky k produktům
    </div>

    <b-modal
      v-model="isCreateModalVisible"
      hide-footer
      no-fade
      size="md"
      title="Odeslání demonstrativního popisku e-shopu"
    >
      <create-demonstration @handle-close="handleUpdate" />
    </b-modal>
  </div>
</template>

<script>
import {defineComponent, ref, onMounted} from "vue";
import {date, when} from "@/filter/vue";
import CreateDemonstration from "@/components/dashboard/part/create-demonstration.vue";
import AdminModel from "@/model/AdminModel.js";

export default defineComponent({
  name: "Demonstrace",
  components: {
    CreateDemonstration
  },
  setup() {
    const data = ref(null);
    const isCreateModalVisible = ref(false);

    function handleUpdate(newData) {
      data.value = newData;
      isCreateModalVisible.value = false;
    }

    async function fetchData() {
      await AdminModel.findDemonstration(
        () => {
        },
        (res) => {
          data.value = res;
        }
      );
    }

    onMounted(() => {
      fetchData();
    });

    return {
      data,
      isCreateModalVisible,
      when,
      date,
      handleUpdate,
      fetchData
    };
  }
});
</script>
