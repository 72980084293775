<template>
  <div class="grid-account">
    <div class="grid-account__menu">
      <router-link
        v-for="(item, index) in filterNavigation"
        :key="index"
        :to="{ name: item.name }"
        class="grid-account__menu-link"
      >
        <b-icon
          :icon="item.icon"
          class="mr-3"
          size="16"
        />
        <span class="position-relative">{{ $t(item.lang.header) }}
          <b-badge
            v-if="item.minimumTariff"
            class="grid-account__menu-badge font-weight-normal fs-10"
            variant="secondary"
          >
            {{ item.minimumTariff.toUpperCase() }}
          </b-badge>
        </span>
      </router-link>
    </div>
    <div class="grid-account__content">
      <router-view />
    </div>

    <b-modal
      v-model="isCustomModalOpen"
      :title="$t('meta.asset')"
      size="xl"
      hide-footer
    >
      <modal-design-custom />
    </b-modal>
  </div>
</template>

<script>
import navigation from "@/components/design/navigation.json";
import eshopData from "@/mixin/eshopData";
import ModalDesignCustom from "@/components/design/custom/modal-design-custom.vue";
export default {
  name: "DesignApp",

  mixins: [eshopData],

  data() {
    return {
      navigation,
      isCustomModalOpen: false,
    };
  },

  computed: {
    filterNavigation() {
      const { navigation, eshop } = this;
      const { platform, role } = eshop;

      return navigation.filter(
        ({ visible, allowPlatform }) =>
          (visible === true || role.includes("admin")) &&
          allowPlatform.includes(platform)
      );
    },
  },

  components:{
    "modal-design-custom": ModalDesignCustom,
  },

  methods: {
    setActiveClass(component) {
      const { $route } = this;
      const { name } = $route;
      return [name === component ? "btn-secondary" : "btn-light", "btn"];
    },
  },
};
</script>