<template>
  <div>
    <help-box
      author="sara"
      type="multilang"
      classes="mt-4"
    >
      <h2 class="mb-4">
        {{ $t("How to use multilang?") }}
      </h2>
      <p class="fs-16 lh-26">
        {{ $t("Before we start working with foreign languages, we need to make sure we have the Foreign Languages add-on installed from Shoptet. If you have the Enterprise plan, foreign languages are already included in this plan and we do not need to install it.") }}
        {{ $t("The default language settings can be found in the Shoptet administration in the settings tab (basic settings-languages). In case you use multilang just activate it below.") }}
      </p>
    </help-box>
    <b-form @submit.prevent="actionUpdate">
      <h5 class="mb-3">
        {{ $t("invoice.multi-lang.turn-on") }}
      </h5>

      <div class="bg-white p-4 mb-5 shadow-sm rounded">
        <p class="text-sm mb-4">
          {{ $t("Po zapnutí volby se v editoru zobrazí vlajky, na které lze kliknout a přepnout obsah do jiného jazyka.") }}
          {{ $t("Pokud není obsah v jiném jazyce přeložený, zobrazí se v editoru obsah výchozího jazyka.") }}
        </p>

        <tier-control
          :allow-tariff="['profi', 'premium', 'saas']"
          minimum-tariff="profi"
          position="left"
        >
          <b-form-group>
            <b-form-checkbox
              id="allow_lang"
              v-model="form.allow_lang"
              :unchecked-value="false"
              :value="true"
              name="allow_lang"
            >
              {{ $t("invoice.multi-lang.confirm") }}
            </b-form-checkbox>
            <b-btn
              class="mt-3"
              type="submit"
              variant="secondary"
            >
              {{ $t("lang.save") }} <i class="ico ico-arrow-right" />
            </b-btn>
          </b-form-group>
        </tier-control>
        <div class="mt-2">
          <editor-skeleton>
            <template #flag>
              <div v-if="form.allow_lang">
                <small class="text-muted fs-12 d-block mb-2">Select language</small>
                <div
                  class="d-flex pb-2"
                >
                  <div
                    v-for="(flag, index) in lang"
                    :key="index"
                  >
                    <span
                      class="content-editor__languages-skeleton"
                    >
                      <img
                        :src="`https://pobo-cdn.b-cdn.net/flag/${flag}.svg`"
                        class="w-100"
                      >
                    </span>
                  </div>
                </div>
              </div>
            </template>
          </editor-skeleton>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import lang from "@/js/config/lang.js";
import TierControl from "@/share/tier-control.vue";
import EditorSkeleton from "@/components/setting/part/editor-skeleton.vue";
import eshopData from "@/mixin/eshopData";
import cloneDeep from "lodash.clonedeep";
import { useEshopStore } from "@/store/EshopStore";
import HelpBox from "@/share/help-box.vue";

export default {
  name: "MultiLang",

  mixins: [eshopData],

  async mounted() {
    const { eshop } = this;
    this.form = cloneDeep(eshop);
  },

  data() {
    return {
      lang,
      form: {},
    };
  },

  components: {
    "help-box": HelpBox,
    "editor-skeleton": EditorSkeleton,
    "tier-control": TierControl,
  },

  methods: {
    async actionUpdate() {
      const { form } = this;

      form.export_mode = 2;

      await useEshopStore().updateEshop({
        data: form
      });
    },
  },
};
</script>
