<template>
  <b-form
    class="px-2"
    @submit.prevent="submitForm"
  >
    <b-row>
      <b-col cols="12">
        <b-form-group
          id="name"
          label="Design name"
        >
          <b-form-input
            id="name"
            v-model="formData.name"
            type="text"
            required
          />
        </b-form-group>

        <b-form-group
          id="remote_name"
          label="Remote template design name"
        >
          <b-form-input
            id="remote_name"
            v-model="formData.remote_name"
            type="text"
          />
        </b-form-group>

        <b-form-group
          id="info"
          label="Design info"
        >
          <b-form-textarea
            id="info"
            v-model="formData.info"
            type="text"
          />
        </b-form-group>

        <b-form-group
          id="image_preview"
          label="Design image preview"
        >
          <b-form-input
            id="image_preview"
            v-model="formData.image_preview"
            type="text"
          />
        </b-form-group>

        <b-form-group
          label="Design type (custom or $$$)"
        >
          <b-form-radio-group
            v-model="formData.type"
            :options="[
              { text: 'Public (free)', value: 'public' },
              { text: 'Custom ($$$)', value: 'custom' }
            ]"
          />
        </b-form-group>

        <b-form-group label="Design visibility">
          <b-form-checkbox
            v-model="formData.visible"
          >
            Design is visible
          </b-form-checkbox>
        </b-form-group>

        <b-form-group>
          <b-btn
            block
            type="submit"
            variant="secondary"
          >
            Submit form
          </b-btn>
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { defineComponent, reactive, onMounted } from "vue";
import DesignModel from "@/model/admin/DesignModel";
import { showMessage } from "@/plugins/flashMessage";

export default defineComponent({
  name: "ModalDetail",
  props: {
    item: {
      type: Object,
      required: false,
      default: () => null,
    },
  },
  setup(props, { emit }) {
    const formData = reactive({
      name: "",
      type: "public",
      visible: false,
      image_preview: "",
      css_typo: "",
      css_widget: "",
      remote_name: "",
      info: "",
      mode: "standalone",
      config: [],
    });

    const isLoaded = reactive({ value: false });

    const submitForm = () => {
      if (props.item) {
        actionUpdate();
      } else {
        actionCreate();
      }
    };

    const actionCreate = async () => {
      await DesignModel.create(
        formData,
        () => {},
        (data) => {
          emit("handle-close", data);
          showMessage("flash.saved");
        }
      );
    };

    const actionUpdate = async () => {
      await DesignModel.update(
        formData,
        () => {},
        (data) => {
          emit("handle-close", data);
          showMessage("flash.saved");
        }
      );
    };

    onMounted(() => {
      if (props.item) {
        Object.assign(formData, props.item);
      }
      setTimeout(() => {
        isLoaded.value = true;
      }, 1500);
    });

    return {
      formData,
      isLoaded,
      submitForm,
    };
  },
});
</script>
