<template>
  <div>
    <b-form @submit.prevent="actionUpdate">
      <h5 class="mb-3">
        {{ $t("invoice.menu.ai-generator") }}
      </h5>

      <div class="bg-white p-4 mb-5 shadow-sm rounded">
        <p class="text-sm mb-4 fs-16 lh-25">
          {{ $t("With the automatic label generation feature, you can have AI create labels for your products. Just enable this feature and select how many products you want to create labels for. The descriptions are automatically generated for the most visited products that are most frequently added to the cart. If a product does not have a short description and parameters filled in, a description will not be generated for it.") }}
        </p>

        <tier-control
          :allow-tariff="['profi', 'premium', 'saas']"
          minimum-tariff="profi"
          position="left"
        >
          <p
            v-if="true"
            class="mb-4 text-sm p-3 rounded information-box"
          >
            <i class="ico ico-warning" />
            {{ $t("For now, this is a test run. In case of problems, please contact us.") }}
          </p>
          <b-form-group>
            <b-form-checkbox
              id="ai_generator_enable"
              v-model="form.ai_generator_enable"
              :unchecked-value="false"
              :value="true"
              name="ai_generator_enable"
            >
              {{ $t("Enable automatic label generation") }}
            </b-form-checkbox>

            <div v-if="form.ai_generator_enable">
              <h5 class="mt-4 pt-1 mb-0">
                {{ $t("Parameters for automatic creation of labels") }}
              </h5>

              <label
                class="mt-4 mb-0 pb-0 fs-16"
                for="ai_generator_count_product"
              >
                {{ $t("Number of products to automatically generate descriptions") }}
              </label>

              <b-form-input
                id="ai_generator_count_product"
                v-model="form.ai_generator_count_product"
                type="number"
                :min="1"
                :max="100"
                :value="10"
                name="ai_generator_count_product"
                class="mt-2 w-25"
              />

              <p
                class="mt-4 mb-0 fs-16"
              >
                {{ $t("Select widget layout") }}
              </p>

              <div class="mt-2 mb-4">
                <ai-layout
                  :layout="form.ai_generator_layout"
                  @switch-layout="switchLayout"
                />
              </div>
            </div>
            <b-btn
              class="mt-3"
              type="submit"
              variant="secondary"
            >
              {{ $t("Save settings") }} <i class="ico ico-arrow-right" />
            </b-btn>
          </b-form-group>
        </tier-control>
      </div>
    </b-form>
  </div>
</template>

<script>
import lang from "@/js/config/lang.js";
import TierControl from "@/share/tier-control.vue";
import AiLayout from "@/share/ai-layout.vue";
import eshopData from "@/mixin/eshopData";
import cloneDeep from "lodash.clonedeep";
import { useEshopStore } from "@/store/EshopStore";

export default {
  name: "AiGenerator",

  mixins: [eshopData],

  async mounted() {
    const { eshop } = this;
    this.form = cloneDeep(eshop);
  },

  data() {
    return {
      lang,
      form: {},
    };
  },

  components: {
    "ai-layout": AiLayout,
    "tier-control": TierControl,
  },

  methods: {
    async actionUpdate() {
      const { form } = this;

      form.ai_generator_count_product = parseInt(form.ai_generator_count_product, 10);

      await useEshopStore().updateEshop({
        data: form
      });
    },


    async switchLayout(id){
      this.form.ai_generator_layout = id;
    }
  },
};
</script>
