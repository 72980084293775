<template>
  <div class="bg-warning-bar position-static w-100 text-center z-999 fs-14 p-1">
    <strong>{{ $t('Attention!') }}</strong> {{ $t('You are currently using a new version of the API, which is still in the testing phase. If you encounter any issues, please let us know!') }}
  </div>
  <router-view />
</template>

<style scoped>
  .z-999{
    z-index: 999;
  }

  .bg-warning-bar{
    background: #f8ff00;
  }
</style>