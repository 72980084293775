<template>
  <a
    v-tooltip.top-center="$t(toolTipMessage)"
    :class="[
      isFavourite ? 'ico-favourite-enable' : 'ico-favourite-disable',
      'ico',
    ]"
    href="#"
    @click.prevent="actionCreate"
  />
</template>

<script setup>
import { ref, computed } from 'vue';
import ProductModel from "@/model/ProductModel.js";
import { showMessage } from "@/plugins/flashMessage";
import { useStatsInfoStore } from "@/store/StatsInfoStore";

const props = defineProps({
  state: {
    type: Boolean,
    required: true,
  },
  content: {
    type: String,
    required: true,
    default: "product",
  },
  id: {
    type: Number,
    required: true,
  },
});

const isFavourite = ref(props.state);

const toolTipMessage = computed(() => {
  return isFavourite.value
    ? "tooltip.removeFromFavourite"
    : "tooltip.addToFavourite";
});

const actionCreate = async () => {
  const { content, id } = props;

  const newState = !isFavourite.value;
  const message = newState
    ? `flash.favourite.${content}.add`
    : `flash.favourite.${content}.remove`;

  await ProductModel.favourite(
    {
      is_favourite: newState,
      id,
      content,
    },
    () => {},
    async () => {
      isFavourite.value = newState;
      showMessage(message);
      await useStatsInfoStore().fetch();
    }
  );
};
</script>