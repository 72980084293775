<template>
  <b-row>
    <b-col cols="4">
      <b-nav
        tabs
        vertical
      >
        <b-nav-item
          :active="activeTab === 'card'"
          data-cy="product-detail-info"
          @click="activeTab = 'card'"
        >
          {{ $t("Product info") }}
        </b-nav-item>
        <b-nav-item
          :active="activeTab === 'design'"
          data-cy="product-detail-setting"
          @click="activeTab = 'design'"
        >
          {{ $t("Design setting") }}
        </b-nav-item>

        <b-nav-item
          :active="activeTab === 'state'"
          data-cy="product-detail-state"
          @click="activeTab = 'state'"
        >
          {{ $t("Product state") }}
        </b-nav-item>
      </b-nav>
    </b-col>
    <b-col cols="8">
      <template v-if="activeTab === 'card'">
        <template v-if="isConfigureLang">
          <div
            class="text-center"
          >
            <b-icon
              icon="info-circle"
              size="50"
            />
            <p>
              {{ $t("setting.export.warning.prefix") }}
              <router-link
                :to="{ name: 'setting-multi-lang' }"
                class="text-success"
              >
                {{ $t("setting.export.warning.link") }}
              </router-link>
              ,
              {{ $t("setting.export.warning.postfix") }}.
            </p>
          </div>
        </template>
        <template v-else>
          <b-form
            v-if="isLoaded"
            data-cy="product-short-modal"
            disabled-field
            @submit.prevent="actionUpdate"
          >
            <b-form-group
              :label="$t('product.name')"
            >
              <b-form-input
                v-model="product.name"
                :readonly="eshop.export_mode !== 1"
                :placeholder="$t('product.placeholder.name')"
                data-cy="product-short-name"
              />
              <small class="form-text text-muted">
                <strong>{{ $t("product.warning") }}!</strong>
                {{ $t("product.rewriteName") }}</small>
            </b-form-group>

            <b-form-group
              v-if="isShoptet && eshop.export_mode === 1"
              :label="$t('product.shortDescription')"
            >
              <div
                class="bg-white"
              >
                <froala
                  v-model:value="product.short_description"
                  :readonly="hasAllowExport"
                  :config="config"
                />

                <small class="form-text text-muted">
                  <strong>{{ $t("product.warning") }}!</strong>
                  {{ $t("product.rewriteShortDescription") }}
                </small>
              </div>
            </b-form-group>

            <b-btn
              :disabled="!product.name"
              block
              type="submit"
              variant="secondary"
            >
              {{ $t("product.save") }}
            </b-btn>
          </b-form>
        </template>
      </template>
      <template v-else-if="activeTab === 'design'">
        <modal-change-design
          :product="product"
          @change-design="changeDesign"
          @action-update="actionUpdate"
        />
      </template>
      <template v-else-if="activeTab === 'state'">
        <modal-change-status
          :products="[product]"
          @change-status="changeState"
        />
      </template>
    </b-col>
  </b-row>
</template>

<script setup>
import { ref, computed, onMounted, defineProps, defineEmits } from "vue";
import { useI18n } from "vue-i18n";
import editorConfig from "@/js/config/froala.js";
import ProductModel from "@/model/ProductModel.js";
import { showMessage } from "@/plugins/flashMessage";
import { useEshopData } from "@/composables/useEshopData";
import ModalChangeStatus from "@/share/part/modal-change-status.vue";
import ModalChangeDesign from "@/share/part/modal-change-design.vue";

const props = defineProps({
  id: {
    type: Number,
    required: true
  },
  options: {
    type: Object,
    required: true
  }
});

const emit = defineEmits(["update"]);

const { t, locale } = useI18n();
const { eshop, isShoptet, isConfigureLang } = useEshopData();

const product = ref({});
const isLoaded = ref(false);
const activeTab = ref("card");

const config = ref({
  ...editorConfig,
  language: locale.value,
  placeholderText: ""
});

const hasAllowExport = computed(() => {
  return eshop.value.export_mode === 1;
});

onMounted(async () => {
  await ProductModel.findMe(props.id,
    () => {
    },
    (productData) => {
      product.value = productData;
      isLoaded.value = true;
    });
});

const actionUpdate = async () => {
  const { options } = props;
  const { content } = options;

  await ProductModel.update(
    product.value.id,
    {
      name: product.value.name,
      status: product.value.status,
      design_id: product.value.design_id,
      short_description: product.value.short_description,
      is_reference: product.value.is_reference
    },
    () => {
    },
    () => {
      emit("update", product.value);
      showMessage(`flash.module.${content}`);
    }
  );
};

const changeState = async (state) => {
  product.value.status = state;
  await actionUpdate();
};

const changeDesign = (designId) => {
  product.value.design_id = designId;
};
</script>