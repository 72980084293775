<template>
  <!--
  <div
    v-if="useBrowserEditor"
    class="pt-4"
  >
    <slot name="view" />
  </div>
  -->
  <template v-if="allowMultiLang">
    <div class="content-editor__languages">
      <template v-if="isConfigureLang">
        <div class="content-editor__languages-flags">
          <a
            :class="[
              'content-editor__languages-flag',
              'default' === valueLang ? 'active' : '',
            ]"
            href="#"
            @click.prevent="selectLang('default')"
          >
            <b-img-lazy
              :src="currentFlag"
              class="content-editor__languages-flag-img"
              :alt="currentFlag"
            />
          </a>

          <a
            v-for="(flag, index) in sortLang"
            :key="index"
            :class="[
              'content-editor__languages-flag',
              flag === valueLang ? 'active' : '',
            ]"
            href="#"
            @click.prevent="selectLang(flag)"
          >
            <b-img-lazy
              :src="`https://pobo-cdn.b-cdn.net/flag/${flag}.svg`"
              :alt="flag"
              class="content-editor__languages-flag-img"
            /></a>
        </div>

        <div
          v-if="!useBrowserEditor"
          class="content-editor__languages-translate"
        >
          <b-btn
            variant="white"
            size="sm"
            block
            @click="$emit('open-translate')"
          >
            <b-icon
              icon="translate"
              size="20px"
            />
            {{ $t("translate.action") }}
          </b-btn>
        </div>
      </template>
      <template v-else>
        <div class="d-flex align-items-center">
          <div class="pr-2">
            <strong
              class="rounded d-inline-block yellow-info-box"
            >
              {{ $t("translate.news") }}
            </strong>
          </div>
          <div class="fs-15">
            {{ $t("translate.managed") }}
          </div>
          <div>
            <router-link
              :to="{ name: 'setting-multi-lang' }"
              class="btn btn-secondary btn-sm ml-2"
              target="_blank"
            >
              <i
                class="ico ico-setting"
                style="margin: 0 3px -3px 0"
              />
              {{ $t("translate.setting") }}
            </router-link>
          </div>
        </div>
      </template>
    </div>
  </template>

  <div class="position-relative">
    <transition name="fade">
      <a
        v-if="widgetType === 1"
        class="content-editor__component-category"
        @click="openCategoryFilter(!isCategoryFilterOpen)"
      >
        <b-icon
          icon="sort-down"
          class="mr-1 mb-1"
          size="18"
        />
      </a>
    </transition>
    <div class="content-editor__component-switch">
      <a
        v-for="item in widget.tab"
        :key="item.id"
        :data-cy="'content-editor-link-id-' + item.id"
        :class="[setActiveClass(item.id), 'content-editor__component-link']"
        @click="setActiveBlock(item.id)"
      >
        <span class="content-editor__component-icon">
          <b-icon
            size="13"
            :icon="item.icon"
          />
        </span>
        <span class="d-block fs-13 lh-13">
          {{ $t(item.translate) }}
        </span>
      </a>

      <transition name="fade">
        <div
          v-if="isCategoryFilterOpen"
          class="position-absolute bg-white shadow-sm border-light rounded content-editor__navigation-submenu"
        >
          <div class="px-4 pt-4 pb-2 content-editor__navigation-inner">
            <div v-if="isCategoryFilterOpen">
              <b-row class="border-bottom pb-2 mb-2">
                <b-col cols="8">
                  <h5 class="mb-4">
                    {{ $t('Category widget') }}
                  </h5>
                </b-col>

                <b-col
                  cols="4"
                  class="text-right"
                >
                  <b-btn
                    variant="danger"
                    size="sm"
                    @click="openCategoryFilter(false)"
                  >
                    {{ $t('Close') }} <b-icon
                      icon="x-circle-fill"
                      size="18px"
                      class="ml-1"
                    />
                  </b-btn>
                </b-col>
              </b-row>
              <div class="content-editor__navigation">
                <a
                  :class="setCategoryClass(null)"
                  href="#"
                  class="content-editor__navigation-link"
                  @click.prevent="scrollToCategory(null)"
                >
                  <span class="content-editor__navigation-inner-ico">
                    <i
                      style="background-image: url('https://image.pobo.cz/widget-category/wc-image.svg')"
                      class="ico-wc"
                    />
                  </span>

                  <span class="content-editor__navigation-inner-text fs-13 font-weight-bolder lh-1">
                    {{ $t("product.all") }}
                  </span>

                  <span class="content-editor__navigation-count bg-secondary">
                    {{ list.length }}
                  </span>
                </a>
                <a
                  v-for="category in list"
                  :key="category.id"
                  :class="setCategoryClass(category.id)"
                  href="#"
                  class="content-editor__navigation-link"
                  @click.prevent="scrollToCategory(category.id)"
                >
                  <span class="content-editor__navigation-inner-ico">
                    <i
                      :style="`background-image: url(${category.ico})`"
                      class="ico-wc"
                    />
                  </span>

                  <span class="content-editor__navigation-inner-text">
                    <em
                      class="fs-15 lh-16 font-weight-bolder d-block"
                      style="font-style: normal"
                    >{{ category.name }}</em>


                    <em
                      class="fs-12 lh-15 mt-2 font-weight-normal d-block text-muted font-weight-normal"
                    >
                      <template v-for="(cat, index) in category.widget.slice(0, 3)">
                        {{ cat.name }}<template v-if="index + 1 < category.widget.length && index < 2">, </template>
                      </template>
                      <template v-if="category.widget.length > 3">
                        + {{ $t('others') }} {{ $t("inflection.enum.category", category.widget.length - 3) }}
                      </template>
                    </em>
                  </span>


                  <span class="content-editor__navigation-count bg-secondary">
                    {{ category.widget.length }}
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>

  <div :class="multiLangClass">
    <template v-if="widgetType === 3">
      <widget-favourite />
    </template>

    <template v-if="widgetType === 2">
      <div class="bg-white p-2 mb-4 text-center">
        {{ $t("editor.custom.info") }}
        <a
          :href="`mailto:${$t('editor.custom.mail')}`"
          class="text-danger"
        >{{ $t("editor.custom.mail") }}
        </a>
      </div>
      <widget-standard
        :list="list"
        :is-loaded="isLoaded"
        :active-category-id="activeCategoryId"
        @move-start="$emit('move-start', true)"
        @move-end="$emit('move-end', false)"
      />
    </template>

    <template v-if="widgetType === 1">
      <widget-standard
        :list="list"
        :is-loaded="isLoaded"
        :active-category-id="activeCategoryId"
        @move-start="$emit('move-start', true)"
        @move-end="$emit('move-end', false)"
      />
    </template>
  </div>
</template>

<script>
import lang from "@/js/config/lang.js";
import WidgetFavourite from "@/share/widget-favourite.vue";
import WidgetStandard from "@/share/widget-standard.vue";
import widget from "@/share/config/widget.json";
import ShareModel from "@/model/ShareModel";
import EshopData from "@/mixin/eshopData";

export default {
  name: "WidgetList",

  mixins: [EshopData],

  props: {
    useBrowserEditor: {
      type: Boolean,
      default: false,
    },

    valueLang: {
      type: String,
      required: false,
      default: "default",
    },
  },

  async mounted() {
    await this.actionRead();
  },

  data() {
    return {
      widgetType: 1,
      activeCategoryId: null,
      isLoaded: false,
      isCategoryFilterOpen: false,
      lang,
      widget,
      list: [],
    };
  },

  computed: {
    sortLang() {
      const { eshop } = this;
      const { editor_lang, lang_list } = eshop;
      return lang.filter(
        (flag) => flag !== editor_lang && lang_list.includes(flag)
      );
    },

    currentFlag() {
      const { eshop } = this;
      const { editor_lang } = eshop;
      return `https://pobo-cdn.b-cdn.net/flag/${editor_lang}.svg`;
    },

    allowMultiLang() {
      const { eshop, isAdmin } = this;
      const { platform } = eshop;
      return platform === "shoptet" || isAdmin;
    },

    multiLangClass() {
      const { allowMultiLang } = this;
      return [
        allowMultiLang
          ? "content-editor__sticky--enable"
          : "content-editor__sticky--disable",
        "content-editor__sticky",
      ];
    },
  },

  watch: {
    isCategoryFilterOpen: {
      handler: function (val) {
        if (val) {
          document.body.classList.add("open-filter-widget");
        } else {
          document.body.classList.remove("open-filter-widget");
        }
      },
    },
  },

  components: {
    "widget-favourite": WidgetFavourite,
    "widget-standard": WidgetStandard,
  },

  methods: {
    openCategoryFilter(state = false) {
      this.isCategoryFilterOpen = state;
    },

    selectLang(flag) {
      this.$emit("update-lang", flag);
    },

    setActiveClass(type) {
      const { widgetType } = this;
      return `${widgetType === type ? "active" : ""}`;
    },

    async setActiveBlock(type) {
      this.widgetType = type;
      this.activeCategoryId = null;

      if ([1,2].includes(type)) {
        await this.actionRead();
      }
    },

    scrollToCategory(id, closeFilter = true) {
      this.activeCategoryId = id;
      if(closeFilter) this.isCategoryFilterOpen = false;
      this.widgetType = 1;
    },

    setCategoryClass(categoryId) {
      const { activeCategoryId } = this;

      return [
        activeCategoryId === categoryId ? "active" : null,
        "content-editor__navigation-ico",
      ];
    },


    async actionRead() {
      const { widgetType } = this;
      this.isLoaded = false;

      await ShareModel.findComponent(
        widgetType,
        () => {},
        (data) => {
          this.list = data;
          this.isLoaded = true;
        }
      );
    },
  },
};
</script>

<style scoped></style>
