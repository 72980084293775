<template>
  <div class="design-showcase">
    <div class="design-showcase__header text-center">
      <h2 class="fs-24">
        {{ $t('design.modal.titleF') }} <span class="design-showcase__highlight">{{ $t('design.modal.titleM') }}</span> {{ $t('design.modal.title') }}
      </h2>

      <p class="fs-18">
        {{ $t('design.modal.info') }}
      </p>
    </div>
    <div class="row align-items-start">
      <div class="col-md-6">
        <h2 class="design-showcase__heading font-weight-bolder fs-19 mb-3">
          {{ $t('design.modal.subtitleF') }}
        </h2>
        <div class="design-showcase__slider-container">
          <button
            class="design-showcase__slider-button design-showcase__slider-button--left"
            @click="prevSlide"
          >
            &#10094;
          </button>
          <div
            ref="sliderWrapper"
            class="design-showcase__slider-wrapper"
          >
            <div
              class="design-showcase__slider"
              :style="{ transform: `translateX(-${(currentIndex + visibleSlides) * (100 / visibleSlides)}%)` }"
              :class="{ 'no-transition': noTransition }"
            >
              <div
                v-for="(item, index) in clonedSlidesAtStart"
                :key="'clone-start-' + index"
                class="design-showcase__slide"
              >
                <a
                  :href="item.link"
                  target="_blank"
                >
                  <img
                    :src="item.src"
                    :alt="item.alt"
                    class="img-fluid"
                  >
                </a>
              </div>
              <div
                v-for="(item, index) in items"
                :key="'original-' + index"
                class="design-showcase__slide"
              >
                <a
                  :href="item.link"
                  target="_blank"
                >
                  <img
                    :src="item.src"
                    :alt="item.alt"
                    class="img-fluid"
                  >
                </a>
              </div>
              <div
                v-for="(item, index) in clonedSlidesAtEnd"
                :key="'clone-end-' + index"
                class="design-showcase__slide"
              >
                <a
                  :href="item.link"
                  target="_blank"
                >
                  <img
                    :src="item.src"
                    :alt="item.alt"
                    class="img-fluid"
                  >
                </a>
              </div>
            </div>
          </div>
          <button
            class="design-showcase__slider-button design-showcase__slider-button--right"
            @click="nextSlide"
          >
            &#10095;
          </button>
        </div>
      </div>
      <div class="col-md-6 d-flex flex-column justify-content-center pl-5">
        <h2 class="font-weight-bolder fs-19">
          {{ $t('design.modal.subtitleR') }}
        </h2>
        <p class="mb-5">
          {{ $t('design.modal.ctainfoF') }} <a
            class="text-secondary"
            href="https://www.pobo.cz/static/individualni-upravy-na-miru"
          >
            {{ $t('design.modal.catinfoS') }}
          </a>
        </p>
        <div class="design-showcase__support flex-grow-1">
          <iframe
            :src="getSupportUrl"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eshopData from "@/mixin/eshopData";
export default {

  mixins:[eshopData],

  data() {
    return {
      items: [
        { src: 'https://image.pobo.cz/images/hk4722pmfv9q/607d8fzfjiecvxw1qzz8-md.png', alt: 'Pobo custom design', link: 'https://www.pobo.cz/reference' },
        { src: 'https://image.pobo.cz/images/hk4722pmfv9q/090ylqgn1k16bomg0o2o-md.png', alt: 'Pobo custom design', link: 'https://www.pobo.cz/reference' },
        { src: 'https://image.pobo.cz/images/hk4722pmfv9q/vctlhvlovfqnpp8kzbx0-md.png', alt: 'Pobo custom design', link: 'https://www.pobo.cz/reference' },
        { src: 'https://image.pobo.cz/images/hk4722pmfv9q/dp5us0cdnerw44sv43ms-md.png', alt: 'Pobo custom design', link: 'https://www.pobo.cz/reference' },
        { src: 'https://image.pobo.cz/images/hk4722pmfv9q/12duza1ze86ve2xzitzt-md.png', alt: 'Pobo custom design', link: 'https://www.pobo.cz/reference' },
        { src: 'https://image.pobo.cz/images/hk4722pmfv9q/iulpibesbluqw2mcnmn6-md.png', alt: 'Pobo custom design', link: 'https://www.pobo.cz/reference' },
      ],
      currentIndex: 0,
      visibleSlides: 3,
      noTransition: false,
    };
  },
  computed: {
    totalSlides() {
      return this.items.length;
    },
    clonedSlidesAtStart() {
      return this.items.slice(-this.visibleSlides);
    },
    clonedSlidesAtEnd() {
      return this.items.slice(0, this.visibleSlides);
    },
    getSupportUrl(){
      return "https://pobo-support.com/" + this.editorLang + "/?eshop=" + this.eshopUrl + "&tariff=" + this.eshopTariff + "&multilang=" + this.isConfigureLang + "&is-shoptet=" + this.isShoptet + "&email=" + this.eshop.email + "&orderDesign=true";
    },
  },
  methods: {
    nextSlide() {
      if (this.currentIndex < this.totalSlides) {
        this.currentIndex++;
      } else {
        this.resetSlider(true);
      }
    },
    prevSlide() {
      if (this.currentIndex > -this.visibleSlides) {
        this.currentIndex--;
      } else {
        this.resetSlider(false);
      }
    },
    resetSlider(forward) {
      this.noTransition = true;
      this.currentIndex = forward ? 0 : this.totalSlides - 1;
      this.$nextTick(() => {
        this.noTransition = false;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.design-showcase {
  &__header {
    font-size: 24px;
    background: #e4e4e4;
    padding: 14px 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 35px;

    & .design-showcase__highlight {
      display: inline-block;
      background-color: #649b0d;
      color: #fff;
      padding: 5px 15px;
      font-weight: bold;
      border-radius: 5px;
    }
  }

  &__heading {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  &__slider-container {
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
  }

  &__slider-wrapper {
    width: 100%;
    overflow: hidden;
  }

  &__slider {
    max-height: 400px;
    display: flex;
    transition: transform 0.5s ease-in-out;

    &.no-transition {
      transition: none;
    }
  }

  &__slide {
    min-width: calc(100% / 3);
    padding: 5px;
  }

  &__slider-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
    font-size: 1.5rem;
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 50%;
    z-index: 1;

    &:hover {
      background-color: rgba(255, 255, 255, 1);
    }

    &--left {
      left: 10px;
    }

    &--right {
      right: 10px;
    }
  }

  &__support {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    iframe {
      min-height: 360px;
      flex-grow: 1;
      width: 100%;
      border: none;
    }
  }
}
</style>
