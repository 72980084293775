<template>
  <div>
    <b-form @submit.prevent="actionUpdate">
      <h5 class="mb-3">
        {{ $t("Animation settings") }}
      </h5>

      <div class="bg-white p-4 mb-5 shadow-sm rounded">
        <div class="d-flex">
          <div class="w-75 justify-content-end align-items-center d-flex">
            <p class="text-sm mb-4 fs-16 lh-25">
              {{ $t("Enabling this feature applies special Pobo animations to your tooltips with smooth loading of individual widgets to increase their visual appeal.") }}
            </p>
          </div>

          <div class="w-25">
            <video
              class="w-100"
              autoplay
              loop
              muted
              playsinline
            >
              <source
                src="https://pobo-cdn.b-cdn.net/static/animation-video.mp4"
                type="video/mp4"
              >
            </video>
          </div>
        </div>

        <tier-control
          :allow-tariff="['premium', 'saas']"
          minimum-tariff="premium"
          position="left"
        >
          <b-form-group>
            <b-form-checkbox
              id="enable_effect"
              v-model="form.enable_effect"
              :unchecked-value="false"
              :value="true"
              name="enable_effect"
            >
              {{ $t("Enable animations on labels") }}
            </b-form-checkbox>

            <b-btn
              class="mt-3"
              type="submit"
              variant="secondary"
            >
              {{ $t("Save settings") }} <i class="ico ico-arrow-right" />
            </b-btn>
          </b-form-group>
        </tier-control>
      </div>
    </b-form>
  </div>
</template>

<script>
import lang from "@/js/config/lang.js";
import eshopData from "@/mixin/eshopData";
import cloneDeep from "lodash.clonedeep";
import { useEshopStore } from "@/store/EshopStore";
import TierControl from "@/share/tier-control.vue";
export default {
  name: "AnimationSetting",

  mixins: [eshopData],

  async mounted() {
    const { eshop } = this;
    this.form = cloneDeep(eshop);
  },

  data() {
    return {
      lang,
      form: {},
    };
  },

  components: {
    TierControl,
  },

  methods: {
    async actionUpdate() {
      const { form } = this;

      await useEshopStore().updateEshop({
        data: form
      });
    },
  },
};
</script>
