<template>
  <widget-all />
</template>

<script>
import { defineComponent } from "vue";
import WidgetAll from "@/components/component/part/widget-all.vue";

export default defineComponent({
  name: "WidgetWrapper",
  components: {
    WidgetAll,
  },
});
</script>
