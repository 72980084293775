<template>
  <div class="container modal-designer-max-width">
    <template v-if="isActiveStep(1)">
      <h2 class="text-center mb-3 mt-3">
        {{ $t("Introducing Live Designer") }}
      </h2>
      <h5 class="text-center text-muted mb-4 font-weight-light">
        {{ $t("Your assistant in styling product pages.") }}
      </h5>

      <ul class="list-group list-group-flush mb-4 pt-3">
        <li
          v-for="condition in conditions"
          :key="condition.key"
          class="list-group-item d-flex align-items-center p-2 px-3 rounded shadow-sm"
          :class="{ 'bg-success-designer': condition.isMet }"
        >
          <b-icon
            v-if="condition.isMet"
            icon="check-circle"
            variant="success"
            class="mr-2"
          />
          <b-icon
            v-else
            icon="exclamation-circle"
            variant="danger"
            class="mr-2"
          />
          {{ $t(condition.label) }} &nbsp;
          <strong v-if="condition.isMet">{{ $t("COMPLETED") }}</strong>
          <strong v-else>
            <router-link
              :to="condition.link"
              class="text-danger border-bottom"
            >
              {{ $t("NOT COMPLETED") }}
            </router-link>
          </strong>
        </li>
      </ul>

      <b-alert
        show
        variant="info"
        class="mb-4"
      >
        {{ $t("Live Designer is in the final stages of testing and you can try it without restrictions") }}.
        {{ $t("The basic features that were available in Pobo Designer will be available to all users and premium features from the PROFI and PREMIUM plan")
        }}.
      </b-alert>


      <b-form-group class="text-center pt-3">
        <b-button
          block
          type="submit"
          variant="secondary"
          size="lg"
          :disabled="!canStartDesigner"
          @click.prevent="startDesigner"
        >
          {{ $t("Launch Designer") }}
        </b-button>
      </b-form-group>

      <p class="text-center text-muted">
        {{ $t('When you start LiveDesigner, the label will be exported!') }}
      </p>
    </template>


    <template v-if="isActiveStep(2)">
      <div class="d-flex justify-content-center align-items-center mt-3 mb-3 flex-column">
        <div class="loading-spinner" />

        <h2 class="text-center mt-3 mb-3 font-weight-bolder">
          {{ $t("Processing Design settings") }}
        </h2>

        <h5 class="text-center text-muted mb-4 font-weight-light">
          {{ $t("Please wait, this will only take a moment.") }}
        </h5>
      </div>
    </template>


    <template v-if="isActiveStep(3)">
      <div class="mt-3 mb-3">
        <i
          class="ico ico-checked-xl m-auto d-block"
        />
        <h2 class="text-center mb-3">
          {{ $t("The design has been modified and assigned to this product") }}
        </h2>

        <h5 class="text-center text-muted mb-4 font-weight-light">
          {{ $t("To publish the changes, please export the description.") }}
        </h5>

        <h2 class="text-left mt-5 mb-3 font-weight-bolder fs-20">
          {{ $t("How to use this Design for other products") }}
        </h2>

        <p class="text-left text-muted">
          {{ $t("If you want to use the Design created in the Designer for other products, just go to the Product Details -> Design Settings and select the option Design generate from Live Designer.")
          }}
        </p>

        <img
          src="https://pobo-cdn.b-cdn.net/static/designer-gif-_1_-_online-video-cutter.com_.gif"
          alt="designer-gif"
          class="w-100"
        >
      </div>
    </template>

    <template v-if="isActiveStep(4)">
      <div class="mt-3 mb-3">
        <h2 class="text-center mt-3 mb-3 font-weight-bolder fs-25">
          {{ $t("Saving unfortunately failed") }}
        </h2>

        <p class="text-left text-muted">
          {{ $t("The data could not be saved, please try again later.") }}
        </p>
      </div>
    </template>
  </div>
</template>

<script>
import EshopData from "@/mixin/eshopData";
import ExportModel from "@/model/ExportModel.js";
import TemplateModel from "@/model/TemplateModel";
export default {
  name: "DesignerV2",

  mixins: [EshopData],
  
  props: {
    mode: {
      required: true,
      type: String
    },
    step: {
      required: true,
      default: 1,
      type: Number
    },
    content: {
      type: Object,
      required: true,
    },
  },

  async mounted() {
    await this.getAssetData();
  },

  data() {
    return {
      assets: null,
    }
  },

  computed: {
    isGeneric() {
      return this.mode === "generic";
    },
    hasValidAssets() {
      return !(this.assets && this.assets.some(asset => asset.name.includes("Migrate CSS from Designer V1")));
    },

    isValidDesignId() {
      return true;
    },

    canStartDesigner() {
      return this.isGeneric && this.hasValidAssets && this.isValidDesignId;
    },

    conditions() {
      return [
        {
          key: "isGeneric",
          label: "Have generic styling of labels enabled:",
          isMet: this.isGeneric,
          link: { name: "setting-template" }
        },
        {
          key: "hasValidAssets",
          label: "Not having active changes from designer 1.0.",
          isMet: this.hasValidAssets,
          link: { name: "setting-template" }
        },
        {
          key: "isValidDesignId",
          label: "Not having an active incompatible template",
          isMet: this.isValidDesignId,
          link: { name: "setting-template" }
        }
      ];
    }
  },

  methods: {
    async startDesigner() {
      const success = await this.runExport();
      if (success) {
        this.$emit("startDesigner");
      }
    },

    async getAssetData(){
      await TemplateModel.findMe(
        () => {},
        (data) => {
          this.assets = data;
        }
      );
    },

    async getTemplateData(){
      await TemplateModel.findMe(
        () => {},
        (data) => {
          this.assets = data;
        }
      );
    },

    isActiveStep(number) {
      return this.step === number;
    },

    async runExport() {
      const { content } = this;
      const { id } = content;

      const request = {
        type: "product",
        data: {
          id: id,
        },
      };

      try {
        await ExportModel.uploadMe(
          request,
          () => {},
          () => {
            return true;
          }
        );
        return true;
      }catch (e){
        return false;
      }
    }
  }

};
</script>


<style scoped>
.modal-designer-max-width {
  max-width: 600px;
}

.bg-success-designer {
  background: rgba(0, 255, 0, 0.25);
}

.loading-spinner {
  border: 5px solid #1c1c1c;
  border-top: 5px solid #649b0d;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spinLoading 2s linear infinite;
}

@keyframes spinLoading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
