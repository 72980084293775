<template>
  <div>
    <layout-grid>
      <template #title>
        {{ $t("blog.header") }}
      </template>

      <template #welcome>
        <help-box
          author="sara"
          header="Lorem ipsum is samet"
          type="blog"
        >
          <h2 class="mb-4">
            {{ $t("How to create blog post?") }}
          </h2>
          <p class="fs-16 lh-30 font-weight-light">
            {{ $t("Pobo Page Builder makes it easy for even the layman to create compelling blog articles.") }}
            {{ $t("With the clear drag-and-drop editor, you can quickly create content without programming knowledge, which saves time and makes work easier.") }}
            {{ $t("The result is attractive articles with a professional look, created playfully and efficiently.") }}
          </p>
        </help-box>
      </template>

      <template
        v-if="isShoptet || isAdmin"

        #create
      >
        <b-btn
          class="my-4"
          variant="secondary"
          @click="openCreate(true)"
        >
          {{ $t("blog.create") }} <i class="ico ico-arrow-right" />
        </b-btn>
      </template>

      <template #content>
        <div class="blog-table--head">
          <div />
          <div>{{ $t("grid.widget") }}</div>
          <div class="pl-2">
            {{ $t("blog.table.created") }}
          </div>

          <div>
            <grid-search
              @input="updateQuery"
              @submit="actionRead"
            />
          </div>
        </div>

        <template v-if="isLoaded && eshop.id">
          <div
            v-for="item in list"
            :key="item.id"
            class="blog-table"
          >
            <div />
            <div>
              <grid-item-quality :content="item.content" />
            </div>

            <div class="text-muted pl-2 small">
              {{ when(item.created_at) }}
            </div>

            <div>
              <template v-if="item.slug && eshop.role.includes('admin')">
                <a
                  :href="item.slug"
                  target="_blank"
                >
                  <span class="font-weight-bolder">{{ item.name }}</span>
                  <i class="ico ico-external" />
                </a>
              </template>
              <template v-else>
                <span class="font-weight-bolder">{{ item.name }}</span>
              </template>

              <small
                v-if="item.category !== null"
                class="d-block text-success"
              >
                Typ stránky: [{{ item.category }}] | Pozice:
                <strong>{{ item.position }}</strong>
              </small>
            </div>

            <div>
              <div class="d-flex justify-content-end align-items-center">
                <template v-if="isShoptet">
                  <b-btn
                    class="mr-2"
                    size="sm"
                    variant="secondary"
                    @click="openHTML(item.id)"
                  >
                    <i class="ico ico-edit" />
                    {{ $t("blog.table.uploadToEshop") }}
                  </b-btn>
                </template>
                <template v-else>
                  <layout-single-export
                    :content="item"
                    trigger="grid"
                    type="blog"
                  />
                </template>

                <layout-edit-button
                  :item="item"
                  :class="[isShoptet ?? 'mr-3']"
                  @open-editor="getBlog"
                />

                <confirm-delete
                  v-if="isShoptet"
                  :id="item.id"
                  @delete="actionDelete"
                >
                  <template #trigger>
                    <i class="ico ico-component-delete" />
                    {{ $t("blog.table.delete") }}
                  </template>
                </confirm-delete>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div
            v-for="n in 10"
            :key="n"
            class="blog-table"
          >
            <div
              v-for="m in 5"
              :key="m"
            >
              <b-skeleton-img
                height="45px"
                no-aspect
              />
            </div>
          </div>
        </template>
      </template>
    </layout-grid>

    <browser-panel
      :primary-modal-open="isEditorOpen"
      type="blog"
      @set-active-panel="setActivePanel"
    />

    <Transition name="slide-up">
      <editor-browser
        v-show="isEditorOpen"
        :tabs="tabs.getGlobalTabs(tabsPageType)"
        :active-id="tabs.getGlobalActiveIndex(tabsPageType)"
        :options="options"
        :is-open="isEditorOpen"
        type="blog"
        :window-browser-tab="windowBrowserTab"
        @open-tool-modal="openToolModal"
        @action-close="closeModal"
        @change-tab="changeTab"
        @action-minimize="minimizeEditor"
      />
    </Transition>

    <b-modal
      v-model="isModalHTMLOpen"
      :title="$t('blog.upload.title')"
      content-class="editor-modal"
      hide-footer
      size="lg"
    >
      <p>
        {{ $t("blog.upload.content") }}
      </p>

      <blog-html :id="htmlID" />
    </b-modal>
    <b-modal
      v-model="isModalCreateOpen"
      :title="$t('blog.modal.title')"
      hide-footer
      size="lg"
    >
      <form @submit.prevent="actionCreate">
        <div class="form-group">
          <label for="name">{{ $t("blog.modal.name") }}</label>
          <input
            id="name"
            v-model="formData.name"
            autocomplete="off"
            class="form-control"
            type="text"
          >
        </div>
        <button
          :disabled="!formData.name"
          class="btn btn-secondary"
          type="submit"
        >
          {{ $t("blog.modal.save") }}
        </button>
      </form>
    </b-modal>

    <b-modal
      v-model="duplicateLoading"
      hide-footer
      hide-header-close
      size="md"
    >
      <div class="d-flex flex-column justify-content-center align-items-center">
        <div>
          <b-spinner size="large" />
        </div>
        <h3 class="mt-2 text-center">
          {{ $t("I'm duplicating the post") }}
        </h3>

        <span class="mt-2 pb-4 text-center">
          {{ $t("It'll be a while...") }}
        </span>
      </div>
    </b-modal>

    <b-modal
      v-model="secondaryModalOpen"
      :size="getSize"
      :title="getTitle"
      hide-footer
    >
      <div
        v-if="tabOpen.includes('duplicate')"
        class="bg-white"
      >
        <form @submit.prevent="actionDuplicate">
          <div class="form-group">
            <label for="name">{{ $t("blog.modal.name") }}</label>
            <input
              id="name"
              v-model="duplicateData.name"
              autocomplete="off"
              class="form-control"
              type="text"
            >
          </div>
          <button
            :disabled="!duplicateData.name"
            class="btn btn-secondary"
            type="submit"
          >
            {{ $t("Create article and duplicate") }}
          </button>
        </form>
      </div>

      <div
        v-if="tabOpen.includes('detail')"
        class="w-100"
      >
        <modal-edit
          :blog="blog"
          :options="options"
          class="w-100"
          @update="secondaryModalOpen = false"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import BlogHTML from "@/share/blog-html.vue";
import {when} from "@/filter/vue";
import options from "@/config/share.json";
import GridSearch from "@/share/grid-search.vue";
import GridItemQuality from "@/share/grid-item-quality.vue";
import BlogModel from "@/model/BlogModel.js";
import eshopData from "@/mixin/eshopData.js";
import LayoutEditButton from "@/share/layout-edit-button.vue";
import {useStatsInfoStore} from "@/store/StatsInfoStore";
import HelpBox from "@/share/help-box.vue";
import axios from "axios";
import EditorBrowser from "@/share/editor-browser.vue";
import BrowserPanel from "@/share/browser-panel.vue";
import {useTabsStore} from "@/store/TabsStore";
import ModalEdit from "@/components/blog/modal-edit.vue";

export default {
  name: "blog",

  provide() {
    return {
      pageType: "blog"
    }
  },

  mixins: [eshopData],

  async mounted() {
    await this.actionRead();
    this.isLoaded = true;
  },

  data() {
    return {
      list: Array,
      query: "",
      isLoaded: false,
      id: null,
      isModalCreateOpen: false,
      isModalHTMLOpen: false,
      isEditorOpen: false,
      tabsPageType: 'blog',
      formData: {
        name: "",
      },
      duplicateData: {
        name: "",
      },
      windowBrowserTab: [
        {
          event: "duplicate",
          translate: "Duplicate of",
          type: "tool", // main or tool,
          ico: "tool-copy"
        },
        {
          event: "detail",
          translate: "blog.modal.name",
          type: "main"
        }
      ],
      duplicateLoading: false,
      htmlID: null,
      options: options.blog,
      tabOpen: [],
      secondaryModalOpen: false,
    };
  },

  computed: {
    tabs() {
      return useTabsStore();
    },
    getSize() {
      const {tabOpen} = this;

      if (tabOpen.includes("duplicate")) {
        return "lg";
      } else if (tabOpen.includes("detail")) {
        return "lg";
      }
      return "editor-lg";
    },

    getTitle() {
      const {tabOpen} = this;

      if (tabOpen.includes("duplicate")) {
        return this.$t("Enter the title of the new article");
      } else if (tabOpen.includes("detail")) {
        return this.$t("blog.modal.name");
      }
      return `Warning: getTitle() not set`;
    },

    blog() {
      return this.tabs.getTabData('blog');
    }
  },

  components: {
    "modal-edit": ModalEdit,
    "editor-browser": EditorBrowser,
    "browser-panel": BrowserPanel,
    HelpBox,
    LayoutEditButton,
    "grid-item-quality": GridItemQuality,
    "grid-search": GridSearch,
    "blog-html": BlogHTML,
  },

  metaInfo() {
    return {title: this.$t("meta.blog")};
  },

  methods: {
    when,

    openToolModal(name) {
      this.tabOpen = [name];
      this.secondaryModalOpen = true
    },

    changeTab(index) {
      this.tabs.setActiveIndex(this.tabsPageType, index);
    },

    async minimizeEditor() {
      this.isEditorOpen = false;
      document.body.classList.remove("modal-open");
      await useStatsInfoStore().fetch()
    },

    async getBlog(blog) {
      await this.tabs.fetchTab(this.tabsPageType, blog);
      this.isEditorOpen = true;
    },

    setActivePanel(entityId) {
      this.isEditorOpen = (this.tabs.globalActiveIndex !== entityId) || !this.isEditorOpen;

      if (!this.isEditorOpen) {
        document.body.classList.remove("modal-open");
      }
      this.tabs.setActiveIndex(this.tabsPageType, entityId);
    },

    updateQuery(query) {
      this.query = query;
    },

    async closeModal(item) {
      document.body.classList.remove("modal-open");
      await this.actionRead();
      await useStatsInfoStore().fetch();
      await this.tabs.closeEditor(this.tabsPageType, item.id);
      this.isEditorOpen = false;
    },

    async openHTML(id) {
      this.isModalHTMLOpen = true;
      this.htmlID = id;
    },

    async openCreate(state) {
      this.isModalCreateOpen = state;
    },

    async actionDuplicate() {
      const {name} = this.duplicateData;

      await BlogModel.create(
        {name: name},
        () => {
        },
        (response) => {
          this.secondaryModalOpen = false;
          this.isEditorOpen = false;
          this.duplicateLoading = true;
          this.isModalCreateOpen = false;
          this.actionReadBlog(response[0].id);
        }
      );
    },

    async actionReadBlog(blog_id) {
      const {options} = this;
      const {api} = options;
      const {widget} = api;

      await axios.get(widget.replace("[id]", this.blog.id)).then(({data}) => {
        this.actionDuplicateWidget(blog_id, data)
      });
    },

    async actionDuplicateWidget(id, widget) {
      const {options} = this;
      const {api} = options;
      const orderSaved = [];

      for (const [index, item] of widget.entries()) {

        const newData = {
          widget_id: item.widget_id,
          position: index,
          added: true,
          json: item.children,
          ord: [...orderSaved, {id: item.widget_id, position: index}],
        };

        try {
          await axios.post(api.widget.replace("[id]", id), newData)
            .then(({data}) => {
              orderSaved.push({id: data[index].id, position: index});
            })
            .catch((error) => {
              console.warn("Error");
              return error;
            });
        } catch (error) {
          console.log("Error", error);
        }
      }

      await this.actionRead();
      this.duplicateLoading = false;
    },

    async actionCreate() {
      const {name} = this.formData;

      await BlogModel.create(
        {name: name},
        () => {
        },
        (data) => {
          this.isModalCreateOpen = false;
          this.list = data;
        }
      );
    },

    async actionRead() {
      await BlogModel.findAll(
        this.query,
        () => {
        },
        (data) => {
          this.list = data;
        }
      );
    },

    async actionDelete(blogId) {
      await BlogModel.delete(
        blogId,
        () => {
        },
        (data) => {
          this.tabs.closeEditor('blog', blogId);
          this.list = data;
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>