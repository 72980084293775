import axios from "axios";

export default class ImageModel {
  static async findAll(
    startCallback = () => {},
    endCallback = () => {},
    errorCallback = () => {}
  ) {
    startCallback();

    return await axios
      .get("/api/v2/eshop/image/grid")
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch user image [findAll()]", error);
        errorCallback(error);
        return error;
      });
  }

  static async findMe(
    params,
    startCallback = () => {},
    endCallback = () => {}
  ) {
    startCallback();

    const { query, page } = params;

    return await axios
      .get(`/api/v2/eshop/image/bank/${page}/${query}`)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch image user [findMe()]", error);
        return error;
      });
  }

  static async create(data, startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .post("/api/v2/eshop/image/bank", data)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error create download image [create()]", error);
        return error;
      });
  }
}
