<template>
  <div>
    <table class="position-relative">
      <price-table-head
        :merged-tariffs="mergedTariffs"
        :region="items.region"
        :period-type="periodType"
        :show-price-with-vat="showPriceWithVat"
        :info="items.info"
        :get-price="getPrice"
        :price="items.price"
      />
      <tbody>
        <template v-if="data.config.showLimit">
          <tr>
            <td>
              <span class="d-block fs-16 pl-2 font-weight-bolder">
                {{ $t('Count product, blog post and category') }}
              </span>

              <span class="d-block fs-13 pl-2 font-weight-light">
                {{ $t('Includes only edited products, articles and categories') }}
              </span>
            </td>

            <template
              v-for="(limit, index) in data.limit"
              :key="index"
            >
              <td class="pt-3 px-3 fs-15">
                <div
                  v-for="(type, typeIndex) in ['products', 'categories', 'posts']"
                  :key="typeIndex"
                >
                  <div class="pl-4">
                    <template v-if="limit[type]">
                      <strong>{{ $t(limit[type]) }}</strong> {{ $t(`limiter.${type}`) }}
                    </template>
                    <template v-else>
                      {{ $t('Unlimited') }} {{ $t(`limiter.${type}`) }}
                    </template>
                  </div>
                </div>
              </td>
            </template>
          </tr>
        </template>

        <tr>
          <td>
            <span class="d-block fs-16 pl-2 font-weight-bolder">
              {{ $t("tariff.credits") }}
            </span>
          </td>

          <td
            v-for="(item, index) in data.header"
            :key="index"
            class="text-center"
          >
            {{ item.freeCredit[data.region] }}
          </td>
        </tr>

        <tr
          v-for="(item, index) in data.table"
          :key="index"
        >
          <td>
            <span class="d-block fs-16 pl-2 font-weight-bolder">
              {{ item.translate.name }}
              <span
                v-if="item.planning"
                class="badge badge-secondary"
              >
                {{ $t("tariff.head.plan") }}</span>
            </span>
            <span class="d-block fs-13 pl-2 font-weight-light">
              {{ item.translate.info }}
            </span>
          </td>
          <td
            v-for="(tariff, indexTariff) in ['start', 'profi', 'premium', 'enterprise']"
            :key="indexTariff"
            class="text-center"
          >
            <img
              v-if="!item.disableTariff.includes(tariff)"
              alt="Yes"
              src="https://pobo-cdn.b-cdn.net/static/yes.png"
            >

            <img
              v-if="item.disableTariff.includes(tariff)"
              alt="No"
              src="https://pobo-cdn.b-cdn.net/static/no.png"
            >
          </td>
        </tr>
      </tbody>

      <tfoot>
        <tr class="price-list-table__last-row">
          <th />

          <td
            v-for="(item, index) in data.footer"
            :key="index"
            class="pt-3 px-3"
          >
            <b-btn
              v-if="item.type === 'external'"
              :disabled="isLockedTariff(item.tariff)"
              block
              variant="secondary"
              @click="openLink(item.link)"
            >
              {{ $t("tariff.head.buy") }}
            </b-btn>

            <b-btn
              v-if="item.type === 'internal'"
              :disabled="isLockedTariff(item.tariff)"
              block
              variant="secondary"
              @click.prevent="handleOpenPayModal(item)"
            >
              {{ $t("tariff.head.buy") }}
            </b-btn>

            <b-btn
              v-if="item.type === 'contact'"
              :disabled="isLockedTariff(item.tariff)"
              block
              variant="info"
              @click="openLink('/contact')"
            >
              {{ $t("tariff.head.contact") }}
            </b-btn>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useEshopData } from '@/composables/useEshopData';
import PriceTableHead from '@/components/setting/part/price-table-head.vue';

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  showPriceWithVat: {
    type: Boolean,
    required: true,
    default: false
  }
});

const emit = defineEmits(['open-modal']);

const { eshop } = useEshopData();

const periodType = computed(() => {
  return props.data.periodViewYear ? 'year' : 'month';
});

const isLockedTariff = (type) => {
  const { tariff, platform } = eshop.value;

  if (platform === "shopify") {
    return type === tariff;
  }

  const dennyList = {
    start: ["start"],
    profi: ["start", "profi"],
    premium: ["start", "profi", "premium"]
  };

  return dennyList[tariff]?.includes(type) || false;
};

const openLink = (link) => {
  window.open(link);
};

const handleOpenPayModal = (item) => {
  const { tariff } = item;
  emit("open-modal", {
    selectedPackage: {
      tariff_paid: tariff,
      person: {
        use_reverse_charge: true
      }
    }
  });
};
</script>