<template>
  <div id="preview-widget-container">
    <b-row class="bg-white rounded-lg shadow-sm" no-gutters>
      <b-col v-for="(detail, index) in props.widget" :key="index" cols="6">
        <a
          :class="[
            'template-trigger p-3 rounded d-block',
            { active: layout === detail.id },
          ]"
          @click.prevent="emit('select-layout', detail.id)"
        >
          <span class="d-block fs-15 lh-15 font-weight-bolder mb-4">
            {{ $t(`translation.variant.${index}`) }}
          </span>

          <div v-for="widget in detail.list" :key="widget.id">
            <div class="preview-widget pb-4" v-html="widget.html_preview" />
          </div>
        </a>
      </b-col>
    </b-row>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import { Widget } from "@/interface/GenerativeInterface";
const { t } = useI18n();

const props = defineProps<{
  widget: Widget[];
  layout: number;
}>();

const emit = defineEmits(["select-layout"]);
</script>
