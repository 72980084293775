<template>
  <div class="bg-white p-4">
    <h3 class="pb-2">
      Seznam e-shopů
    </h3>
    <div class="row pb-2 mb-2 pt-4 align-items-center">
      <div class="col-4">
        <paginate
          v-if="users.length > 0"
          :click-handler="handleUsePaginator"
          :margin-pages="0"
          :next-text="$t('paginator.next')"
          :page-count="Math.ceil(params.total / params.limit)"
          :prev-text="$t('paginator.prev')"
          :value="params.offset"
          container-class="custom-paginator"
        />
      </div>

      <div class="col-4 text-right">
        <div class="btn-group pl-2">
          <button
            :class="[params.filter === 1 ? 'btn-secondary' : 'btn-light']"
            class="btn btn-sm"
            @click="filterProduct(1)"
          >
            Od nejnovějšího
          </button>
          <button
            :class="[params.filter === 2 ? 'btn-secondary' : 'btn-light']"
            class="btn btn-sm"
            @click="filterProduct(2)"
          >
            TOP e-shopy
          </button>
          <button
            :class="[params.filter === 3 ? 'btn-secondary' : 'btn-light']"
            class="btn btn-sm"
            @click="filterProduct(3)"
          >
            Nejaktivnější
          </button>
        </div>
      </div>

      <div class="col-4">
        <form
          class="input-group"
          @submit.prevent="fetchData"
        >
          <input
            v-model="params.query"
            class="form-control form-control-sm"
            placeholder="Vyhledejte podle URL"
            type="text"
          >
          <div class="input-group-prepend">
            <button
              class="btn btn-sm btn-secondary"
              type="button"
            >
              <i class="ico ico-search" />
            </button>
          </div>
        </form>
      </div>
    </div>

    <table class="table table-bordered table-striped">
      <thead>
        <tr
          class="font-weight-bolder bg-light"
          style="font-size: 14px"
        >
          <td class="border-0">
            ID
          </td>
          <td
            class="border-0"
            colspan="2"
          >
            Produkty
          </td>
          <td class="border-0">
            <i
              v-tooltip.top-center="toolTip.userDashBoardActive"
              class="ico ico-tooltip-help"
            />
          </td>
          <td class="border-0">
            <i class="ico ico-premium-small" />
          </td>
          <td class="border-0">
            URL
          </td>
          <td class="border-0">
            <i
              v-tooltip.top-center="toolTip.productFilterView"
              class="ico ico-filter-eye"
            />
          </td>
          <td class="border-0">
            <i
              v-tooltip.top-center="toolTip.addToBasketTooltip"
              class="ico ico-filter-basket"
            />
          </td>
          <td class="border-0">
            <i
              v-tooltip.top-center="toolTip.userDashBoardLogged"
              class="ico ico-filter-logged"
            />
          </td>
          <td class="border-0">
            Kon. poměr
          </td>
          <td class="border-0">
            Registr.
          </td>
          <td
            class="border-0"
            colspan="3"
          >
            Tarif
          </td>
        </tr>
      </thead>
      <tbody>
        <template v-if="isLoaded">
          <tr
            v-for="item in users"
            :key="item.id"
          >
            <td class="align-middle text-right">
              {{ item.id }}
            </td>
            <td
              class="align-middle text-right"
              style="width: 20px"
            >
              {{ item.product_total }}x
            </td>
            <td
              class="align-middle bg-secondary border-white text-white text-right"
              style="width: 20px"
            >
              {{ item.product_editor }}x
            </td>
            <td class="align-middle">
              <i
                v-if="!item.is_disable"
                class="ico ico-checked"
              />
              <i
                v-if="item.is_disable"
                class="ico ico-cancel"
              />
            </td>
            <td class="align-middle">
              <i
                v-if="item.is_premium"
                class="ico ico-checked"
              />
              <i
                v-if="!item.is_premium"
                class="ico ico-cancel"
              />
            </td>
            <td class="align-middle">
              <a
                :href="item.url"
                class="d-block mt-1"
                target="_blank"
              >
                {{ item.url.replace(/(^\w+:|^)\/\//, "").replace(/\/$/, "") }}
              </a>
              <small class="d-block text-muted mt-1">
                {{ item.users.map(({ email }) => email).join(", ") }}
              </small>
            </td>
            <td class="align-middle text-right">
              {{ item.count_view }}x
            </td>
            <td class="align-middle text-right">
              {{ item.count_basket }}x
            </td>
            <td class="align-middle text-right">
              {{ item.history.logged }}x
            </td>
            <td class="align-middle text-right">
              {{ item.conversion_product }}%
            </td>
            <td class="align-middle">
              {{ when(item.created_at) }}
            </td>
            <td class="align-middle">
              <template v-if="item.tariff.length > 0">
                <small
                  v-for="(tariff, index) in item.tariff"
                  :key="index"
                  class="d-block text-black-50 text-uppercase"
                >
                  {{ tariff.tariff_origin }} ➔ {{ tariff.tariff_paid }}
                </small>
              </template>
              <template v-else>
                <small class="d-block text-black-50">
                  Bez zaplaceného tarifu
                </small>
              </template>
            </td>
            <td class="align-middle text-right">
              <div class="btn-group">
                <button
                  class="btn btn-sm btn-secondary"
                  @click="openUserModal(item.id)"
                >
                  <i class="ico ico-zoom" />
                </button>
              </div>
            </td>
          </tr>
        </template>
        <template v-else>
          <tr
            v-for="n in 10"
            :key="n"
          >
            <td colspan="12">
              <b-skeleton-img
                height="30px"
                no-aspect
                width="100%"
              />
            </td>
          </tr>
        </template>
      </tbody>
    </table>

    <b-modal
      id="modal"
      v-model="openProductModal"
      hide-footer
      size="ai"
      title="Náhled e-shopu"
    >
      <user :user-id="userId" />
    </b-modal>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from "vue";
import {date, when} from "@/filter/vue";
import Paginate from "vuejs-paginate";
import toolTip from "@/config/tooltip.json";
import User from "@/components/dashboard/user.vue";
import DashboardModel from "@/model/DashboardModel.js";

export default defineComponent({
  name: "Users",
  components: {
    paginate: Paginate,
    user: User
  },
  methods: {when},
  setup() {
    const toolTipData = reactive(toolTip);
    const users = ref([]);
    const isLoaded = ref(false);
    const userId = ref(null);
    const openProductModal = ref(false);

    const params = reactive({
      offset: 1,
      limit: 10,
      query: "",
      total: 0,
      filter: 1
    });

    async function fetchData() {
      isLoaded.value = false;
      await DashboardModel.findAll(
        params,
        () => {
        },
        (res) => {
          users.value = res.data.users;
          params.total = res.data.total;
          isLoaded.value = true;
        }
      );
    }

    async function filterProduct(filter) {
      params.filter = filter;
      await fetchData();
    }

    async function handleUsePaginator(count) {
      params.offset = count;
      isLoaded.value = false;
      await DashboardModel.findAll(
        params,
        () => {
        },
        (res) => {
          users.value = res.data.users;
          params.total = res.data.total;
          isLoaded.value = true;
        }
      );
    }

    function openUserModal(id) {
      userId.value = id;
      openProductModal.value = true;
    }

    onMounted(() => {
      fetchData();
    });

    return {
      toolTip: toolTipData,
      users,
      params,
      userId,
      openProductModal,
      isLoaded,
      date,
      openUserModal,
      fetchData,
      filterProduct,
      handleUsePaginator
    };
  }
});
</script>
