<template>
  <table class="position-relative">
    <price-table-head
      :merged-tariffs="mergedTariffs"
      :region="items.region"
      :period-type="periodType"
      :show-price-with-vat="showPriceWithVat"
      :info="items.info"
      :price="items.price"
      :get-price="getPrice" />
    <tbody>
    <tr>
      <td>
        <span class="d-block fs-16 pl-2 font-weight-bolder">
          {{ $t("Count product, blog post and category") }}
        </span>

        <span class="d-block fs-13 pl-2 font-weight-light">
          {{ $t("Includes only edited products, articles and categories") }}
        </span>
      </td>

      <template
        v-for="(limit, index) in items.limit"
        :key="index"
      >
        <td class="pt-3 px-3 fs-15">
          <div
            v-for="(type, typeIndex) in ['products', 'categories', 'posts']"
            :key="typeIndex"
          >
            <div class="pl-4">
              <template v-if="limit[type]">
                <strong>{{ $t(limit[type]) }}</strong> {{ $t(`limiter.${type}`) }}
              </template>
              <template v-else>
                {{ $t("Unlimited") }} {{ $t(`limiter.${type}`) }}
              </template>
            </div>
          </div>
        </td>
      </template>
    </tr>
    <tr
      v-for="(item, index) in items.table"
      :key="index"
    >
      <td>
          <span class="d-block fs-16 pl-2 font-weight-bolder">
            {{ item.translate.name }}
            <span
              v-if="item.planning"
              class="badge badge-secondary"
            >
              {{ $t("tariff.head.plan") }}</span>
          </span>
        <span class="d-block fs-13 pl-2 font-weight-light">
            {{ item.translate.info }}
          </span>
      </td>
      <td
        v-for="(tariff, indexTariff) in ['start', 'profi', 'premium', 'saas']"
        :key="indexTariff"
        class="text-center"
      >
        <img
          v-if="!item.disableTariff.includes(tariff)"
          alt="Yes"
          src="https://pobo-cdn.b-cdn.net/static/yes.png"
        >

        <img
          v-if="item.disableTariff.includes(tariff)"
          alt="No"
          src="https://pobo-cdn.b-cdn.net/static/no.png"
        >
      </td>
    </tr>
    </tbody>
    <tfoot>
    <tr class="price-list-table__last-row">
      <th />
      <td
        v-for="(item, index) in items.footer"
        :key="index"
        class="pt-3 px-3"
      >
        <template v-if="item.type === 'contact'">
          <a
            :href="item.link"
            class="btn btn-block btn-info"
            target="_blank"
          >
            {{ $t("tariff.head.contact") }}
          </a>
        </template>
        <template v-else>
          <button
            class="btn btn-block btn-secondary"
            :disabled="isLockedTariff(item.tariff)"
            @click="handleStripe(item)"
          >
            {{ $t("tariff.head.buy") }}
          </button>
        </template>
      </td>
    </tr>
    </tfoot>
  </table>
</template>


<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useEshopData } from "@/composables/useEshopData";
import TariffModel from "@/model/TariffModel";
import {
  FooterItemWithTariff,
  PeriodType,
  TariffData,
  TariffPlan,
  TariffPlanItem,
  TariffType
} from "@/interface/Tariff";
import PriceTableHead from "@/components/setting/part/price-table-head.vue";

const props = defineProps<{
  items: TariffData;
  showPriceWithVat: boolean;
  currency: string;
}>();

const { eshop } = useEshopData() as {
  eshop: { value: { tariff: TariffType; platform: string; uuid: string; user_uuid: string } }
};
const priceList = ref<TariffPlan[]>([]);

onMounted(async () => {
  try {
    priceList.value = await TariffModel.getPriceList();
  } catch (error) {
    console.error("Error fetching price list:", error);
  }
});

const currentPlan = computed(() => {
  return priceList.value.find(
    (plan) => plan.plan.currency_code === props.currency
  );
});

const mergedTariffs = computed(() => {
  const { items, currency } = props;
  const { header } = items;
  return header.map((header) => {
    const tariff = items.footer.find((f) => f.tariff === header.type);
    if (!tariff) return header;
    return { ...header, link: tariff.link ? `${tariff.link}?currency=${currency}` : null };
  });
});

const periodType = computed((): PeriodType => {
  const { periodViewYear } = props.items;
  return periodViewYear ? "year" : "month";
});

const isLockedTariff = (type: TariffType): boolean => {
  const { tariff, platform } = eshop.value;

  if (platform === "shopify") {
    return type === tariff;
  }

  const dennyList: Record<TariffType, TariffType[]> = {
    start: ["start"],
    profi: ["start", "profi"],
    premium: ["start", "profi", "premium"],
    saas: ["start", "profi", "premium", "saas"]
  };

  return dennyList[tariff]?.includes(type) || false;
};

const getPricePlan = (tariffType: TariffType, period: PeriodType): TariffPlanItem | undefined => {
  if (!currentPlan.value || !currentPlan.value.price) {
    return undefined;
  }

  return currentPlan.value.price.find(
    (price) => price.type === tariffType && price.period === period
  );
};

const createStripeRequest = async (plan: TariffPlanItem): Promise<void> => {
  try {
    const { user_uuid } = eshop.value;
    const data = {
      user_guid: user_uuid,
      eshop_guid: eshop.value.uuid,
      price_id: plan.id
    };

    const response = await TariffModel.createStripeRequest(data);
    window.location = response.url;
  } catch (error) {
    console.error("Error creating Stripe request:", error);
  }
};

const handleStripe = (item: FooterItemWithTariff): void => {
  const pricePlan = getPricePlan(item.tariff, periodType.value);
  if (!pricePlan) {
    return;
  }
  createStripeRequest(pricePlan);
};

const getPrice = (tariffType: TariffType, period: PeriodType, withVat = false): string | null => {
  if (!currentPlan.value || !currentPlan.value.price) {
    return null;
  }

  const priceObj = currentPlan.value.price.find(
    (price) => price.type === tariffType && price.period === period
  );

  if (!priceObj) {
    return null;
  }

  const finalPrice = withVat ? (priceObj.price * 1.21).toFixed(0) : priceObj.price;
  return `${finalPrice} ${currentPlan.value.plan.currency_symbol}`;
};
</script>