<template>
  <transition name="fade">
    <article
      v-if="widget.length === 0"
      class="content-editor__insert"
    >
      <h2
        class="text-black-50 text-center font-weight-normal"
      >
        {{ $t("editor.guide.part1") }}<br>
        {{ $t("editor.guide.part2") }}
      </h2>

      <i class="ico ico-editor-drag-drop" />
    </article>
  </transition>
  <draggable
    v-bind="dragOptions"
    v-model="widget"
    :component-data="{ name: 'fade' }"
    item-key="id"
    class="content-editor__placeholder-inner"
    data-cy="content-editor-dragging-place"
    @change="actionCreate($event)"
    @end="moveWidget(false)"
    @start="moveWidget(true)"
  >
    <template #item="{ element }">
      <div
        class="widget-container"
        data-cy="single-widget-container"
        style="padding-right: 40px"
      >
        <div
          :class="[
            element.enable_typography_css ? 'widget-typography' : 'widget-projector',
            element.is_original
              ? 'content-editor__widget-original'
              : '',
            isMovedActive ? 'moved-deprecated' : '',
            'content-editor__widget'
          ]"
        >
          <widget-panel
            :widget="element"
            @handle-delete="actionDelete"
            @create-favourite="$refs.widget.setActiveBlock(3)"
            @move-up="moveWidgetUp"
            @move-down="moveWidgetDown"
          />

          <div :class="isMovedActive ? 'content-editor__inner' : ''">
            <template v-if="element.is_original">
              <div v-html="element.html" />
            </template>
            <template v-else>
              <template
                v-if="element.children && element.children.length > 0"
              >
                <!-- eslint-disable -->
                <widget-tree
                  v-for="(tree, index) in element.children"
                  :id="id"
                  :key="element.key"
                  :class="tree.class"
                  :content-id="element.id"
                  :extension-editor="extensionMonaco"
                  :index="index"
                  :item="tree"
                  :options="options"
                  :value-lang="valueLang"
                  :aspect-ratio="element.aspect_ratio"
                  @element-root-handler="elementHandler"
                />
              </template>
            </template>
          </div>
        </div>
      </div>
    </template>
  </draggable>
</template>

<script>
import axios from "axios";
import { showMessage } from "@/plugins/flashMessage";
import WidgetTree from "@/share/widget-tree.vue";
import WidgetPanel from "@/share/widget-panel.vue";
import Draggable from "vuedraggable";
import WidgetModel from "@/model/WidgetModel";

export default  {
  name: "widget-area",

  props: {
    id: {
      type: Number,
      required: true
    },

    options: {
      type: Object,
      required: true
    },

    valueLang: {
      type: String,
      required: false,
      default: "default"
    },

    extensionMonaco: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },

  async mounted() {
    await this.actionRead();
  },

  data() {
    return {
      isMovedWidget: false,
      widget: []
    }
  },

  computed: {
    isMovedActive() {
      return this.isMovedWidget;
    },

    dragOptions() {
      return {
        animation: 500,
        group: "people",
        ghostClass: "ghost",
        disabled: false,
        handle: ".btn-secondary"
      };
    },
  },

  components: {
    "widget-tree": WidgetTree,
    "widget-panel": WidgetPanel,
    draggable: Draggable
  },

  methods: {
    moveWidget(state) {
      this.isMovedWidget = state;
    },

    moveWidgetUp(widgetId) {
      const index = this.widget.findIndex(w => w.id === widgetId);
      if (index > 0) {
        const item = this.widget.splice(index, 1)[0];
        this.widget.splice(index - 1, 0, item);
        this.actionUpdate();
      }
    },

    moveWidgetDown(widgetId) {
      const index = this.widget.findIndex(w => w.id === widgetId);
      if (index < this.widget.length - 1) {
        const item = this.widget.splice(index, 1)[0];
        this.widget.splice(index + 1, 0, item);
        this.actionUpdate();
      }
    },

    async actionRead() {
      const { options, id } = this;
      const { api } = options;
      const { widget } = api;

      await axios.get(widget.replace("[id]", id)).then(({ data }) => {
        this.widget = data;
        this.isLoaded = true;
        this.$emit("action-update", data);
      });
    },

    async elementHandler(data) {
      await this.actionUpdate();

      const { options, id } = this;
      const { api } = options;

      await WidgetModel.copyElement(
        { ...data, ...{ id } },
        api.copy.replace("[id]", id),
        () => {
        },
        (data) => {
          this.widget = data;
        }
      );
    },

    async actionCreate({ added, moved }) {
      const data = added || moved;
      const { options, widget, id } = this;
      const { api } = options;
      const { newIndex, element } = data;

      await this.actionUpdate();

      if (added) {
        let position = [];

        Array.from(widget).forEach(({ id }, index) => {
          position.push({
            id: id,
            position: index
          });
        });

        await axios
          .post(api.widget.replace("[id]", id), {
            widget_id: element.id,
            position: newIndex,
            added: added !== undefined,
            json: element.children,
            ord: position
          })
          .then(({ data }) => {
            this.widget = data;
            this.$emit("action-update", data);
          })
          .catch((error) => {
            console.log("Error", error);
          });
      }
    },

    async actionDelete(widget_id) {
      const { options, id } = this;
      const { api } = options;

      await this.actionUpdate();

      const url = api.widget.replace("[id]", id) + `/${widget_id}`;

      await axios
        .delete(url)
        .then(({ data }) => {
          this.widget = data;
          showMessage("flash.delete");
          this.$emit("action-update", data);
        });
    },

    async actionUpdate(
      showFlashMessage = false,
      refreshData = false
    ) {
      const { options, id, widget } = this;
      const { api } = options;
      const { content } = options;

      await axios
        .put(api.widget.replace("[id]", id), { widget })
        .then(({ data }) => {
          if (refreshData) {
            this.widget = data;
          }

          if (this.$refs.iframe) {
            this.$refs.iframe.src = "/app/iframe/product/[id]".replace("[id]", id);
          }

          if (showFlashMessage) {
            showMessage(`flash.content.${content}`);
          }
        });
    },
  }

}
</script>

