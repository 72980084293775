<template>
  <form @submit.prevent="actionUpdate">
    <p
      v-if="isLocked"
      class="mb-4 text-sm p-3 rounded information-box"
    >
      <i class="ico ico-warning" />
      {{ $t("setting.export.warning.prefix") }}
      <router-link
        :to="{ name: 'setting-multi-lang' }"
        class="text-success"
      >
        {{ $t("setting.export.warning.link") }}
      </router-link>
      , {{ $t("setting.export.warning.postfix") }}.
    </p>

    <b-form-group>
      <b-form-radio
        v-model="form.export_mode"
        :disabled="isLocked"
        :value="1"
        class="mb-3 pb-3 border-bottom"
      >
        {{ $t("setting.export.full.part1") }}
        <span class="font-weight-bolder">
          {{ $t("setting.export.full.part2") }}
        </span>
        <small class="d-block text-black-50 pt-1">
          {{ $t("setting.export.full.part3") }}
          <strong>{{ getPlainUrl }}</strong>
          {{ $t("setting.export.full.part4") }}.
        </small>
      </b-form-radio>

      <b-form-radio
        v-model="form.export_mode"
        :disabled="isLocked"
        :value="2"
      >
        {{ $t("setting.export.minimal.part1") }}
        <span class="font-weight-bolder">{{
          $t("setting.export.minimal.part2")
        }}</span>
        <small class="d-block text-black-50 pt-1">
          {{ $t("setting.export.minimal.part3") }}
          <strong>{{ getPlainUrl }}</strong>
          {{ $t("setting.export.minimal.part4") }}
        </small>
      </b-form-radio>
    </b-form-group>

    <b-form-group class="mt-4">
      <b-button
        :disabled="isLocked"
        type="submit"
      >
        {{ $t("setting.export.save") }} <i class="ico ico-arrow-right" />
      </b-button>
    </b-form-group>
  </form>
</template>

<script>
import eshopData from "@/mixin/eshopData";
import cloneDeep from "lodash.clonedeep";
import { useEshopStore } from "@/store/EshopStore";
export default {
  name: "setting-form",
  displayName: "setting-form",

  mixins: [eshopData],

  async mounted() {
    const { eshop } = this;
    this.form = cloneDeep(eshop);
  },

  data() {
    return {
      form: {},
      errors: {},
    };
  },

  computed: {
    getPlainUrl() {
      const { eshop } = this;

      const url = new URL(eshop.url);

      return url.hostname;
    },

    isLocked() {
      const { eshop } = this;
      const { allow_lang } = eshop;
      return allow_lang === true;
    },
  },

  methods: {
    async actionUpdate() {
      const { form } = this;
      await useEshopStore().updateEshop({
        data: form
      });
    },
  },
};
</script>
